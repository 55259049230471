/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Divider from '@guestyci/foundation/Divider';
import { Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';

import Icon from 'components/Icon';
import DiscountTypeText from './DiscountTypeText';

const useStyles = createStyles(() => ({
  closeIcon: {
    marginRight: 15,
    cursor: 'pointer',
  },
  couponWrapper: {
    padding: ({ withRemoveButton }) => (withRemoveButton ? '13px 0 13px 10px' : '13px 0'),
  },
}));

const CouponCode = ({ coupon, handleClick, actualDiscount }) => {
  const { closeIcon, couponWrapper } = useStyles({
    withRemoveButton: !!handleClick,
  });

  return (
    <>
      <Row className={couponWrapper}>
        {handleClick && (
          <span data-qa="remove-coupon" className={closeIcon} onClick={handleClick}>
            <Icon icon="close" size={12} />
          </span>
        )}
        <Row align="center" className="w-fill pl-0 pr-0">
          <Icon icon="coupon" size={16} />
          <DiscountTypeText coupon={coupon} actualDiscount={actualDiscount} />
        </Row>
      </Row>
      <Divider />
    </>
  );
};

export default CouponCode;
