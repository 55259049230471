class GTAG {
  constructor() {
    this.reservationTypes = {
      SINGLE: 'Single reservation',
      GROUP: 'Group reservation',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  initialize({ tagId, gaId }) {
    if (tagId) {
      this.hasGTM = true;
      GTAG.initGTM({ tagId });
    }
    if (gaId) {
      this.hasGA = true;
      GTAG.initGTag({ gaId });
    }
  }

  pageView(url) {
    this.gtagEvent('page_view', {
      page_location: url,
    });
  }

  static initGTM({ tagId }) {
    if (!tagId) return;
    const scriptNode = window.document.getElementById('gtm-script');
    if (scriptNode) return;

    // same logic as "Google Tag Manager" install script
    window.dataLayerGtm = window.dataLayerGtm || [];
    window.dataLayerGtm.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const scriptElement = window.document.createElement('script');
    scriptElement.async = true;
    scriptElement.id = 'gtm-script';
    scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${encodeURIComponent(tagId)}&l=dataLayerGtm`;
    document.head.prepend(scriptElement);
  }

  static initGTag({ gaId }) {
    if (!gaId) return;
    const initializedScriptElem = window.document.getElementById('gtagScript');
    if (initializedScriptElem) return;

    const scriptElement = window.document.createElement('script');
    scriptElement.async = true;
    scriptElement.id = 'gtagScript';
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${encodeURIComponent(gaId)}`;
    document.head.prepend(scriptElement);

    window.dataLayer = window.dataLayer || [];
    // note: for some reason for GA we need to use `dataLayer.push(arguments)` and not [...args]
    window.gtag =
      window.gtag ||
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };
    window.gtag('js', new Date());
    window.gtag('config', gaId, {
      // we track page_view manually using history change events - because we have SPA
      // https://developers.google.com/analytics/devguides/collection/ga4/single-page-applications?implementation=event
      send_page_view: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  gtagEvent(event, payload) {
    if (this.hasGA && window.gtag) window.gtag('event', event, payload);
    if (this.hasGTM && window.dataLayerGtm) {
      if (event === 'page_view') {
        window.dataLayerGtm.push({ event, ...payload });
      } else {
        // ecommerce events
        window.dataLayerGtm.push({ ecommerce: null });
        window.dataLayerGtm.push({ event, ecommerce: payload });
      }
    }
  }

  /**
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {number} data.totalPrice
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {number} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommerceViewItem(data) {
    this.gtagEvent('view_item', {
      currency: data.currency,
      value: data.totalPrice,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          index: 0,
          price: data.totalPrice,
          quantity: 1,
        },
      ],
    });
  }

  /**
   * @param {number} data.totalPrice
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommerceBeginCheckout(data) {
    this.gtagEvent('begin_checkout', {
      value: data.totalPrice,
      currency: data.currency,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          index: 0,
          price: data.totalPrice, // fixme: fix for group reservations
          quantity: 1,
        },
      ],
    });
  }

  /**
   * @param {string} data.reservationId
   * @param {number} data.reservationTotalAmount
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.taxes
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommercePurchase(data) {
    const payload = {
      transaction_id: data.reservationId,
      value: data.totalPrice,
      tax: data.taxes,
      currency: data.currency,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          item_category4: data.reservationType,
          index: 0,
          price: data.totalPrice,
          quantity: 1,
        },
      ],
    };

    if (data.groupReservationId) {
      payload.item_category5 = data.groupReservationId;
    }
    this.gtagEvent('purchase', payload);
  }

  gtagEcommerceGroupReservationsPurchase(data) {
    this.gtagEvent('purchase', {
      transaction_id: data.reservationId,
      value: data.totalPrice,
      // tax: 0,
      // shipping: 0,
      currency: data.currency,
      // coupon: '',
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          // coupon: '',
          // discount: 0,
          index: 0,
          price: data.totalPrice, // fixme: fix for group reservations
          quantity: 1,
        },
      ],
    });
  }
}

export default new GTAG();
