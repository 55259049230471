/* eslint-disable import/prefer-default-export */
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_MONTH = 'YYYY-MM';
export const UI_DATE_FORMAT = 'MMM DD, YYYY';
export const US_DATE_FORMAT = 'MMMM DD, YYYY';
export const DAY_FIRST_UI_DATE_FORMAT = 'D MMMM YYYY';
export const HOUR_FORMAT_24 = 'HH:mm';
export const HOUR_FORMAT_12 = 'hh:mm A';
export const PAYMENT_DATE_TYPE = {
  AT: 'AT',
  AFTER: 'AFTER',
  DATE: 'DATE',
};
