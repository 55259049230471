import TextField from '@guestyci/foundation/TextField/TextField';
import map from 'lodash/map';
import { getBedTypes, getPropertyRoomTypes } from 'constants/constants';
import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import { BedIcons } from 'components/BedArrengement/BedIcons';

const useStyles = createStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacer(1),
    minWidth: theme.spacer(26),
    marginRight: theme.spacer(4),
    marginBottom: theme.spacer(4),

    '&:last-child': {
      marginRight: 0,
    },
  },
  carouselItemTitle: {
    padding: `${theme.spacer(4)}px ${theme.spacer(4)}px ${theme.spacer(1)}px ${theme.spacer(4)}px`,
  },
  carouselItem: {
    padding: theme.spacer(4),
    whiteSpace: 'nowrap',
    maxWidth: theme.spacer(48),
    display: 'flex',
    alignItems: 'center',
  },
  bedsContainer: {
    justifyContent: 'left',
  },
}));

export const BedArrangementItem = ({ type, beds, name, roomNumber, _id }) => {
  const { root, carouselItem, carouselItemTitle, bedsContainer } = useStyles();
  const bedTypesTranslation = getBedTypes();
  const roomTypesTranslation = getPropertyRoomTypes();

  return (
    <Col justify="start" className={root}>
      <TextField bold className={carouselItemTitle} variant="h5">
        {name || `${roomTypesTranslation[type]} ${roomNumber + 1}`}
      </TextField>
      <Row className={bedsContainer} wrap="wrap" justify="space-evenly">
        {map(beds, (qty, bedType) => {
          if (!qty) return null;
          const Icon = BedIcons[bedType];
          const label = `${qty} ${bedTypesTranslation[bedType] ? bedTypesTranslation[bedType](qty) : ''}`;

          return (
            <Col key={`${_id}-${bedType}-${qty}`} className={carouselItem}>
              {Icon && <Icon />}
              <TextField>{label}</TextField>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default BedArrangementItem;
