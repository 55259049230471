import { Col, Row } from '@guestyci/foundation/Layout';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import Divider from '@guestyci/foundation/Divider';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro/t.macro';

import Icon from 'components/Icon';

const useStyles = createStyles(({ breakpoints: { create }, spacer }) => ({
  emptySort: {
    paddingTop: spacer(4),
  },
  sortDropdown: {
    maxWidth: spacer(80),
    width: 'initial',
    marginRight: spacer(5),
    cursor: 'pointer',
    [create('xs')]: {
      padding: spacer(3),
    },
    [create('lg')]: {
      padding: `${spacer(2)}px ${spacer(4)}px`,
    },
  },
}));

const SortBy = ({ selectedSortOrder, setSelectedSortOrder, sortOptions }) => {
  const { sortDropdown, emptySort } = useStyles();

  const handleSetSortOrder = (e) => {
    const { value } = e.target;
    if (!value) {
      e.stopPropagation();
    }
    setSelectedSortOrder(value);
  };

  if (!sortOptions?.length) {
    return <Col className={emptySort} />;
  }

  return (
    <Col>
      <Divider />

      <Dropdown
        value={selectedSortOrder}
        className={sortDropdown}
        onChange={handleSetSortOrder}
        input={
          <Row align="center" spacing={2}>
            <TextField className="block" color="input" bold>
              {t(`Sort by: {sortBy}`, { sortBy: selectedSortOrder?.label })}
            </TextField>
            {selectedSortOrder ? <Icon icon="close" size={12} onClick={handleSetSortOrder} /> : null}
          </Row>
        }
      >
        {sortOptions.map((option) => (
          <Option value={option} key={option.label}>
            {option.label}
          </Option>
        ))}
      </Dropdown>
    </Col>
  );
};

export default SortBy;
