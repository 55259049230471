import { api } from 'api';

const URL = '/reservations/upsell';
const upsetUpsells = async ({ quoteId, additionalFeeIds, ratePlanId }) => {
  const { data } = await api.post(`${URL}/${quoteId}`, {
    additionalFeeIds: additionalFeeIds || [],
    ratePlanIds: ratePlanId ? [ratePlanId] : [],
  });
  return data;
};

export default upsetUpsells;
