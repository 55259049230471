import { useEffect, Component, useCallback } from 'react';
import PropTypes from 'prop-types';

function useOutsideClick({ handler, elementRef, wrapperRef, preventHandler = false }) {
  const handleClickOutside = useCallback(
    (event) => {
      if (
        !preventHandler &&
        !elementRef.current?.contains(event.target) &&
        (!wrapperRef || wrapperRef?.current?.contains(event.target))
      ) {
        handler();
      }
    },
    [elementRef, handler, wrapperRef, preventHandler]
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [handleClickOutside]);
}

useOutsideClick.propTypes = {
  elementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })])
    .isRequired,
  wrapperRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Component) })]),
  handler: PropTypes.func.isRequired,
  preventHandler: PropTypes.bool,
};

export default useOutsideClick;
