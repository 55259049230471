import t from '@guestyci/localize/t.macro';
import moment from 'moment';
import CancellationPolicyKeys from 'constants/cancellationPolicyKeys';
import CancellationPolicyDays from 'constants/cancellationPolicyDays';
import { languagesTimeFormats } from 'constants/languages';
import { DAY_FIRST_UI_DATE_FORMAT } from 'constants/date';
import useSearchValues from 'hooks/useSearchValues';
import useLocale from 'hooks/useLocale';

/**
 *  Returns flag If the time between today, check-in day and the selected cancellation policy does not coincide.
 * @param {Object} params - Params
 * @param {string} params.checkInDate Check-in date
 * @param {string} params.cancellationPolicy Cancellation policy
 * @returns {Boolean} date passed flag
 */
const checkIfDateHasPassed = ({ checkInDate, cancellationPolicy }) => {
  // Do not use logic if cancellationPolicy is not provided
  if (!cancellationPolicy) return false;

  // it is not possible to create a reservation later than the time when the Free policy is due
  if (cancellationPolicy === CancellationPolicyKeys.FREE) return false;

  // Check for not refundable policy is not needed
  if (cancellationPolicy === CancellationPolicyKeys.NON_REFUNDABLE) return false;

  // Get difference in days amount by cancellationPolicy
  const daysOfPolicy = CancellationPolicyDays[cancellationPolicy];

  if (!daysOfPolicy) return false;

  const nowDate = moment().toDate().getTime();

  // Define 14:00 as start of check-in date
  const checkIn = moment(checkInDate).hours(14).minutes(0).seconds(0).milliseconds(0);

  const checkInDateWithSubtractedPolicyDays = checkIn.subtract(daysOfPolicy, 'day').toDate().getTime();

  // If today date is after check-in date with subtracted policy days, it means that date has passed
  return checkInDateWithSubtractedPolicyDays <= nowDate;
};

/**
 *  Get last cancellation date based on cancellation policy and check-in date
 * @param {Object} params - Params
 * @param {string} params.checkInDate Check-in date
 * @param {string} params.cancellationPolicy Cancellation policy
 * @returns {string} Date
 */
const getLastCancellationDate = ({ checkInDate, cancellationPolicy }) => {
  const checkInDateLocalized = moment(checkInDate);
  switch (cancellationPolicy) {
    case CancellationPolicyKeys.FREE:
      return checkInDateLocalized.format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.FLEX:
      return checkInDateLocalized.subtract(1, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.SEMI_FLEXIBLE:
      return checkInDateLocalized.subtract(2, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.SEMI_MODERATE:
      return checkInDateLocalized.subtract(5, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.MODERATE:
      return checkInDateLocalized.subtract(7, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.FIRM:
      return checkInDateLocalized.subtract(14, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.STRICT:
      return checkInDateLocalized.subtract(30, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    case CancellationPolicyKeys.STRICT_60:
      return checkInDateLocalized.subtract(60, 'day').format(DAY_FIRST_UI_DATE_FORMAT);
    default:
      return checkInDateLocalized.format(DAY_FIRST_UI_DATE_FORMAT);
  }
};

/**
 *  Returns translated refund title and refund description based on cancellation policy
 * @param {Object} params - Params
 * @param {string} params.cancellationPolicy Cancellation policy
 * @param {string} params.cancellationFee Cancellation fee
 * @param {string} params.defaultCheckInTime Default check in time
 * @returns {{ refundTitle: string, refundDescription: string }} Title, description of choose rate plan
 */
const useCancellationPolicy = ({ cancellationPolicy, cancellationFee, defaultCheckInTime }) => {
  const { startDate: checkInDate } = useSearchValues();
  const locale = useLocale();

  const isDatePassed = checkIfDateHasPassed({
    checkInDate,
    cancellationPolicy,
  });

  const lastCancellationDate = getLastCancellationDate({
    checkInDate,
    cancellationPolicy,
  });

  const getRefundTitle = () => {
    if (cancellationPolicy === CancellationPolicyKeys.FREE) {
      return t('Free cancellation');
    }

    if (cancellationPolicy === CancellationPolicyKeys.NON_REFUNDABLE) {
      return t('Non-refundable');
    }

    // Return specific title if date of policy restriction passed
    if (isDatePassed) {
      switch (cancellationFee) {
        case 0:
          return t('Costs first night to cancel');
        case 50:
          return t('Costs 50% to cancel');
        case 100:
          return t('Non-refundable');
        default:
          return t('Non-refundable');
      }
    }

    return t('Free cancellation before {date}', {
      date: lastCancellationDate,
    });
  };

  const getRefundDescription = () => {
    const timeFormatBasedOnLocale = languagesTimeFormats[locale];

    if (cancellationPolicy === CancellationPolicyKeys.FREE) {
      // Last checkIn time. If defaultCheckInTime not provided, define time as 14:00
      const lastCheckInTime = moment(defaultCheckInTime || '14:00', ['HH:mm']).format(timeFormatBasedOnLocale);
      return t(
        "Cancel free of charge before {time} on {date}. The free cancellation deadline is in the property's timezone.",
        {
          date: lastCancellationDate,
          time: lastCheckInTime,
        }
      );
    }

    if (cancellationPolicy === CancellationPolicyKeys.NON_REFUNDABLE) {
      return t('Non-refundable');
    }

    if (isDatePassed) {
      switch (cancellationFee) {
        case 0:
          return t('By canceling now, you will be charged the cost of the first night.');
        case 50:
          return t('By canceling now, you will be charged 50% of the cost of the reservation.');
        case 100:
          return t('By canceling now, you will be charged the total cost of the reservation.');
        default:
          return t('Non-refundable');
      }
    }

    // Last checkIn time. If defaultCheckInTime not provided, define time as 24:00
    const lastCheckInTime = moment(defaultCheckInTime || '24:00', ['HH:mm']).format(timeFormatBasedOnLocale);

    switch (cancellationFee) {
      case 0:
        return t(
          "If you cancel after {time} on {date}, you will be charged the cost of the first night. The free cancelation deadline is in the property's timezone.",
          {
            date: lastCancellationDate,
            time: lastCheckInTime,
          }
        );
      case 50:
        return t(
          "If you cancel after {time} on {date}, you will be charged 50% of the cost of the reservation. The free cancelation deadline is in the property's timezone.",
          {
            date: lastCancellationDate,
            time: lastCheckInTime,
          }
        );
      case 100:
        return t(
          "If you cancel after {time} on {date}, you will be charged the total cost of the reservation. The free cancelation deadline is in the property's timezone.",
          {
            date: lastCancellationDate,
            time: lastCheckInTime,
          }
        );
      default:
        return t('Non-refundable');
    }
  };

  return {
    refundTitle: getRefundTitle(),
    refundDescription: getRefundDescription(),
  };
};

export default useCancellationPolicy;
