import { api } from 'api';

const URL = 'internal/listings/markers';

const getListingsMarkers = async ({ params }) => {
  const { data } = await api.get(URL, { params });
  return data;
};

export default getListingsMarkers;
