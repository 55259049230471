import React from 'react';
import Section from '@guestyci/foundation-legacy/Section';
import GenericError from '@guestyci/foundation-legacy/GenericError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // error
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // Send error to ??
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Section style={{ height: '100vh' }} className="w-fill" bgColor="white" col>
          <GenericError className="text-gray-dark" />
        </Section>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
