import t from '@guestyci/localize/t.macro/t.macro';
import TextField from "@guestyci/foundation/TextField";
import createStyles from "@guestyci/foundation/createStyles";
import {Link} from "react-router-dom";
import {useCallback} from "react";
import NoPropertiesFoundIcon from "./NoPropertiesFoundIcon";

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [create('xs')]: {
      padding: 0,
    },
    [create('md')]: {
      padding: '5em',
    },
    [create('lg')]: {
      padding: 0,
    },
  },
  textBlock: {
    marginTop: 40,
    textAlign: 'center',
    padding: '0px 20px'
  },
  titleText: {
    fontWeight: 500,
    fontSize: 24,
    color: '#10275B',
  },
  secondaryText: {
    fontWeight: 400,
    fontSize: 18,
    color: '#3E4E5D',
  },
}));

const NoPropertiesFound = ({ handleClearSearch }) => {
  const { root, textBlock, titleText, secondaryText } = useStyles();

  const handleClickClearSearch = useCallback(() => {
    if (handleClearSearch) {
      handleClearSearch({});
    }
  }, [handleClearSearch])


  return (
    <div className={root}>
      <NoPropertiesFoundIcon />
      <div className={textBlock}>
        <TextField variant='h2' className={titleText}>
          {t('No properties matched your search')}
        </TextField>
        <TextField className={secondaryText}>
          {t('Try to use fewer filters, broaden the date range, or <link>clear search</link>', {
            link: chunks => (
              <Link to="/properties" onClick={handleClickClearSearch}>
                {chunks}
              </Link>
            ),
          })}
        </TextField>
      </div>
    </div>
  );
}

export default NoPropertiesFound;
