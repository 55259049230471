import t from '@guestyci/localize/t.macro/t.macro';

export const SORT_BY = {
  AREA_SQUARE_FEET: 'areaSquareFeet',
  BASE_PRICE: 'prices.basePrice',
  NUMBER_OF_GUESTS: 'numberOfGuests',
  NUMBER_OF_BEDROOMS: 'numberOfBedrooms',
};

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const getSortOptions = () => ({
  AREA_SQUARE_FEET_DESC: {
    label: t('Floor surface/Property size - high to low'),
    sortBy: SORT_BY.AREA_SQUARE_FEET,
    sortOrder: SORT_ORDER.DESC,
  },
  BASE_PRICE_DESC: {
    label: t('Price - high to low'),
    sortBy: SORT_BY.BASE_PRICE,
    sortOrder: SORT_ORDER.DESC,
  },
  BASE_PRICE_ASC: {
    label: t('Price - low to high'),
    sortBy: SORT_BY.BASE_PRICE,
    sortOrder: SORT_ORDER.ASC,
  },
  NUMBER_OF_GUESTS_DESC: {
    label: t('Maximum occupancy - high to low'),
    sortBy: SORT_BY.NUMBER_OF_GUESTS,
    sortOrder: SORT_ORDER.DESC,
  },
  NUMBER_OF_BEDROOMS_DESC: {
    label: t('Count of bedrooms - high to low'),
    sortBy: SORT_BY.NUMBER_OF_BEDROOMS,
    sortOrder: SORT_ORDER.DESC,
  },
});
