import Dialog, { DialogContent, DialogFooter } from '@guestyci/foundation/Dialog';
import { Section } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import FlatButton from '@guestyci/foundation/FlatButton';
import t from '@guestyci/localize/t.macro/t.macro';

const ErrorDialog = ({ isOpen, message, title, handleClose = () => {} }) => {
  const errorMessage = message || t('Payment process failed');
  const titleLabel = title || t('Payment process failed');
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Section gutter="6 8 0 6">
        <TextField color="error" variant="h2">
          {titleLabel}
        </TextField>
      </Section>
      <DialogContent>
        <TextField>{errorMessage}</TextField>
      </DialogContent>
      <DialogFooter>
        <FlatButton onClick={handleClose}>{t('Ok')}</FlatButton>
      </DialogFooter>
    </Dialog>
  );
};

export default ErrorDialog;
