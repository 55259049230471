import { useContext } from 'react';
import moment from 'moment';

import t from '@guestyci/localize/t.macro';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import useGetPaymentSchedule from 'hooks/useGetPaymentSchedule';
import { PAYMENT_DATE_TYPE, US_DATE_FORMAT } from 'constants/date';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import bookingTypes from 'constants/bookingTypes';
import { getPaymentDateType } from 'utils';
import { DISCO_BE_AUTO_PAYMENT } from 'constants/featureToggleNames';

const PAYMENTS_COUNT_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

const useStyles = createStyles(({ spacer }) => ({
  root: {
    color: '#6B7280',
    marginTop: spacer(3),
  },
}));

const FirstPaymentDate = ({ total }) => {
  const [, isAutoPayment] = useFeatureToggle(DISCO_BE_AUTO_PAYMENT);
  const classes = useStyles();
  const { data: { payments } = {} } = useGetPaymentSchedule({
    total,
  });
  const firstPayment = payments?.[0];
  const { bookingType } = useContext(WebsiteSettingsContext);

  if (!payments?.length || !bookingType || !isAutoPayment) {
    return null;
  }

  const getTranslation = ({ payment }) => {
    const paymentDateType = getPaymentDateType(payment?.after);
    const paymentsCountType = payments.length > 1 ? PAYMENTS_COUNT_TYPE.MULTIPLE : PAYMENTS_COUNT_TYPE.SINGLE;
    const PAYMENT_TRANSLATION = {
      [PAYMENTS_COUNT_TYPE.SINGLE]: {
        [PAYMENT_DATE_TYPE.AFTER]: t(
          `You will not be charged until { after, plural, =1 {# day} other {# days} } after booking confirmation`,
          { after: payment?.after }
        ),
        [PAYMENT_DATE_TYPE.AT]: t('You will not be charged until the booking confirmation'),
        [PAYMENT_DATE_TYPE.DATE]: t('You will not be charged until {date}', {
          date: moment(payments[0]?.date).format(US_DATE_FORMAT),
        }),
      },
      [PAYMENTS_COUNT_TYPE.MULTIPLE]: {
        [PAYMENT_DATE_TYPE.AT]: t('You will not be charged yet'),
        [PAYMENT_DATE_TYPE.AFTER]: t(
          `You will not be charged until { after, plural, =1 {# day} other {# days} } after booking confirmation`,
          { after: payment?.after }
        ),
        [PAYMENT_DATE_TYPE.DATE]: t('You will not be charged until {date}', {
          date: moment(payments[0]?.date).format(US_DATE_FORMAT),
        }),
      },
    };

    switch (bookingType) {
      case bookingTypes.INSTANT:
        return t('You will not be charged until {date}', {
          date: moment(payments[0]?.date).format(US_DATE_FORMAT),
        });
      case bookingTypes.INQUIRY:
        return PAYMENT_TRANSLATION[paymentsCountType][paymentDateType];
      default:
        return '';
    }
  };

  return (
    <TextField variant="h7" className={classes.root} align="center">
      {getTranslation({ payment: firstPayment })}
    </TextField>
  );
};

export default FirstPaymentDate;
