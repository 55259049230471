import FeatureToggleProvider from '@guestyci/feature-toggle-fe/FeatureToggleProvider';

import { env } from 'api';
import useGetWebsiteSettings from 'hooks/useGetWebsiteSettings';

const FeatureToggleContext = ({ children }) => {
  const { data, isLoading } = useGetWebsiteSettings({ language: null, enabled: true });
  const { accountData: { accountId } = {} } = data || {};

  if (isLoading) {
    return null;
  }
  return (
    <FeatureToggleProvider accountId={accountId} env={env}>
      {children}
    </FeatureToggleProvider>
  );
};

export default FeatureToggleContext;
