import { useRouteMatch } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { NEW_TRANSLATIONS_UI } from 'constants/featureToggleNames';
import useLocale from './useLocale';

const useGetPathToNavigate = () => {
  const [isFtLoading, isTranslationsEnabled] = useFeatureToggle(NEW_TRANSLATIONS_UI);
  const match = useRouteMatch();
  const locale = useLocale();

  const getPathWithLocale = (path) => {
    return isTranslationsEnabled ? `/${locale}${path}` : path;
  };

  return {
    path: match.url,
    locale,
    isTranslationsEnabled,
    isFtLoading,
    getPathWithLocale,
  };
};

export default useGetPathToNavigate;
