import { useState } from 'react';
import CommonAccordion from '@guestyci/foundation/Accordion';
import TextField from '@guestyci/foundation/TextField';
import useCancellationPolicy from 'hooks/useCancellationPolicy';
import useMealPlan from 'hooks/useMealPlan';

const jssOverride = {
  content: {
    paddingTop: '7px',
    paddingBottom: '0px',
  },
  header: {},
};
const Accordion = ({ children, title, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <CommonAccordion
      className={className}
      title={
        <TextField color="primary" variant="h5">
          {title}
        </TextField>
      }
      onChange={() => setIsOpen(!isOpen)}
      open={isOpen}
      jss={jssOverride}
    >
      {children}
    </CommonAccordion>
  );
};

const MealPlans = ({ mealPlans }) => {
  const { isAccordion, title, description } = useMealPlan({ mealPlans });

  if (!isAccordion) {
    return (
      <TextField color="primary" className="mb-2 pt-0">
        {title}
      </TextField>
    );
  }

  return (
    <Accordion title={title} className="mb-0 pt-0">
      <TextField color="secondary">{description}</TextField>
    </Accordion>
  );
};

const CancellationPolicy = ({ ratePlan, defaultCheckInTime, withAccordion = true }) => {
  const { cancellationFee, cancellationPolicy, mealPlans } = ratePlan;

  const { refundTitle, refundDescription } = useCancellationPolicy({
    cancellationPolicy,
    cancellationFee,
    defaultCheckInTime,
  });

  if (!withAccordion) {
    return (
      <div className="mt-2">
        <TextField color="primary">{refundTitle}</TextField>
        <TextField color="secondary">{refundDescription}</TextField>
        {mealPlans?.length ? <MealPlans mealPlans={mealPlans} /> : null}
      </div>
    );
  }

  return (
    <>
      <Accordion title={refundTitle} className="pt-2">
        <TextField color="secondary">{refundDescription}</TextField>
      </Accordion>
      {mealPlans?.length ? <MealPlans mealPlans={mealPlans} /> : null}
    </>
  );
};

export default CancellationPolicy;
