/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { useContext } from 'react';
import Slider from 'react-slick';
import SmallPropertyCard from 'components/SmallPropertyCard';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Icon from 'components/Icon';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useDio from 'hooks/useDio';

const useStyles = createStyles(({ breakpoints: { create, down } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  featuredListingsCarousel: {
    width: '100%',
    maxWidth: 1203,
    '& .slick-slide.slick-current': {
      [create('xs')]: {
        marginRight: 20,
      },
      [create('md')]: {
        marginRight: 0,
      },
    },
    '& .slick-slide > div': {
      display: 'flex',
      justifyContent: 'center',
      margin: '0',
      [create('md')]: {
        margin: '0 15px',
      },
    },
    '& .slick-list': {
      overflowY: 'visible',
      overflowX: 'hidden',
      margin: '0 -15px',
      [create('xs')]: {
        height: 460,
        overflowY: 'hidden',
      },
      [down('sm')]: {
        overflowY: 'hidden',
      },
      [create('md')]: {
        height: 570,
      },
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    textAlign: 'center',
  },
  arrow: {
    width: 45,
    height: 45,
    position: 'absolute',
    '&:before': {
      content: 'none',
    },
    '&.slick-prev': {
      [create('xs')]: {
        left: -14,
      },
      '@media(min-width: 375px)': {
        left: 0,
        zIndex: 1,
      },
      [create('xl')]: {
        left: -50,
      },
    },
    '&.slick-next': {
      [create('xs')]: {
        right: -14,
      },
      '@media(min-width: 375px)': {
        right: 0,
      },
      [create('xl')]: {
        right: -50,
      },
    },
  },
}));

const CustomArrow = ({ onClick, className, icon }) => {
  const { arrow } = useStyles();
  return (
    <div className={cn(arrow, className)} onClick={onClick}>
      <Icon icon={icon} size={45} />
    </div>
  );
};

const sliderSettings = ({ slides }) => ({
  infinite: slides > 2,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  lazyLoad: true,
  nextArrow: <CustomArrow icon="arrow_next" />,
  prevArrow: <CustomArrow icon="arrow" />,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: slides > 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: slides > 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
});

const FeatureListingsCarousel = ({ listings, context }) => {
  const { featuredListingsCarousel } = useStyles();
  const { dioTrack } = useDio();

  const handleClick = (property, index) => {
    dioTrack(`click_suggest_listings_${context}`, 'click', {
      listings: [
        {
          listing_id: property.id,
          index,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    });
  };
  return (
    <Slider {...sliderSettings({ slides: listings.length })} className={featuredListingsCarousel}>
      {listings?.map((property, index) => (
        <SmallPropertyCard handleClick={() => handleClick(property, index)} key={property._id} property={property} />
      ))}
    </Slider>
  );
};

const FeaturedListings = ({ context }) => {
  const { root, titleWrapper } = useStyles();
  const { contentConfiguration } = useContext(WebsiteSettingsContext);

  const {
    active = false,
    payload: {
      desc: description,
      ttl: title,
      title: sectionTitle,
      description: sectionDescription,
      includedListings = [],
    } = {},
  } = contentConfiguration?.sections?.FEATURED_LISTINGS || contentConfiguration?.sections?.LISTING || {};

  if (includedListings.length === 0) return null;
  if (!active) return null;

  return (
    <div data-featured-property className={root}>
      <div className={titleWrapper}>
        <TextField variant="h2">{title || sectionTitle}</TextField>
        <TextField>{description || sectionDescription}</TextField>
      </div>
      {includedListings.length > 1 && <FeatureListingsCarousel context={context} listings={includedListings} />}
      {includedListings.length === 1 && <SmallPropertyCard property={includedListings[0]} />}
    </div>
  );
};

export default FeaturedListings;
