import { useState } from 'react';
import cn from 'classnames';

import TextField from '@guestyci/foundation/TextField/TextField';
import { Col } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';

import Icon from 'components/Icon';

const useStyles = createStyles((theme) => ({
  root: {
    padding: `${theme.spacer(2)}px ${theme.spacer(1)}px}`,
    width: 170,
  },
  additionalRules: {
    margin: `${theme.spacer(6)}px 0 0`,
  },
  lineBreak: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  noteContainer: {
    fontSize: 12,
    whiteSpace: 'pre',
  },
  accordionContainer: {
    lineHeight: '16px',
    fontSize: 12,
    textAlign: 'center',
  },
  title: {
    fontSize: 14,
    cursor: 'pointer',
  },
  accordionBody: {
    background: theme.palette.background.system,
    padding: theme.spacer(2),
    marginTop: theme.spacer(2),
    transition: 'transform 0.15s ease',
    transformOrigin: 'top',
    overflow: 'hidden',
    height: 0,
    transform: 'scaleY(0)',

    '&.open': {
      height: 'auto',
      transform: 'scaleY(1)',
      transformOrigin: 'top',
      transition: 'transform 0.25s ease-out',
    },
  },
}));

export const HouseRuleItem = ({ text, icon, details, note }) => {
  const { root, lineBreak, accordionContainer, noteContainer, accordionBody, title } = useStyles();
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const handleSetMoreDetailsOpen = () => setMoreDetailsOpen(!moreDetailsOpen);
  const accordionTitle = moreDetailsOpen ? 'Less details' : 'More details';

  return (
    <Col className={root} align="center" key={`${icon}-${text}}`}>
      <Icon icon={icon} size={40} />
      <TextField className={lineBreak}>
        {text}
        {note && <div className={noteContainer}>{note}</div>}
      </TextField>
      {details && (
        <div className={accordionContainer}>
          <TextField onClick={handleSetMoreDetailsOpen} className={title} color="link">
            {accordionTitle}
          </TextField>
          <div className={cn(accordionBody, { open: moreDetailsOpen })}>{details}</div>
        </div>
      )}
    </Col>
  );
};

export default HouseRuleItem;
