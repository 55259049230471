import { ReactComponent as KingBed } from 'assets/icons/bedArrangement/king_bed.svg';
import { ReactComponent as DoubleBed } from 'assets/icons/bedArrangement/double_bed.svg';
import { ReactComponent as QueenBed } from 'assets/icons/bedArrangement/queen_bed.svg';
import { ReactComponent as SingleBed } from 'assets/icons/bedArrangement/single_bed.svg';
import { ReactComponent as SofaBed } from 'assets/icons/bedArrangement/sofa_bed.svg';
import { ReactComponent as AirMattress } from 'assets/icons/bedArrangement/air_mattress.svg';
import { ReactComponent as BunkBed } from 'assets/icons/bedArrangement/bunk_bed.svg';
import { ReactComponent as WaterBed } from 'assets/icons/bedArrangement/water_bed.svg';
import { ReactComponent as ToddlerBed } from 'assets/icons/bedArrangement/toddler_bed.svg';
import { ReactComponent as Crib } from 'assets/icons/bedArrangement/crib.svg';
import { ReactComponent as FloorMattress } from 'assets/icons/bedArrangement/flor_mattress.svg';
import { ReactComponent as FullBathroom } from 'assets/icons/bedArrangement/full_bathroom.svg';
import { ReactComponent as HalfBathroom } from 'assets/icons/bedArrangement/half_bathroom.svg';

export const BedIcons = {
  KING_BED: KingBed,
  QUEEN_BED: QueenBed,
  DOUBLE_BED: DoubleBed,
  SINGLE_BED: SingleBed,
  SOFA_BED: SofaBed,
  AIR_MATTRESS: AirMattress,
  BUNK_BED: BunkBed,
  FLOOR_MATTRESS: FloorMattress,
  WATER_BED: WaterBed,
  TODDLER_BED: ToddlerBed,
  CRIB: Crib,
  FULL_BATHROOM: FullBathroom,
  HALF_BATHROOM: HalfBathroom,
};

export default BedIcons;
