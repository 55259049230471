import { isCloudinaryImage } from 'utils';

const insertStringAfterText = (text, stringToInsert, stringToSearch) => {
  const index = stringToSearch.indexOf(text);
  return stringToSearch.slice(0, index + text.length) + stringToInsert + stringToSearch.slice(index + text.length);
};

const replaceText = (oldText, newText, stringToSearch) => {
  const index = stringToSearch.indexOf(oldText);
  if (index === -1) return stringToSearch;
  return stringToSearch.slice(0, index) + newText + stringToSearch.slice(index + oldText.length);
};

const generateCloudinaryUrlOriginal = ({ src, w, h, c, density = 1 }) => {
  const widthStr = 'w_';
  const heightStr = 'h_';
  const cropStr = 'c_';
  const imageHasWidth = src.includes(widthStr);
  const imageHasHeight = src.includes(heightStr);
  const imageHasCrop = src.includes(cropStr);

  const modifiers = [
    w && !imageHasWidth ? `${widthStr}${w * density}` : '',
    h && !imageHasHeight ? `${heightStr}${h * density}` : '',
    c && !imageHasCrop ? `${cropStr}${c}` : '',
  ]
    .filter(Boolean)
    .join(',');

  return insertStringAfterText('upload/', `${modifiers}/`, src).replace('upload//', 'upload/');
};

const generateCloudinaryUrlNew = ({ src, w, h, c, density = 1 }) => {
  const widthStr = 'w_';
  const heightStr = 'h_';
  const cropStr = 'c_';

  const preModStr = '/upload/';
  const idx = src.indexOf(preModStr);
  if (idx === -1) return src;
  const endIdx = src.indexOf('/', idx + preModStr.length);
  const modStr = endIdx === -1 ? '' : src.slice(idx + preModStr.length, endIdx); // e.g: "c_fit,h_200", "v1683762093"
  const hasMods = modStr.includes('_');

  const modifiers = [
    w ? `${widthStr}${w * density}` : '',
    h ? `${heightStr}${h * density}` : '',
    c ? `${cropStr}${c}` : '',
  ]
    .filter(Boolean)
    .join(',');

  return hasMods
    ? replaceText(`${modStr}/`, `${modifiers}/`, src)
    : insertStringAfterText('upload/', `${modifiers}/`, src);
};

const Image = ({ src, alt, w, h, c, className, isNewParser }) => {
  if (isCloudinaryImage(src)) {
    // NOTE: density increased twice to make both sources look similar on the same page
    // NOTE: density increased twice to make both sources look similar on the same page
    // source 1: https://guesty-listing-images.s3.amazonaws.com/production/original_53531459_1521260779.jpg
    // source 2: https://res.cloudinary.com/guesty/image/upload/v1667808665/production/62c6a93ac43b4e00361f3f9c/izo9pzmgxfdxbslz5vni.jpg
    // source 3: https://assets.guesty.com/image/upload/c_fit,h_200/v1683762093/production/640a80d2252d270038db2561/riq4te1ovqzwziptq3wl.jpg
    // source 4: https://res.cloudinary.com/guesty/image/upload/c_fit,h_200/fcqrlwp5i28lxuy5lblu
    // source 5: https://res.cloudinary.com/guesty/image/upload/fcqrlwp5i28lxuy5lblu
    // needs to be monitored as it might affect the performance for some of our users
    const generateCloudinaryUrl = isNewParser ? generateCloudinaryUrlNew : generateCloudinaryUrlOriginal;
    return (
      <img
        src={generateCloudinaryUrl({ src, w, h, c })}
        srcSet={`
                  ${generateCloudinaryUrl({ src, w, h, c, density: 6 })} 3x,
                  ${generateCloudinaryUrl({ src, w, h, c, density: 4 })} 2x,
                  ${generateCloudinaryUrl({ src, w, h, c, density: 2 })} 1x`}
        alt={alt}
        className={className}
      />
    );
  }

  return <img src={src} alt={alt} className={className} />;
};

export default Image;
