import { useContext, useEffect } from 'react';

import CheckOutInquiry from 'components/CheckOutInquiry';
import CheckOutInstant from 'components/CheckOutInstant';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useDio from 'hooks/useDio';
import bookingTypes from 'constants/bookingTypes';

const Checkout = () => {
  const { bookingType } = useContext(WebsiteSettingsContext);
  const { dioTrack } = useDio();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  dioTrack('view_checkout', 'pageview', {
    type: bookingType,
  });

  const renderFormByType = (type) => {
    switch (type) {
      case bookingTypes.INQUIRY:
        return <CheckOutInquiry />;
      case bookingTypes.INSTANT:
        return <CheckOutInstant />;
      default:
        return <CheckOutInquiry />;
    }
  };

  return renderFormByType(bookingType);
};

export default Checkout;
