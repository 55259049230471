import { useContext } from 'react';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const useGetRoomsLabel = () => {
  const {
    contentConfiguration: { roomName },
  } = useContext(WebsiteSettingsContext);

  if (!roomName) {
    return {
      isRoomLabel: true,
    };
  }
  return {
    isRoomLabel: roomName === 'room',
  };
};

export default useGetRoomsLabel;
