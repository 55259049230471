import { api } from 'api';

const URL = '/listings';

const getListingsCalendar = async ({ listingId, from, to }) => {
  const { data } = await api.get(`${URL}/${listingId}/calendar`, {
    params: { from, to },
  });
  return data;
};

export default getListingsCalendar;
