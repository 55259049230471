import { useContext, useState } from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import Col from '@guestyci/foundation/Layout/Col';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';

import { CurrencyContext } from 'context/CurrencyContext';
import currencySymbolMap from 'constants/currencies';
import { getSymbolFromCurrency } from 'utils';
import useDio from 'hooks/useDio';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const useStyles = createStyles(({ breakpoints: { create }, spacer, palette }) => ({
  root: {
    [create('xs')]: {
      marginRight: spacer(1),
    },
    [create('md')]: {
      marginRight: spacer(5),
    },
  },
  dropdown: {
    '& > div': {
      minWidth: 70,
      cursor: 'pointer',
    },
    '& [class*="active"]': {
      color: ({ textColor }) => textColor || palette.text.primary,
    },
    '& svg': {
      color: ({ textColor }) => textColor || palette.text.primary,
    },
  },
}));

const CurrencySelect = () => {
  const { contentConfiguration: { colorTheme: { colors = {} } = {} } = {} } = useContext(WebsiteSettingsContext);
  const { setSelectedCurrency, defaultCurrency } = useContext(CurrencyContext);
  const { root, dropdown } = useStyles({ textColor: colors?.headerLink });
  const { dioTrack } = useDio();
  const [currency, setCurrency] = useState(defaultCurrency);

  const getCurrencyText = (currencyName) => (
    <span>{`${currencyName} ${getSymbolFromCurrency(currencyName, currencySymbolMap)}`}</span>
  );

  const handleCurrencyChange = (value) => {
    const selectedCurrency = value.target.value;
    setCurrency(selectedCurrency);
    setSelectedCurrency(selectedCurrency);
    dioTrack('change_currency', 'side-effect', {
      currency: selectedCurrency,
      page: window.location.href,
    });
  };

  return (
    <Col spacing={2} className={root}>
      <Dropdown
        className={dropdown}
        value={currency}
        onChange={handleCurrencyChange}
        input={<DropdownLabelInput />}
        renderSelected={() => getCurrencyText(currency)}
        menuWidth={120}
      >
        {Object.keys(currencySymbolMap).map((option) => (
          <Option data-qa="currency-dropdown-item" value={option} key={option}>
            {getCurrencyText(option)}
          </Option>
        ))}
      </Dropdown>
    </Col>
  );
};

export default CurrencySelect;
