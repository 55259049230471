import { Circle, GoogleMap, InfoBox, Marker, MarkerClusterer, LoadScript } from '@react-google-maps/api';
import { memo, useRef, useState } from 'react';
import createStyles from '@guestyci/foundation/createStyles';

import MapPropertiesCarousel from 'components/MapPropertiesCarousel';

import useOutsideClick from 'hooks/useOutsideClick';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

import { CIRCLE_SETTINGS, GOOGLE_MAP_KEY, MAP_SETTINGS, MAP_STYLING, MARKER_SETTINGS } from 'constants/map';

import numberMarker from 'assets/icons/marker_with_number.svg';

const { DEFAULT_ZOOM, DEFAULT_CENTER, CIRCLE_ZOOM, CLUSTER_ZOOM, MAX_ZOOM, SHOW_BLUE_ICONS } = MAP_SETTINGS;

const containerMarkerStyle = {
  height: '73vh',
  width: '100%',
};

const containerCircleStyles = {
  width: '100%',
  height: '300px',
};

const infoBoxWidth = 380;
const infoBoxOffsetX = (-infoBoxWidth - 130) / 2;
const infoBoxOffsetY = -520;
const infoBoxWithMultiplePropertiesOffsetY = -530;

const useStyles = createStyles(() => ({
  infoBoxContainer: {
    transform: 'scale(0.7)',
  },
}));

const MapContainer = ({ markers = [], circle = false, center, containerStyles }) => {
  const { infoBoxContainer } = useStyles();
  const { locale = 'en-US' } = useGetPathToNavigate();
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [infoBoxData, setInfoBoxData] = useState(null);
  const [preventClosePropertyCard, setPreventClosePropertyCard] = useState(false);
  const infoBoxContentRef = useRef(null);
  const mapWrapperRef = useRef(null);

  useOutsideClick({
    elementRef: infoBoxContentRef,
    wrapperRef: mapWrapperRef,
    handler: () => setShowInfoBox(false),
    preventHandler: preventClosePropertyCard,
  });

  const onMarkerClick = (similarListings) => {
    setInfoBoxData(null);
    setShowInfoBox(true);
    setInfoBoxData(similarListings);
  };

  const togglePreventClosePropertyCard = () => {
    setPreventClosePropertyCard(!preventClosePropertyCard);
  };

  const renderMarkerCluster = () => (
    <>
      <MarkerClusterer defaultMaxZoom={CLUSTER_ZOOM} maxZoom={CLUSTER_ZOOM} options={SHOW_BLUE_ICONS}>
        {(clusterer) =>
          markers.map((marker) => {
            const { position, properties } = marker;
            const isMultipleListingsAtTheSamePosition = properties.length > 1;
            return properties?.map(({ _id }) => (
              <Marker
                animation={2}
                key={`${_id}_${position.lat + position.lng}`}
                position={position}
                style={{ position: 'relative' }}
                clusterer={clusterer}
                onClick={() => onMarkerClick(marker)}
                icon={{
                  ...(isMultipleListingsAtTheSamePosition && {
                    url: numberMarker,
                    labelOrigin: new window.google.maps.Point(17, 15),
                  }),
                  ...(!isMultipleListingsAtTheSamePosition && {
                    path: MARKER_SETTINGS.PATH,
                    labelOrigin: new window.google.maps.Point(0, 7),
                  }),
                  fillOpacity: circle ? MARKER_SETTINGS.OPACITY.CIRCLE : MARKER_SETTINGS.OPACITY.NO_CIRCLE,
                  scale: 1,
                  strokeColor: circle ? MARKER_SETTINGS.STROKE_COLOR.CIRCLE : MARKER_SETTINGS.STROKE_COLOR.NO_CIRCLE,
                  strokeWeight: MARKER_SETTINGS.STROKE_WEIGHT,
                }}
                label={
                  isMultipleListingsAtTheSamePosition && {
                    text: properties.length.toString(),
                    fontSize: '11px',
                  }
                }
              />
            ));
          })}
      </MarkerClusterer>
      {showInfoBox && infoBoxData?.position && window.google.maps && (
        <InfoBox
          position={infoBoxData.position}
          options={{
            pixelOffset: new window.google.maps.Size(
              infoBoxOffsetX,
              infoBoxData.properties.length > 1 ? infoBoxWithMultiplePropertiesOffsetY : infoBoxOffsetY
            ),
            closeBoxURL: '',
            boxStyle: {
              minWidth: '510px',
              height: '550px',
              overflow: 'hidden',
            },
            enableEventPropagation: true,
          }}
        >
          <div ref={infoBoxContentRef} className={infoBoxContainer}>
            <MapPropertiesCarousel properties={infoBoxData.properties} />
          </div>
        </InfoBox>
      )}
    </>
  );

  const renderMarkerCircle = () => <Circle center={center} options={CIRCLE_SETTINGS} />;

  const onLoad = (mapInstance) => {
    if (center) return;
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position: { lat, lng } }) => {
      if (!lat || !lng) return;
      bounds.extend(new window.google.maps.LatLng(lat, lng));
      mapInstance.fitBounds(bounds);
    });
  };
  const renderMap = () => {
    const zoom = circle ? CIRCLE_ZOOM : DEFAULT_ZOOM;
    const containerStyle = containerStyles || (circle ? containerCircleStyles : containerMarkerStyle);
    return (
      <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY} language={locale}>
        <div ref={mapWrapperRef}>
          <GoogleMap
            zoom={zoom}
            center={center || DEFAULT_CENTER}
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            options={{
              styles: MAP_STYLING,
              maxZoom: MAX_ZOOM,
            }}
            onDragStart={togglePreventClosePropertyCard}
            onDragEnd={togglePreventClosePropertyCard}
          >
            {markers && !circle && renderMarkerCluster()}
            {circle && renderMarkerCircle()}
          </GoogleMap>
        </div>
      </LoadScript>
    );
  };

  return renderMap();
};

export default memo(MapContainer);
