import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

import createInquiryReservation from 'api/quote/createInquiryReservation';
import createInquiryGroupReservation from 'api/quote/createInquiryGroupReservation';

import { createSingleInquiryReservationParams, createGroupReservationParams } from 'utils';

import useTrackCreateReservationSuccess from './useTrackCreateReservationSuccess';
import useTrackCreateReservationError from './useTrackCreateReservationError';

import useSearchValues from './useSearchValues';
import useGetPathToNavigate from './useGetPathToNavigate';
import useIsGroupReservation from './useIsGroupReservation';

const useCreateInquiry = () => {
  return useMutation(createInquiryReservation);
};

const useCreateInquiryGroup = () => {
  return useMutation(createInquiryGroupReservation);
};

const useSubmitInquiry = ({ property }) => {
  const history = useHistory();
  const { pointofsale, rooms } = useSearchValues();
  const { getPathWithLocale, locale } = useGetPathToNavigate();
  const { isGroupReservation, isGroupReservationEnabled } = useIsGroupReservation();
  const successPath = getPathWithLocale('/inquiry-success');
  const { state } = useLocation();
  const { mutateAsync: handleCreateInquiryReservation, isLoading: isCreatingInquiry } = useCreateInquiry();
  const { mutateAsync: handleCreateInquiryGroupReservation, isLoading: isCreatingInquiryGroupReservation } =
    useCreateInquiryGroup();
  const { trackSuccess } = useTrackCreateReservationSuccess({ property, provider: '', type: 'inquiry' });
  const { trackError } = useTrackCreateReservationError({ property, provider: '', type: 'inquiry' });

  const [reservationError, setReservationError] = useState(false);

  const isRequestingReservation = isCreatingInquiry || isCreatingInquiryGroupReservation;

  const submitInquirySingle = async (values) => {
    try {
      let selectedRatePlanId;
      let selectedQuoteId;
      if (isGroupReservationEnabled) {
        selectedRatePlanId = state?.ratePlan?._id;
        selectedQuoteId = state?.quoteData?.quote[0]?._id;
      } else {
        selectedRatePlanId = state?.ratePlanId;
        selectedQuoteId = state?.quoteId;
      }
      const params = createSingleInquiryReservationParams(values, selectedRatePlanId, pointofsale, locale);
      const reservation = await handleCreateInquiryReservation({
        quoteId: selectedQuoteId,
        params: {
          ...params,
        },
      });
      trackSuccess({
        response: reservation,
      });
      history.push(successPath);
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
      });
    }
  };
  const submitInquiryGroup = async (values) => {
    try {
      const ratePlanId = state?.ratePlan?._id;
      const quoteData = state?.quoteData;
      const params = createGroupReservationParams({ values, rooms, quoteData, ratePlanId, locale });
      const reservation = await handleCreateInquiryGroupReservation({
        params,
      });
      trackSuccess({
        response: reservation,
      });
      history.push(successPath);
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
      });
    }
  };

  const submitHandler = isGroupReservation ? submitInquiryGroup : submitInquirySingle;
  return {
    isRequestingReservation,
    reservationError,
    setReservationError,
    submitHandler,
  };
};

export default useSubmitInquiry;
