import { InView } from 'react-intersection-observer';

import PropertyCard from 'components/PropertyCard';

import Spinner from '@guestyci/foundation/Spinner';
import useDio from 'hooks/useDio';

const PropertyList = ({ properties, hasNextPage, isLoading, onIntersect, isFetchingNextPage }) => {
  const { dioTrack } = useDio();
  const handlePropertyCardClick = (property, index) => {
    dioTrack('click_select_item', 'click', {
      listings: [
        {
          listing_id: property._id,
          index,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
          recommended: !!property?.isRecommended,
        },
      ],
    });
  };

  return (
    <>
      {properties.map((property, index) => (
        <PropertyCard
          index={index}
          handlePropertyCardClick={handlePropertyCardClick}
          key={property._id}
          property={property}
        />
      ))}
      <InView
        as="div"
        onChange={(inView) => {
          if (inView && !isLoading && hasNextPage) {
            onIntersect();
          }
        }}
      />
      {isFetchingNextPage && (
        <div className="w-100 d-flex justify-content-center">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default PropertyList;
