/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import Button from '@guestyci/foundation/Button';
import Icon from '@guestyci/foundation/Icon';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { UPSELL } from 'constants/featureToggleNames';
import cn from 'classnames';
import { chunk } from 'lodash';

import Dialog, { DialogHeader, DialogFooter } from '@guestyci/foundation/Dialog';

import RaisedButton from '@guestyci/foundation/RaisedButton';
import FlatButton from '@guestyci/foundation/FlatButton';

import useIsOverflow from 'hooks/useIsOverflow';

import { ReactComponent as BtnDeleteMobile } from '@guestyci/icons/BtnDeleteMobile.svg';
import { ReactComponent as BtnRight } from '@guestyci/icons/BtnRight.svg';
import { ReactComponent as BtnLeft } from '@guestyci/icons/BtnLeft.svg';

import { useMutation } from 'react-query';
import upsetUpsells from 'api/upsell/upsetUpsells';
import useGetUpsells from 'hooks/useGetUpsells';
import useSearchValues from 'hooks/useSearchValues';
import useIsMobile from 'hooks/useIsMobile';
import useGetListingById from 'hooks/useGetListingById';
import useDio from 'hooks/useDio';
import Slider from 'react-slick';
import { UpsellContext } from 'context/UpsellContext';
import CardItem, { CardBody } from './Card';

const useStyles = createStyles(({ spacer, breakpoints: { create, values }, boxShadow }) => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    boxShadow: boxShadow[1],
    borderRadius: 16,
    padding: '24px 0px 24px 16px',
    marginBottom: 30,
    [`@media (min-width: 1300px) and (max-width: ${values.xlg}px)`]: {
      width: `calc(100% - ${spacer(6)}px)`,
      maxWidth: '100%',
      marginRight: spacer(6),
    },
    [`@media (min-width: ${values.xl}px) and (max-width: 1370px)`]: {
      width: `calc(100% - ${spacer(6)}px)`,
      maxWidth: 670,
      marginRight: spacer(6),
    },
    [create('xs')]: {
      marginTop: 40,
      width: `calc(100% - ${spacer(2)}px)`,
    },
    '&:hover $arrowContainer': {
      display: 'flex',
    },
  },
  rootCollapsed: {
    display: 'inline-block',
    width: 220,
  },
  rightPadding: {
    paddingRight: 16,
  },
  lastMargin: {
    '&:last-child': {
      marginRight: 16,
    },
  },
  containerHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  topButtonsSection: {
    marginLeft: 'auto',
  },
  showButton: {
    color: '#206CFF',
    marginLeft: 10,
  },
  closeButton: {
    transform: 'scale(0.7)',
    marginLeft: 'auto',
  },
  carouselContainerWrapper: {
    position: 'relative',
  },
  carouselContainer: {
    marginTop: 10,
    display: 'flex',
    gap: 10,
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    alignItems: 'stretch',
  },
  navBtn: {
    background: '#10275B',
    color: 'white',
    cursor: 'pointer',
    borderRadius: 50,
    padding: 15,
    transform: 'scale(0.5)',
    pointerEvents: 'auto',
    margin: 'auto',
  },
  navContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    pointerEvents: 'auto',
  },
  hoverContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    '&:hover $navContainer': {
      display: 'flex',
    },
  },
  prevButton: {
    position: 'absolute',
    top: '40%',
    background: '#10275B',
    color: 'white',
    cursor: 'pointer',
    borderRadius: 50,
    padding: 15,
    transform: 'scale(0.5)',
  },
  dialContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    maxWidth: 820,
    padding: '30px 40px 30px 30px',
  },
  dialSection: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 10,
    overflowX: 'hidden',
    alignItems: 'stretch',
  },
  mobileDialogRoot: {
    minWidth: 315,
    margin: 10,
    borderRadius: 8,
    overflow: 'hidden',
  },
  image: {
    '& > img': {
      width: '100%',
      height: 130,
      objectFit: 'cover',
    },
  },
  arrowContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'none',
    pointerEvents: 'none',
  },
  arrowRight: {
    right: 0,
  },
  mobileSlider: {
    marginBottom: 40,
    '& > .slick-list': {
      display: 'flex',
      '& > .slick-track': {
        width: '98vw !important',
        display: 'flex',
        gap: 10,
        '& > .slick-slide': {
          width: '180px !important',
          display: 'flex',
          height: 210,
          '& > div': {
            width: 180,
            border: 'solid #D1D5DB 1px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderRadius: 8,
          },
        },
      },
    },
  },
}));

const ShowAllDialog = ({ items, openConfirm, handleClose, onReadmoreClick, expandedItem, currency }) => {
  const { dialContent, dialSection } = useStyles();

  if (!items) return null;

  return (
    <Dialog open={openConfirm} onClose={handleClose}>
      <DialogHeader>{t('Upgrade your stay')}</DialogHeader>

      <div className={dialContent}>
        {items &&
          chunk(items, 4).map((chunkedItems) => {
            return (
              <div className={dialSection}>
                {chunkedItems.map((item) => (
                  <CardItem
                    key={item._id}
                    item={item}
                    onReadmoreClick={onReadmoreClick}
                    isExpanded={item._id === expandedItem}
                    currency={currency}
                    source="popup"
                  />
                ))}
              </div>
            );
          })}
      </div>

      <DialogFooter>
        <FlatButton onClick={handleClose}>{t(' Cancel ')}</FlatButton>
        <RaisedButton onClick={handleClose}>{t(' Ok ')}</RaisedButton>
      </DialogFooter>
    </Dialog>
  );
};

const ReadmoreDialog = ({ item, openConfirm, handleClose, listingId, currency }) => {
  const { mobileDialogRoot } = useStyles();
  return (
    <Dialog className={mobileDialogRoot} open={openConfirm} onClose={handleClose}>
      <CardBody item={item} isExpanded listingId={listingId} currency={currency} source="popup" />
    </Dialog>
  );
};

const UpsellCarousel = () => {
  const {
    root,
    containerHeader,
    showButton,
    closeButton,
    carouselContainer,
    carouselContainerWrapper,
    navBtn,
    mobileSlider,
    arrowContainer,
    arrowRight,
    rightPadding,
    lastMargin,
    topButtonsSection,
    rootCollapsed,
  } = useStyles();

  const ref = useRef();

  const { state } = useLocation();
  const { listingId } = useSearchValues();

  const { dioTrack } = useDio();

  const { setDataFromState } = useContext(UpsellContext);

  const { inquiryId } = state;

  const {
    upsells: items,
    isUpsellLoading,
    isUpsellError,
  } = useGetUpsells({
    listingId,
    quoteId: inquiryId,
  });

  const [, isFTUpsellEnable] = useFeatureToggle(UPSELL);

  const { isLoading: isAddingUpsells } = useMutation(upsetUpsells);
  const isMobile = useIsMobile();

  const [isHide, setIsHide] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const { property, isGettingListing } = useGetListingById({ id: listingId });

  const isOverflow = useIsOverflow(ref, items);

  const handleOnReadmoreClick = (item) => {
    dioTrack('click_show_more', 'click', { upsell: item });
    if (isMobile) {
      setSelectedItem(item);
      setOpenConfirm((prevState) => !prevState);
    } else if (expandedItem === item._id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(item._id);
    }
  };

  const handleOpenConfirm = () => {
    dioTrack('click_show_all_widget', 'click');
    setOpenConfirm((prevState) => !prevState);
    setIsHide(true);
  };

  const handleClose = () => {
    setOpenConfirm(false);
  };

  const settingsMain = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    centerMode: false,
    swipeToSlide: true,
  };

  const scrollOneItemRight = () => {
    ref.current.scrollLeft += 100;
  };

  const scrollOneItemLeft = () => {
    ref.current.scrollLeft -= 100;
  };

  useEffect(() => {
    setDataFromState(state);
  }, [state, setDataFromState]);

  useEffect(() => {
    if (isFTUpsellEnable && items && items?.length > 0) {
      dioTrack('view_selected_item', 'pageview', {
        items,
        total: items?.length,
      });
    }
  }, [items]);

  if (!isFTUpsellEnable || isUpsellError || isUpsellLoading || !items || items?.length < 1 || isGettingListing)
    return null;
  if (isMobile)
    return (
      <>
        <ReadmoreDialog
          item={selectedItem}
          isLoading={isAddingUpsells}
          openConfirm={openConfirm}
          handleClose={handleClose}
          listingId={listingId}
          currency={property?.prices?.currency}
        />
        <Slider className={mobileSlider} {...settingsMain}>
          {items?.map((item) => (
            <CardBody
              key={item._id}
              item={item}
              onReadmoreClick={handleOnReadmoreClick}
              isExpanded={false}
              currency={property?.prices?.currency}
              source="widget"
            />
          ))}
        </Slider>
      </>
    );

  return (
    <div className={cn(root, { [rightPadding]: !isOverflow, [rootCollapsed]: isHide })}>
      <ShowAllDialog
        items={items}
        onReadmoreClick={handleOnReadmoreClick}
        openConfirm={openConfirm}
        handleClose={handleClose}
        expandedItem={expandedItem}
        currency={property?.prices?.currency}
      />
      <div className={cn(containerHeader, { [rightPadding]: isOverflow })}>
        <TextField bold>{t('Add to your stay')}</TextField>
        <div className={topButtonsSection}>
          {!isHide && (
            <Button className={closeButton} onClick={() => setIsHide(true)}>
              <Icon svg={BtnDeleteMobile} />
            </Button>
          )}
          {(isOverflow || isHide) && (
            <Button className={showButton} onClick={handleOpenConfirm}>
              {t('Show all')}
            </Button>
          )}
        </div>
      </div>
      {!isHide && (
        <div className={carouselContainerWrapper}>
          <div className={carouselContainer} ref={ref}>
            {items?.map((item) => (
              <CardItem
                className={{ [lastMargin]: isOverflow }}
                key={item._id}
                item={item}
                onReadmoreClick={handleOnReadmoreClick}
                isExpanded={item._id === expandedItem}
                currency={property?.prices?.currency}
                source="widget"
              />
            ))}
          </div>
          {isOverflow && (
            <>
              <div className={arrowContainer}>
                <Button className={navBtn} onClick={scrollOneItemLeft}>
                  <Icon svg={BtnLeft} />
                </Button>
              </div>
              <div className={cn(arrowContainer, arrowRight)}>
                <Button className={navBtn} onClick={scrollOneItemRight}>
                  <Icon svg={BtnRight} />
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UpsellCarousel;
