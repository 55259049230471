import { api } from 'api';

const URL = '/reservations/quotes';
const addCoupon = async ({ quoteId, coupon }) => {
  const { data } = await api.post(`${URL}/${quoteId}/coupons`, {
    coupons: coupon ? [coupon] : [],
  });
  return data;
};

export default addCoupon;
