import { api } from 'api';

const URL = '/listings/cities';

// note: api returns all cities at once
const getCities = async () => {
  const {
    data: { results },
  } = await api.get(URL);

  return results || [];
};

export default getCities;
