import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import createStyles from '@guestyci/foundation/createStyles';
import Col from '@guestyci/foundation/Layout/Col';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { countryCodeToLabel } from 'constants/languages';
import { NEW_TRANSLATIONS_UI } from 'constants/featureToggleNames';
import useLocale from 'hooks/useLocale';

const useStyles = createStyles(({ breakpoints: { create }, spacer, palette }) => ({
  root: {
    [create('xs')]: {
      marginRight: spacer(1),
    },
    [create('md')]: {
      marginRight: spacer(5),
    },
  },
  dropdown: {
    '& > div': {
      minWidth: 70,
      cursor: 'pointer',
    },
    '& [class*="active"]': {
      color: ({ textColor }) => textColor || palette.text.primary,
    },
    '& svg': {
      color: ({ textColor }) => textColor || palette.text.primary,
    },
  },
}));

const SelectLanguageDropdown = () => {
  const [isFtLoading, isTranslationsEnabled] = useFeatureToggle(NEW_TRANSLATIONS_UI);
  const history = useHistory();
  const locale = useLocale();
  const { contentConfiguration: { colorTheme: { colors = {} } = {} } = {} } = useContext(WebsiteSettingsContext);
  const { root, dropdown } = useStyles({ textColor: colors?.headerLink });
  const [selectedCountryCode, setSelectedCountryCode] = useState(locale);
  const [languageList, setLanguageList] = useState([]);

  const { enginesData: { languages = [] } = {} } = useContext(WebsiteSettingsContext);

  useEffect(() => {
    if (languages.length) {
      const languageStrings = languages.map(({ language: lang }) => lang);
      setLanguageList(languageStrings);
    }
  }, [languages]);

  if (isFtLoading) {
    return null;
  }

  if (!isTranslationsEnabled) return null;
  if (languages?.length === 1) return null;

  const changeLanguage = (newLanguage) => {
    // Remove the existing locale from the path
    const currentPathWithoutLocale = history.location.pathname.replace(`/${locale}`, '');

    // Construct the new path with the new language
    const newPath = `/${newLanguage}${currentPathWithoutLocale}`;

    // Use history.replace to update the URL without adding a new entry to the history stack
    history.replace({
      pathname: newPath,
      search: history.location.search,
      state: history.location.state,
    });
  };

  const handleLanguageChange = (e) => {
    const { value } = e.target;
    setSelectedCountryCode(value);
    changeLanguage(value);
  };

  return (
    <Col spacing={2} className={root}>
      <Dropdown
        className={dropdown}
        value={selectedCountryCode}
        onChange={handleLanguageChange}
        input={<DropdownLabelInput />}
        renderSelected={() => countryCodeToLabel(selectedCountryCode)}
        menuWidth={120}
      >
        {languageList.map((lang) => (
          <Option data-qa="currency-dropdown-item" value={lang} key={lang}>
            {countryCodeToLabel(lang)}
          </Option>
        ))}
      </Dropdown>
    </Col>
  );
};

export default SelectLanguageDropdown;
