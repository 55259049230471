import { useMemo, Children } from 'react';

const StepWizard = ({ children, activeStep }) => {
  const activeStepContent = useMemo(() => {
    const stepList = Children.toArray(children);
    return stepList[activeStep];
  }, [activeStep, children]);

  return <div className="w-100">{activeStepContent}</div>;
};

export default StepWizard;
