import moment from 'moment';
import { useQuery } from 'react-query';

import getListingsCalendar from 'api/listings/getListingsCalendar';

import { DATE_FORMAT } from 'constants/date';
import { QUERY_IDS } from 'api';

const { GET_LISTINGS_CALENDAR } = QUERY_IDS;

const useGetCalendarAvailability = ({ listingId }) => {
  const from = moment().format(DATE_FORMAT);
  const to = moment().add(2, 'years').format(DATE_FORMAT);
  const { data, isSuccess, isLoading, isError } = useQuery(
    [GET_LISTINGS_CALENDAR, { listingId, from, to }],
    () =>
      getListingsCalendar({
        listingId,
        from,
        to,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    data,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useGetCalendarAvailability;
