import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import createDecorator from 'final-form-calculate';
import { Link } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import FormattedMessage from '@guestyci/localize/FormattedMessage';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Checkbox from '@guestyci/foundation/Checkbox';
import { FormProvider } from '@guestyci/foundation/enums';
import Form from '@guestyci/foundation/Form';
import Divider from '@guestyci/foundation/Divider';
import FormField from '@guestyci/foundation/FormField';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import Breadcrumbs from 'components/Breadcrumbs';
import CheckOutSummary from 'components/CheckOutSummary';
import GuestInformation from 'components/GuestInformation';
import ReservationErrorDialog from 'components/ErrorDialog/ReservationErrorDialog';
import CheckOutSummaryGroupReservation from 'components/CheckOutSummary/CheckOutSummaryGroupReservation';
import useSearchValues from 'hooks/useSearchValues';
import usePaymentTypeInitialValue from 'hooks/usePaymentTypeInitialValue';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import UpsellCarousel from 'components/UpsellCarousel';
import { useAnalytics } from 'analytics/hooks';
import BankTransferForm from 'components/InstantForm/BankTransferForm';
import useSubmitInstantBankTransfer from 'hooks/useSubmitInstantBankTransfer';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
    '& .col-wrapper': {
      '& [class*="Input-root"]': {
        background: '#fff',
      },
    },
  },
  contentWrapper: {
    background: '#FAFAFA',
    width: '100%',
    maxWidth: 1500,
    borderRadius: 20,
    display: 'flex',
    [create('xs')]: {
      flexWrap: 'wrap',
      padding: '10px 10px',
      justifyContent: 'center',
    },
    [create('md')]: {
      padding: '30px 30px 80px',
    },
    [create('lg')]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },
  title: {
    marginBottom: 30,
    marginTop: 30,
  },
  pci: {
    marginBottom: 30,
  },
  detailsRoot: {
    [create('lg')]: {
      maxWidth: 450,
    },
    [create('xl')]: {
      width: '100%',
      maxWidth: 600,
    },
  },
  agreements: {
    [create('xs')]: {
      width: 250,
    },
    [create('md')]: {
      width: '100%',
    },
  },
  privacyWrapper: {
    marginTop: 30,
    marginBottom: 20,
  },
  guestInfo: {
    marginBottom: '48px',
  },
  discountsWrapper: {},
  formContainer: {
    [create('xs')]: {
      width: '100%',
    },
    [create('xl')]: {
      width: 560,
    },
  },
}));

const required = (value) => (value ? undefined : 'Required');

const Summary = ({ submit, isLoading, isFormInvalid, onChangePriceAmount }) => {
  const [, isFtEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  if (isFtEnabled) {
    return (
      <CheckOutSummaryGroupReservation
        submit={submit}
        isLoading={isLoading}
        isFormInvalid={isFormInvalid}
        onChangePriceAmount={onChangePriceAmount}
      />
    );
  }

  return (
    <CheckOutSummary
      onChangePriceAmount={onChangePriceAmount}
      submit={submit}
      isLoading={isLoading}
      isFormInvalid={isFormInvalid}
    />
  );
};

const CheckOutInstantBankTransfer = ({ property }) => {
  const paymentTypeInitialValue = usePaymentTypeInitialValue();
  const {
    companyInfo: { name },
    userLocation,
  } = useContext(WebsiteSettingsContext);
  const [reservationTotalAmount, setReservationTotalAmount] = useState(null);
  const submitRef = useRef(null);
  const [isInvalid, setIsInvalid] = useState(true);
  const {
    isRequestingReservation,
    submitHandler,
    setReservationError,
    reservationError,
  } = useSubmitInstantBankTransfer({ property, reservationTotalAmount });

  const { minOccupancy, pointofsale, checkOutDateLocalized, checkInDateLocalized } = useSearchValues();
  const {
    root,
    contentWrapper,
    title,
    formRoot,
    detailsRoot,
    guestInfo,
    agreements,
    privacyWrapper,
    discountsWrapper,
    formContainer,
  } = useStyles();

  const { gtagEcommerceBeginCheckout, fbtrack } = useAnalytics();
  const { getPathWithLocale } = useGetPathToNavigate();

  const handleChangeReservationTotalAmmount = useCallback(
    (totalAmount) => {
      setReservationTotalAmount(totalAmount);
    },
    [setReservationTotalAmount]
  );

  const cardHolderNameDecorator = useMemo(
    () =>
      createDecorator({
        field: 'cardHolderNameAsGuest',
        updates: (value, _, allValues) =>
          value
            ? {
              ...allValues,
              cardHolderFirstName: undefined,
              cardHolderLastName: undefined,
            }
            : allValues,
      }),
    []
  );

  useEffect(() => {
    if (!reservationTotalAmount || !property) return;

    gtagEcommerceBeginCheckout({
      listingId: property._id,
      listingName: property.title,
      totalPrice: reservationTotalAmount,
      currency: property.prices.currency,
      checkInDate: checkInDateLocalized || null,
      checkOutDate: checkOutDateLocalized || null,
      numberOfGuests: minOccupancy || null,
      pointOfSale: pointofsale,
    });
    fbtrack('InitiateCheckout', {
      listing_id: [property._id],
      content_name: property.title,
      content_type: property.propertyType,
      currency: property.prices.currency,
      value: reservationTotalAmount,
      num_guests: minOccupancy,
      check_in_date: checkInDateLocalized,
      check_out_date: checkOutDateLocalized,
      point_of_sale: pointofsale,
    });
  }, [
    reservationTotalAmount,
    property,
    checkInDateLocalized,
    minOccupancy,
    checkOutDateLocalized,
    pointofsale,
    gtagEcommerceBeginCheckout,
    fbtrack,
  ]);

  return (
    <div className={root}>
      <div className={contentWrapper}>
        <div className={detailsRoot}>
          <Breadcrumbs />
          <TextField variant="h1" className={title}>
            {t('Fill in your details')}
          </TextField>
          <UpsellCarousel />
          <FinalForm
            onSubmit={submitHandler}
            decorators={[cardHolderNameDecorator]}
            provider={FormProvider.Final}
            mutators={{ ...arrayMutators }}
            initialValues={{
              paymentType: paymentTypeInitialValue,
              phone: userLocation?.countryPhoneCode,
            }}
            render={({ handleSubmit, invalid }) => {
              submitRef.current = handleSubmit;
              setIsInvalid(invalid);
              return (
                <Form
                  title="checkout"
                  onSubmit={handleSubmit}
                  provider={FormProvider.Final}
                  fieldInstance={Field}
                  className={formRoot}
                >
                  <div className={guestInfo}>
                    <GuestInformation />
                  </div>
                  <div className={formContainer}>
                    <BankTransferForm />
                  </div>
                  <Divider />
                  <FormField className={privacyWrapper} name="privacyPolicy" validate={[required]} type="checkbox">
                    <Checkbox>
                      <TextField className={agreements}>
                        <FormattedMessage
                          defaultMessage=" I have read and accept the <link>Privacy Policy</link>"
                          values={{
                            link: (chunks) => <Link to={getPathWithLocale('/privacy-policy')}>{chunks}</Link>,
                          }}
                        />
                        {' | '}
                        <FormattedMessage
                          defaultMessage="<link>{name}</link>"
                          values={{
                            name,
                            link: (chunks) => (
                              <Link to={getPathWithLocale('/terms')}>
                                {chunks}
                                {' '}
                                {t('Terms and Conditions')}
                              </Link>
                            ),
                          }}
                        />
                      </TextField>
                    </Checkbox>
                  </FormField>
                  <FormField className={discountsWrapper} name="discounts" type="checkbox">
                    <Checkbox>
                      <TextField className={agreements}>
                        {t('I am interested in receiving discounts, promotions and news about {name}', { name })}
                      </TextField>
                    </Checkbox>
                  </FormField>
                </Form>
              );
            }}
          />
        </div>
        <Summary
          onChangePriceAmount={handleChangeReservationTotalAmmount}
          submit={submitRef}
          isLoading={isRequestingReservation}
          isFormInvalid={isInvalid}
        />
      </div>
      <ReservationErrorDialog
        error={reservationError}
        open={!!reservationError}
        handleClose={() => setReservationError(false)}
      />
    </div>
  );
};

export default CheckOutInstantBankTransfer;
