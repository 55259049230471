import { useContext } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { privacyPolicyText, privacyPolicyTitle } from 'constants/privacyPolicyText';
import { BOOKING_ENGINE_NEW_SETUP, PRIVACY_POLICY_TERMS } from 'constants/featureToggleNames';

const useStyles = createStyles(() => ({
  root: {
    background: '#fff',
    padding: 20,
  },
  privacyWrapper: {
    whiteSpace: ({ applyPrivacyWrapper }) => (applyPrivacyWrapper ? 'pre-wrap' : 'normal'),
  },
}));

const PrivacyPolicy = () => {
  const { companyInfo: { email = null } = {}, contentConfiguration } = useContext(WebsiteSettingsContext);
  const [, features] = useFeatureToggle([BOOKING_ENGINE_NEW_SETUP, PRIVACY_POLICY_TERMS]);

  const isNewUiEnabled = features[BOOKING_ENGINE_NEW_SETUP];
  const isPrivacyPolicyTermsEnabled = features[PRIVACY_POLICY_TERMS];

  const policyData = contentConfiguration?.sections?.PRIVACY_POLICY || {};
  const termData = contentConfiguration?.sections?.TERMS || {};

  let description = privacyPolicyText({ email });
  let title = privacyPolicyTitle;
  let applyPrivacyWrapper = false;

  if (policyData?.payload?.title && policyData?.payload?.description) {
    description = policyData?.payload?.description;
    title = policyData?.payload?.title;
    applyPrivacyWrapper = true;
  } else if (
    termData?.active &&
    termData?.payload?.title &&
    termData?.payload?.description &&
    termData?.payload?.activeSection === 'DEFAULT' &&
    !isNewUiEnabled &&
    !isPrivacyPolicyTermsEnabled
  ) {
    // super legacy DISCO-privacy-policy-terms OFF - has one section for terms and policy
    description = termData?.payload?.description;
    title = termData?.payload?.title;
    applyPrivacyWrapper = true;
  }

  const { root, privacyWrapper } = useStyles({ applyPrivacyWrapper });

  return (
    <div className={cn(root, privacyWrapper)}>
      <TextField variant="h1">{parse(title)}</TextField>
      <TextField>{parse(description)}</TextField>
    </div>
  );
};

export default PrivacyPolicy;
