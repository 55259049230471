import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';

import { isEmpty } from 'lodash';

const Promotion = ({ promotion, className }) => {
  if (isEmpty(promotion) || !promotion) return null;
  const {
    name,
    rule: { discountAmount, discountType },
  } = promotion;

  const discountTypeToSymbol = discountType === 'percent' ? '%' : '$';
  return (
    <Row className={className} justify="between">
      <Row align="center">
        <TextField>{name}</TextField>
      </Row>
      <TextField>{`-${discountAmount}${discountTypeToSymbol}`}</TextField>
    </Row>
  );
};

export default Promotion;
