import Icon from 'components/Icon';
import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';
import t from '@guestyci/localize/t.macro';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    width: 400,
    [create('xs')]: {
      flexWrap: 'wrap',
    },
  },
  feature: {
    alignItems: 'center',
    [create('xs')]: {
      width: '50%',
      padding: 10,
    },
    [create('md')]: {
      width: '25%',
    },
  },
}));

const Feature = ({ title, icon, amount }) => {
  const { feature } = useStyles();
  return (
    <Col className={feature}>
      <Icon size={40} icon={icon} />
      <Row>
        <TextField color="secondary">{amount}</TextField>
        &nbsp;
        <TextField color="secondary">{title}</TextField>
      </Row>
    </Col>
  );
};

const HotelFeatures = ({ bedrooms, beds, guests, bathrooms }) => {
  const { root } = useStyles();
  const guestsPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Guests}
    =1 {Guest}
    few {Guests}
    many {Guests}
    other {Guests}}
  `,
    { num: guests }
  );
  const bedroomPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Bedrooms}
    =1 {Bedroom}
    few {Bedrooms}
    many {Bedrooms}
    other {Bedrooms}}
  `,
    { num: bedrooms }
  );

  const bathroomPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Bathrooms}
    =1 {Bathroom}
    few {Bathrooms}
    many {Bathrooms}
    other {Bathrooms}}
  `,
    { num: bathrooms }
  );

  const bedPluralLabel = t(
    `
  {
    num,
    plural,
    =0 {Beds}
    =1 {Bed}
    few {Beds}
    many {Beds}
    other {Beds}}
  `,
    { num: beds }
  );
  return (
    <Row className={root}>
      {!!bedrooms && <Feature icon="listing_bedrooms" title={bedroomPluralLabel} amount={bedrooms} />}
      {!!beds && <Feature icon="listing_beds" title={bedPluralLabel} amount={beds} />}
      {!!guests && <Feature icon="listing_guests" title={guestsPluralLabel} amount={guests} />}
      {!!bathrooms && <Feature icon="listing_bathroom" title={bathroomPluralLabel} amount={bathrooms} />}
    </Row>
  );
};

export default HotelFeatures;
