import { api } from 'api';

const URL = '/engines/content';
const getWebsiteSettings = async ({ language }) => {
  const { data } = await api.get(URL, {
    params: {
      ...(language && { lang: language }),
    },
  });
  return data;
};

export default getWebsiteSettings;
