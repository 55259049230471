import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(() => ({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #D4D8E1',
    borderRadius: 20,
    background: '#fff',
  },
}));

const SearchBarWrapper = ({ children, className }) => {
  const { root } = useStyles();
  return <div className={cn(root, className)}>{children}</div>;
};

export default SearchBarWrapper;
