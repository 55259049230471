import { Row } from '@guestyci/foundation/Layout';
import Icon from '@guestyci/foundation/Icon';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';
import Tooltip from '@guestyci/foundation/Tooltip';
import createStyles from '@guestyci/foundation/createStyles';

import { ReactComponent as CreditCardShieldIcon } from 'assets/icons/creditCardShield.svg';
import { ReactComponent as HelpCircleIcon } from 'assets/icons/helpCircle.svg';

const useStyles = createStyles({
  wrapper: {
    backgroundColor: '#fce8b5',
    padding: '8px 10px',
    borderRadius: 4,
  },
  helpIcon: {
    minWidth: 24,
  },
});

const ZeroAmountNotice = () => {
  const { wrapper, helpIcon } = useStyles();

  return (
    <Row spacing="2" align="center" className={wrapper}>
      <Icon width={32} height={32} svg={CreditCardShieldIcon} strokeColor="#2c3742" fillColor="#fce8b5" />
      <TextField color="dark">
        {t(
          `To securely authenticate your card, <b>your bank will send a €0</b> authorization to confirm, before processing the total amount.`,
          { b: (text) => <b>{text}</b> }
        )}
      </TextField>
      <Tooltip
        body={t(
          'We collect and authenticate your card solely to reserve the property. Your card will not be charged instantly but charged according to our payment policies.'
        )}
      >
        <Icon width="24" height="24" svg={HelpCircleIcon} fillColor="#2c3742" className={helpIcon} />
      </Tooltip>
    </Row>
  );
};

export default ZeroAmountNotice;
