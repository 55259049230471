export default {
  USD: '$', // US Dollar
  ARS: '$', // Argentine peso
  CLP: '$', // Chilean peso
  COP: '$', // Colombian peso
  NZD: '$', // New Zealand dollar
  PEN: 'S/.', // Peruvian sol
  AUD: 'A$', // Australian dollar
  BRL: 'R$', // Brazilian real
  HKD: 'HK$', // Honk kong dollar
  TWD: 'NT$', // New Taiwan dollar
  MXN: 'MEX$', // Mexican peso
  CAD: 'C$', // Canadian Dollar
  EUR: '€', // Euro
  HRK: 'kn', // Croatia kuna
  SEK: 'kr', // Swedish krona
  RON: 'lei', // Romanian leu
  CZK: 'Kč', // Czech koruna
  DKK: 'Kr.', // Danish krone
  NOK: 'Kr.', // Norwegian krone
  CHF: 'Fr.', // Swiss franc
  HUF: 'Ft', // Hungarian forint
  MYR: 'RM', // Malaysian ringgit
  ZAR: 'R', // South african rand
  MAD: 'DH', // Moroccan dirham
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  IDR: 'Rp', // Indonesian Rupiah
  JPY: '¥', // Japanese Yen
  CNY: '¥', // Chinese yuan
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  RUB: '₽', // Russian ruble
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  TRY: '₺', // Turkish lira
  AFN: '؋', // Afghan afghani
  AED: 'د.إ', // Emirati dirham
  SAR: 'ر.س', // Saudi Arabian riyal
  BGN: 'Лв.', // Bulgarian lev
};
