import { useContext } from 'react';
import { useFormState } from 'react-final-form';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import GuestInformation from 'components/GuestInformation';
import PaymentDetails from 'components/PaymentDetails/PaymentDetails';
import PaymentMethodSwitch from 'components/PaymentMethodSwitch';
import BankTransferInformation from 'components/BankTransferInformation';

import { BANK_TRANSFER } from 'constants/featureToggleNames';

const StripeInstantForm = () => {
  const {
    paymentTypes: {
      creditCard: creditCardPaymentTypeEnabled = true,
      bankTransfer: bankTransferPaymentTypeEnabled = false,
    } = {},
  } = useContext(WebsiteSettingsContext);
  const [isFtLoading, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);
  const formState = useFormState();
  const { cardHolderNameAsGuest = null, billingAddress = null } = formState?.values || {};

  if (isFtLoading) {
    return null;
  }

  return (
    <>
      <GuestInformation />
      {isBankTransferEnabled && (creditCardPaymentTypeEnabled || bankTransferPaymentTypeEnabled) ? (
        <PaymentMethodSwitch
          isCreditCardEnabled={creditCardPaymentTypeEnabled}
          isBankTransferEnabled={bankTransferPaymentTypeEnabled}
          creditCardForm={<PaymentDetails showBilling={!billingAddress} showCardHolderName={!cardHolderNameAsGuest} />}
          BankTransferInformation={<BankTransferInformation />}
        />
      ) : (
        <PaymentDetails showBilling={!billingAddress} showCardHolderName={!cardHolderNameAsGuest} />
      )}
    </>
  );
};

export default StripeInstantForm;
