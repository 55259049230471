import { useState } from 'react';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import { useLocation } from 'react-router-dom';
import { get, times } from 'lodash';

import createStyles from '@guestyci/foundation/createStyles';
import Checkbox from '@guestyci/foundation/Checkbox';
import t from '@guestyci/localize/t.macro';
import FormField from '@guestyci/foundation/FormField';
import Input from '@guestyci/foundation/Input';
import { email } from '@guestyci/foundation/validators';
import PhonePicker from '@guestyci/foundation/PhonePicker';

import { Col, Row } from '@guestyci/foundation/Layout';
import useSearchValues from 'hooks/useSearchValues';
import TextField from '@guestyci/foundation/TextField';
import useIsGroupReservation from 'hooks/useIsGroupReservation';

const useRoomDetailStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    padding: [25, 15],
    borderRadius: 4,
    border: '1px solid #D4D8E1',
  },
  row: {
    marginBottom: 20,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      [create('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
      [create('xl')]: {
        width: 255,
        marginBottom: 0,
      },
    },
  },
}));

const RoomDetails = ({ name, index }) => {
  const { root, row } = useRoomDetailStyles();
  const firstName = `${name}.guest.firstName`;
  const lastName = `${name}.guest.lastName`;
  const phone = `${name}.guest.phone`;
  const emailName = `${name}.guest.email`;

  const firstNameLabel = t('First Name');
  const lastNameLabel = t('Last Name');
  const phoneLabel = t('Phone');
  const emailLabel = t('Email');

  const firstNamePlaceholder = t('Guest first Name');
  const lastNamePlaceholder = t('Guest last Name');
  const phonePlaceholder = t('Guest phone number');
  const emailPlaceholder = 'example@email.com';

  return (
    <Col className={root}>
      <TextField bold className="mb-3" variant="h4">
        {t('Room {index}', { index: index + 1 })}
      </TextField>
      <Row className={row}>
        <FormField className="form-field" name={firstName} label={firstNameLabel} required>
          <Input placeholder={firstNamePlaceholder} />
        </FormField>
        <FormField className="form-field" name={lastName} label={lastNameLabel} required>
          <Input placeholder={lastNamePlaceholder} />
        </FormField>
      </Row>
      <Row className={row}>
        <FormField className="form-field" name={phone} label={phoneLabel} required>
          <PhonePicker withActions={false} placeholder={phonePlaceholder} />
        </FormField>
        <FormField className="form-field" name={emailName} validate={[email]} label={emailLabel} required>
          <Input placeholder={emailPlaceholder} />
        </FormField>
      </Row>
    </Col>
  );
};

const useStyles = createStyles(() => ({
  root: {
    gap: 30,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const GroupBooker = ({ handleChange = () => {} }) => {
  const { isGroupReservation } = useIsGroupReservation();
  const { state } = useLocation();
  const { rooms } = useSearchValues();
  const { root } = useStyles();
  const [useGroupBooker, setUserGroupBooker] = useState(true);
  const fieldArray = useFieldArray('quotes');
  const { fields } = fieldArray;

  const ratePlanId = state?.ratePlan?._id;
  const _handleChange = (e) => {
    const { checked } = e.target;
    setUserGroupBooker(checked);
    handleChange(checked);
    if (!checked) {
      times(rooms, (i) => {
        fields.push({
          ratePlanId,
          quoteId: get(state.quoteData.quote, `${i}._id`),
        });
      });
    } else {
      times(fields.length, fields.pop);
    }
  };

  if (!isGroupReservation) return null;

  return (
    <div className={root}>
      <Checkbox checked={useGroupBooker} onChange={_handleChange}>
        {t("Use group booker's contact information")}
      </Checkbox>
      <FieldArray name="quotes">
        {({ fields: array }) => array.map((name, i) => <RoomDetails key={name} name={name} index={i} />)}
      </FieldArray>
    </div>
  );
};

export default GroupBooker;
