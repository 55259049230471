// eslint-disable-next-line import/prefer-default-export
export const MARKER_SETTINGS = {
  PATH: 'M-0.0006900000000005235,-27 C-5.523121,-27 -10,-22.5240517 -10,-17.000689700000002 C-10,-9.727448299999999 -0.5634999999999994,0 0.2879310000000004,0 C1.1351720000000007,0 10.000017,-10.5844655 10.000017,-17.000689700000002 C9.999552000000001,-22.5240517 5.522672,-27 -0.0006900000000005235,-27 L-0.0006900000000005235,-27 Z M-0.048171999999999215,-13.6582759 C-2.2156209999999996,-13.6582759 -3.971552,-15.3671897 -3.971552,-17.4769138 C-3.971552,-19.5852414 -2.216086,-21.2960172 -0.048171999999999215,-21.2960172 C2.1197409999999994,-21.2960172 3.876137999999999,-19.5852414 3.876137999999999,-17.4769138 C3.876137999999999,-15.3671897 2.1197409999999994,-13.6582759 -0.048171999999999215,-13.6582759 L-0.048171999999999215,-13.6582759 Z',
  OPACITY: {
    CIRCLE: 0,
    NO_CIRCLE: 1,
  },
  STROKE_COLOR: {
    CIRCLE: 'transparent',
    NO_CIRCLE: 'white',
  },
  STROKE_WEIGHT: 1.5,
  CIRCLE: {
    RADIUS: 300,
    OPACITY: 0.35,
    STROKE_WEIGHT: 1,
  },
};

export const CIRCLE_SETTINGS = {
  strokeColor: '#1abdee',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#1abdee',
  fillOpacity: 0.4,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 300,
  zIndex: 1,
};

export const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lat: 57, lng: 20 },
  DEFAULT_ZOOM: 4,
  CLUSTER_ZOOM: 16,
  CIRCLE_ZOOM: 15,
  MAX_ZOOM: 18,
  MARKER_SIZE: 35,
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -35,
    },
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
  SHOW_BLUE_ICONS: { imageSizes: [53] },
};
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || 'AIzaSyBl_Dm8z-tL86EafllfN_TOF2zaW5lztlk';

export const MAP_STYLING = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dde8de',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#889094',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#889094',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#889094',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c4d1e6',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#868e94',
      },
    ],
  },
];
