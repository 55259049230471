import PaymentMethodSwitch from 'components/PaymentMethodSwitch';
import BankTransferInformation from 'components/BankTransferInformation';

const BankTransferForm = () => {
  return (
    <PaymentMethodSwitch
      BankTransferInformation={<BankTransferInformation />}
      creditCardForm={null}
      isBankTransferEnabled
      isCreditCardEnabled={false}
    />
  );

};

export default BankTransferForm;
