/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import requiredIconMap from 'assets/icons/iconMap';
import warning from 'fbjs/lib/warning';

import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(() => ({
  root: {
    width: '1em',
    height: '1em',
    fontSize: ({ fontSize }) => fontSize,
  },
}));

const Icon = ({ icon, className, size, onClick }) => {
  const { root } = useStyles({ fontSize: size });
  const iconUrl = requiredIconMap[icon]?.default;
  if (process.env.NODE_ENV === 'development') {
    warning(typeof iconUrl !== 'undefined', `No icon found for '${icon}'. Did you forget to add it to iconMap.js?`);
  }

  if (!iconUrl) return null;
  return <img src={iconUrl} alt={icon} className={cn(root, className)} onClick={onClick} />;
};

export default Icon;
