import { api } from 'api';

const contactUs = async ({ params }) => {
  const { data } = await api.post(`legacy/websites/message`, {
    ...params,
  });
  return data;
};

export default contactUs;
