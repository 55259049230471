import { useState } from 'react';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { ZERO_AMOUNT_NOTIFICATION } from 'constants/featureToggleNames';

const useZeroAmountNotificationDialog = () => {
  const [isFeatureLoading, isFeatureEnabled] = useFeatureToggle(ZERO_AMOUNT_NOTIFICATION);
  const [zeroAmountNotificationDialogIsOpen, changeZeroAmountNotificationDialogIsOpen] = useState(false);

  const openZeroAmountNotificationDialog = async () => {
    changeZeroAmountNotificationDialogIsOpen(true);
    await new Promise((resolve) => setTimeout(resolve, 6000));
    changeZeroAmountNotificationDialogIsOpen(false);
  };

  return { zeroAmountNotificationDialogIsOpen, openZeroAmountNotificationDialog, isFeatureLoading, isFeatureEnabled };
};

export default useZeroAmountNotificationDialog;
