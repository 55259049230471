import { useRouteMatch } from 'react-router-dom';
import { useMemo } from 'react';
import { shortLanguageList } from '../constants/languages';

const useLocale = () => {
  const { locale = 'en' } = useRouteMatch('/:locale')?.params || {};

  return useMemo(() => {
    return shortLanguageList.includes(locale) ? locale : 'en';
  }, [locale]);
};

export default useLocale;
