import { useContext } from 'react';

import t from '@guestyci/localize/t.macro';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import bookingTypes from 'constants/bookingTypes';

const useBookNowButtonText = () => {
  const { bookingType } = useContext(WebsiteSettingsContext);
  return bookingType === bookingTypes.INSTANT ? t('Book now') : t('Request to Book');
};

export default useBookNowButtonText;
