const CancellationPolicyKeys = {
  FREE: 'free',
  FLEX: 'flex',
  SEMI_FLEXIBLE: 'semi_flexible',
  SEMI_MODERATE: 'semi_moderate',
  MODERATE: 'moderate',
  FIRM: 'firm',
  STRICT: 'strict',
  STRICT_60: 'strict_60',
  NON_REFUNDABLE: 'super_strict',
};

export default CancellationPolicyKeys;
