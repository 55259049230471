import { api } from 'api';

const URL = '/listings';

const getListingById = async ({ id, params = {} }) => {
  const { data } = await api.get(`${URL}/${id}`, { params });
  return data;
};

export default getListingById;
