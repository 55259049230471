import { useContext, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';

import t from '@guestyci/localize/t.macro';
import Accordion from '@guestyci/foundation/Accordion';
import { Row, Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

import useGetPaymentSchedule from 'hooks/useGetPaymentSchedule';
import { PAYMENT_DATE_TYPE, US_DATE_FORMAT } from 'constants/date';
import PriceConverter from 'components/PriceConverter';
import { getPaymentDateType } from 'utils';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import bookingTypes from 'constants/bookingTypes';
import PaymentClarificationTooltip from './PaymentClarificationTooltip';

const useStyles = createStyles(({ palette, spacer }) => ({
  accordion: {
    '& button[class*=header]': {
      backgroundColor: palette.primary.backgroundLight,
      padding: spacer(2),
    },
    '& [class*=Accordion-content]': {
      padding: spacer(2),
    },
    '& [class*=Accordion-title]': {
      fontWeight: 400,
    },
  },
  payment: {
    color: '#6B7280',
  },
  paymentPrice: {
    color: '#1F2937',
  },
}));

const Payments = ({ total, currency, className }) => {
  const classes = useStyles();
  const { bookingType } = useContext(WebsiteSettingsContext);
  const { data: { payments = [] } = {} } = useGetPaymentSchedule({ total });
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getPaymentTranslation = ({ payment, i }) => {
    const dateType = getPaymentDateType(payment?.after) || PAYMENT_DATE_TYPE.DATE;
    const PAYMENT_TRANSLATION = {
      [bookingTypes.INSTANT]: {
        [PAYMENT_DATE_TYPE.DATE]: t(
          `{ num, plural, =1 {#st} =2 {#nd} =3 {#rd} few {#th} other {#th} } payment is on {date} `,
          { num: i, date: moment(payment?.date).format(US_DATE_FORMAT) }
        ),
      },
      [bookingTypes.INQUIRY]: {
        [PAYMENT_DATE_TYPE.AFTER]: t(
          `{ num, plural, =1 {#st} =2 {#nd} =3 {#rd} few {#th} other {#th} } payment is { after, plural, =1 {# day} other {# days} } after confirmation`,
          { num: i, after: payment?.after }
        ),
        [PAYMENT_DATE_TYPE.AT]: t(
          `{ num, plural, =1 {#st} =2 {#nd} =3 {#rd} few {#th} other {#th} } payment is at confirmation`,
          {
            num: i,
          }
        ),
        [PAYMENT_DATE_TYPE.DATE]: t(
          `{ num, plural, =1 {#st} =2 {#nd} =3 {#rd} few {#th} other {#th} } payment is on {date} `,
          { num: i, date: moment(payment?.date).format(US_DATE_FORMAT) }
        ),
      },
    };

    return PAYMENT_TRANSLATION[bookingType][dateType];
  };

  if (payments?.length <= 1) {
    return null;
  }

  return (
    <Accordion
      open={isOpen}
      className={cn(classes.accordion, className)}
      onChange={toggleAccordion}
      title={t('Your payment schedule')}
    >
      {payments?.map((payment, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col data-qa-payment-item key={`${payment?.date}-${i}`}>
            <Row justify="between">
              <Row align="center" spacing={2}>
                <TextField variant="h7" className={classes.payment}>
                  {getPaymentTranslation({ payment, i: i + 1 })}
                </TextField>
                {payment?.tooltip && <PaymentClarificationTooltip tooltip={payment?.tooltip} />}
              </Row>
              <TextField className={classes.paymentPrice}>
                <PriceConverter currency={currency} amount={payment?.amount} />
              </TextField>
            </Row>
          </Col>
        );
      })}
    </Accordion>
  );
};

export default Payments;
