import useSearchValues from './useSearchValues';
import useDio from './useDio';

const useTrackCreateReservationError = ({ property, type, provider }) => {
  const { dioTrack } = useDio();
  const { minOccupancy } = useSearchValues();

  const trackError = ({ error, additionalData }) => {
    dioTrack('booking_create_error', 'side-effect', {
      error,
      type,
      provider,
      ...additionalData,
      listings: [
        {
          guests: minOccupancy,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
        },
      ],
    });
  };
  return { trackError };
};

export default useTrackCreateReservationError;
