import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import t from '@guestyci/localize/t.macro';

import DateRangePickerAvailability from 'components/DateRangePickerAvailability';
import IconInput from 'components/IconInput';
import BookNowError from 'components/BookNowError';
import GuestsAndRoomsDropdown, { DropdownOption } from 'components/GuestsAndRoomsDropdown';
import useGetRoomsLabel from 'hooks/useGetRoomsLabel';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  dateRangePicker: {
    [create('xs')]: {
      width: '100%',
      '& .DateRangePicker_picker': {
        left: '-75px!important',
      },
    },
  },
}));

export const GroupReservationInputs = ({
  dateRange,
  setDateRange,
  listingId,
  isCreatingQuoteError,
  guestsCount,
  maxGuestsNumber,
  selectedGuestsCount,
  handleSetGuestsCount,
  selectedRoomsCount,
  handleSetRoomsCount,
  propertyType,
}) => {
  const { dateRangePicker } = useStyles();
  const { isRoomLabel } = useGetRoomsLabel();

  const isSingleUnit = propertyType === 'SINGLE';
  const overwriteRooms = isSingleUnit ? 1 : selectedRoomsCount;

  const roomLabel = t('{ num, plural, =0 {Rooms} =1 {Room} few {Rooms} many {Rooms} other {Rooms}}', {
    num: overwriteRooms,
  });

  const unitLabel = t('{ num, plural, =0 {Units} =1 {Unit} few {Units} many {Units} other {Units}}', {
    num: overwriteRooms,
  });

  const guestsLabel = t('{ num, plural, =0 {Guests} =1 {Guest} few {Guests} many {Guests} other {Guests}}', {
    num: selectedGuestsCount,
  });

  const maxGuestsNumberAdjusted = maxGuestsNumber * selectedRoomsCount;

  return (
    <div>
      <DateRangePickerAvailability
        dateRange={dateRange}
        setDateRange={setDateRange}
        listingId={listingId}
        className={cn('mb-4', dateRangePicker)}
      />

      <BookNowError data={{ isCreatingQuoteError, guestsCount, maxGuestsNumber }} />

      <GuestsAndRoomsDropdown className="mb-8" withIcon>
        <DropdownOption
          min={selectedRoomsCount}
          max={maxGuestsNumberAdjusted}
          value={selectedGuestsCount}
          handleChange={handleSetGuestsCount}
          label={guestsLabel}
        />
        <DropdownOption
          min={1}
          max={10}
          value={overwriteRooms}
          handleChange={handleSetRoomsCount}
          label={isRoomLabel ? roomLabel : unitLabel}
          disable={isSingleUnit}
          onChange={(value) => {
            handleSetRoomsCount({ target: { value } });
            if (value > selectedGuestsCount) {
              handleSetGuestsCount({ target: { value } });
            }
            if (selectedGuestsCount / value > maxGuestsNumber) {
              handleSetGuestsCount({ target: { value: maxGuestsNumber * value } });
            }
          }}
        />
      </GuestsAndRoomsDropdown>
    </div>
  );
};

const WidgetControls = ({
  dateRange,
  setDateRange,
  listingId,
  isCreatingQuoteError,
  guestsCount,
  maxGuestsNumber,
  selectedGuestsCount,
  handleSetGuestsCount,
  maxGuestsOptions,
}) => {
  const { dateRangePicker } = useStyles();

  return (
    <div>
      <DateRangePickerAvailability
        dateRange={dateRange}
        setDateRange={setDateRange}
        listingId={listingId}
        className={cn('mb-4', dateRangePicker)}
      />

      <BookNowError data={{ isCreatingQuoteError, guestsCount, maxGuestsNumber }} />

      <Dropdown
        placeholder={t('Guests')}
        value={selectedGuestsCount}
        onChange={handleSetGuestsCount}
        className="mb-8"
        input={<IconInput />}
        inputProps={{
          readOnly: true,
        }}
      >
        {maxGuestsOptions?.map((guest) => (
          <Option value={guest.value} key={guest.value}>
            {guest.label}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default WidgetControls;
