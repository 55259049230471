import { useContext } from 'react';
import parse from 'html-react-parser';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const useStyles = createStyles(() => ({
  root: {
    position: 'relative',
    padding: 20,
    alignItems: ({ descriptionLengthOverflow }) => (descriptionLengthOverflow ? 'flex-start' : 'center'),
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
    width: '100%',
    marginBottom: 30,
    '& > div': {
      zIndex: 1,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      background: '#f5f5f5b0',
      right: '-100%',
      left: '-100%',
      top: 0,
      bottom: 0,
    },
  },
  descriptionWrapper: {
    maxWidth: ({ descriptionLengthOverflow }) => (descriptionLengthOverflow ? '100%' : 600),
    textAlign: ({ descriptionLengthOverflow }) => (descriptionLengthOverflow ? 'left' : 'center'),
  },
}));

const About = () => {
  const { contentConfiguration } = useContext(WebsiteSettingsContext);
  const { description, title } = contentConfiguration?.sections?.INFO?.payload || {};

  const descriptionLengthOverflow = description?.length > 200;
  const { root, descriptionWrapper } = useStyles({ descriptionLengthOverflow });

  if (!description && !title) return null;

  return (
    <div className={root}>
      <TextField variant="h2" className="text-align-center mb-1">
        {title}
      </TextField>
      <TextField className={descriptionWrapper}>{parse(description ?? '')}</TextField>
    </div>
  );
};

export default About;
