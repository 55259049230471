import { api } from 'api';

const URL = '/reservations/payouts/list';

const getPaymentSchedule = async (params) => {
  const { data } = await api.get(URL, { params });

  return data;
};

export default getPaymentSchedule;
