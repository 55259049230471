import { api } from 'api';

const URL = '/listings';

const getListings = async ({ pageParam = {}, search }) => {
  const { cursor, exactSearch } = pageParam;
  const { data } = await api.get(URL, { params: { cursor, exactSearch, ...search } });
  return data;
};

export default getListings;
