/* eslint-disable no-param-reassign */
import t from '@guestyci/localize/t.macro';

export const houseRulesWithTranslation = () => ({
  petsAllowed: {
    text: t('Pets allowed'),
    icon: 'petsAllowed',
  },
  notPetsAllowed: {
    text: t('No pets are allowed'),
    icon: 'notPetsAllowed',
  },
  smokingAllowed: {
    text: t('Smoking allowed'),
    icon: 'smokingAllowed',
  },
  notSmokingAllowed: {
    text: t('No smoking'),
    icon: 'notSmokingAllowed',
  },
  suitableForChildren: {
    text: t('Suitable for children (2 to 12 years)'),
    icon: 'suitableForChildren',
  },
  notSuitableForChildren: {
    text: t('Not suitable for children (2 to 12 years)'),
    icon: 'notSuitableForChildren',
  },
  suitableForInfants: {
    text: t('Suitable for infants (under 2 years)'),
    icon: 'suitableForInfants',
  },
  notSuitableForInfants: {
    text: t('Not suitable for infants (under 2 years)'),
    icon: 'notSuitableForInfants',
  },
  suitableForEvents: {
    text: t('Parties or events are allowed'),
    icon: 'suitableForEvents',
  },
  notSuitableForEvents: {
    text: t('No parties or events are allowed'),
    icon: 'notSuitableForEvents',
  },
  quietBetween: {
    text: t('Quiet time'),
    icon: 'quietBetween',
  },
  notQuietBetween: {
    text: t('No quiet time'),
    icon: 'notQuietBetween',
  },
});

const houseRules = [
  {
    icon: 'petsAllowed',
    text: 'Pets allowed',
  },
  {
    icon: 'notPetsAllowed',
    text: 'No pets are allowed',
  },
  {
    icon: 'smokingAllowed',
    text: 'Smoking allowed',
  },
  {
    icon: 'notSmokingAllowed',
    text: 'No smoking',
  },
  {
    icon: 'suitableForChildren',
    text: `Suitable for children
      (2 to 12 years)`,
  },
  {
    icon: 'notSuitableForChildren',
    text: `Not suitable for children
      (2 to 12 years)`,
  },
  {
    icon: 'suitableForInfants',
    text: `Suitable for infants
      (under 2 years)`,
  },
  {
    icon: 'notSuitableForInfants',
    text: `Not suitable for infants
      (under 2 years)`,
  },
  {
    icon: 'suitableForEvents',
    text: 'Parties or events are allowed',
  },
  {
    icon: 'notSuitableForEvents',
    text: 'No parties or events are allowed',
  },
  {
    icon: 'quietBetween',
    text: 'Quiet time',
  },
  {
    icon: 'notQuietBetween',
    text: 'No quiet time',
  },
];

export default houseRules;
