import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro/t.macro';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import successInquiry from 'assets/baners/successInquiry.svg';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
  },
  text: {
    textAlign: 'center',
  },
  content: {
    minHeight: '71vh',
    paddingBottom: 40,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1500,
    background: '#FAFAFA',
    width: '100%',
  },
}));

const InquirySuccessPage = () => {
  const { contentConfiguration } = useContext(WebsiteSettingsContext);
  const { getPathWithLocale } = useGetPathToNavigate();
  const redirectPath = getPathWithLocale('/properties');
  const confirmationPage = contentConfiguration?.sections?.CONFIRMATION_PAGE?.payload;
  const { image, title, description } = confirmationPage || {};

  const { root, text, content } = useStyles();
  return (
    <div className={root}>
      <Col align="center" className={content}>
        <img src={image || successInquiry} alt="successInquiry" />
        <TextField className="mt-6" variant="h2">
          {title || t('Thank you for reaching out!')}
        </TextField>
        <TextField className={text}>
          {description || t('Someone from our team will be in touch soon to help you complete the reservation.')}
        </TextField>
        <Link to={redirectPath} className="btn btn-colored mt-6">
          <TextField variant="h3" color="white">
            {t('Browse more properties')}
          </TextField>
        </Link>
      </Col>
    </div>
  );
};

export default InquirySuccessPage;
