import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

import { useGuestyPay } from '@guestyci/guesty-pay-widget';

import createInstantReservation from 'api/quote/createInstantReservation';
import createInstantGroupReservation from 'api/quote/createInstantGroupReservation';

import { BANK_TRANSFER_PAYMENT_TYPE, CREDIT_CARD_PAYMENT_TYPE } from 'constants/constants';
import { createSingleReservationParams, createGroupReservationParams } from 'utils';

import useTrackCreateReservationSuccess from './useTrackCreateReservationSuccess';
import useTrackCreateReservationError from './useTrackCreateReservationError';
import useGetPathToNavigate from './useGetPathToNavigate';
import useSearchValues from './useSearchValues';
import useIsGroupReservation from './useIsGroupReservation';
import useCalculateHostPayout from './useCalculateHostPayout';

const useCreateInstant = () => {
  return useMutation(createInstantReservation);
};

const useCreateInstantGroup = () => {
  return useMutation(createInstantGroupReservation);
};

const useSubmitInstantAmaryllis = ({ property, reservationTotalAmount }) => {
  const { getPathWithLocale, locale } = useGetPathToNavigate();
  const hostPayout = useCalculateHostPayout();
  const { submit } = useGuestyPay();
  const history = useHistory();
  const { rooms } = useSearchValues();
  const { state } = useLocation();
  const { isGroupReservation, isGroupReservationEnabled } = useIsGroupReservation();
  const { mutateAsync: handleCreateInstantReservation, isLoading: isCreatingInstant } = useCreateInstant();
  const { mutateAsync: handleCreateInstantGroupReservation, isLoading: isCreatingInstantGroupReservation } =
    useCreateInstantGroup();
  const { trackSuccess } = useTrackCreateReservationSuccess({
    property,
    provider: 'amaryllis',
    type: 'instant',
    reservationTotalAmount,
  });
  const { trackError } = useTrackCreateReservationError({ property, provider: 'amaryllis', type: 'instant' });
  const currency = state?.selectedRatePlanData?.ratePlan?.money?.currency || state?.ratePlan?.money?.currency;

  const [isAmaryllisError, setIsAmaryllisError] = useState(false);
  const [reservationError, setReservationError] = useState(false);

  const successPath = getPathWithLocale('/instant-success');

  const isRequestingReservation = isCreatingInstant || isCreatingInstantGroupReservation;

  const submitInstantSingle = async (values) => {
    const { paymentType = CREDIT_CARD_PAYMENT_TYPE, reusePaymentMethod } = values;
    try {
      let selectedRatePlanId;
      let selectedQuoteId;
      if (isGroupReservationEnabled) {
        selectedRatePlanId = state?.ratePlan?._id;
        selectedQuoteId = state?.quoteData?.quote[0]?._id;
      } else {
        selectedRatePlanId = state?.ratePlanId;
        selectedQuoteId = state?.quoteId;
      }
      const params = createSingleReservationParams(values, selectedRatePlanId, locale);
      if (paymentType === BANK_TRANSFER_PAYMENT_TYPE) {
        const response = await handleCreateInstantReservation({
          quoteId: selectedQuoteId,
          params: {
            ...params,
            paymentType,
          },
        });

        trackSuccess({
          response,
          dioAdditionalData: { paymentType },
        });
        history.push({
          pathname: successPath,
          state: {
            paymentType,
          },
        });
        return;
      }

      let tokenizationResult;
      try {
        tokenizationResult = await submit({
          amount: reservationTotalAmount || hostPayout,
          currency,
          guest: params.guest,
          listingId: property._id,
          quoteId: selectedQuoteId,
        });
      } catch (error) {
        setIsAmaryllisError(true);
        trackError({
          error,
          additionalData: { paymentType },
        });
        return;
      }

      if (tokenizationResult?._id) {
        const reservation = await handleCreateInstantReservation({
          quoteId: selectedQuoteId,
          params: {
            ...params,
            reuse: reusePaymentMethod,
            ccToken: tokenizationResult._id,
          },
        });

        trackSuccess({
          response: reservation,
          dioAdditionalData: { paymentType },
        });
        history.push({
          pathname: successPath,
          state: {
            paymentType,
          },
        });
      }
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
        additionalData: { paymentType },
      });
    }
  };
  const submitInstantGroup = async (values) => {
    const { paymentType = CREDIT_CARD_PAYMENT_TYPE, reusePaymentMethod } = values;
    try {
      const ratePlanId = state?.ratePlan?._id;
      const quoteData = state?.quoteData;

      const params = createGroupReservationParams({ values, rooms, quoteData, ratePlanId, locale });

      if (paymentType === BANK_TRANSFER_PAYMENT_TYPE) {
        const response = await handleCreateInstantGroupReservation({
          params: {
            ...params,
            reuse: reusePaymentMethod,
            paymentType,
          },
        });

        trackSuccess({
          response,
          dioAdditionalData: { paymentType },
        });
        history.push({
          pathname: successPath,
          state: {
            paymentType,
          },
        });
        return;
      }

      let tokenizationResult;
      try {
        tokenizationResult = await submit({
          amount: reservationTotalAmount || hostPayout,
          currency,
          apiVersion: 'v2',
        });
      } catch (error) {
        setIsAmaryllisError(true);
        trackError({
          error,
          additionalData: { paymentType },
        });
        return;
      }

      if (tokenizationResult?._id) {
        const reservation = await handleCreateInstantGroupReservation({
          params: {
            ...params,
            reuse: reusePaymentMethod,
            ccToken: tokenizationResult._id,
          },
        });

        trackSuccess({
          response: reservation,
          dioAdditionalData: { paymentType },
        });

        history.push({
          pathname: successPath,
          state: {
            paymentType,
          },
        });
      }
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
        additionalData: { paymentType },
      });
    }
  };

  const submitHandler = isGroupReservation ? submitInstantGroup : submitInstantSingle;
  return {
    isRequestingReservation,
    submitHandler,
    setReservationError,
    reservationError,
    isAmaryllisError,
    setIsAmaryllisError,
  };
};

export default useSubmitInstantAmaryllis;
