import { useMemo } from 'react';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import Icon from '../Icon';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    flexDirection: 'column',
    minWidth: '65px',
    '&.inlineRoot': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    [create('xs')]: {
      display: 'none',
    },
    [create('md')]: {
      display: 'flex',
    },
  },
  inner: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '5px',
  },
  item: {
    position: 'relative',
    display: 'inline-flex',
    marginRight: '5px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  star: {
    position: 'absolute',
    overflow: 'hidden',
    '&.emptyStar': {
      width: 0,
    },
    '&.halfStar': {
      width: '50%',
    },
    '&.wholeStar': {
      width: '100%',
    },
  },
  starBlock: {
    width: 20,
    height: 23,
  },
  reviews: {
    '&.inlineReviews': {
      padding: '5px 0 0 5px',
    },
  },
}));

const RateStar = ({ isHalf = false }) => {
  const { item, star, starBlock } = useStyles();

  return (
    <div className={item}>
      <div
        className={cn(star, {
          wholeStar: !isHalf,
          halfStar: isHalf,
        })}
      >
        <Icon icon="star_filled" size={20} />
      </div>
      <div className={starBlock} />
    </div>
  );
};

const PropertyRate = ({ property, inline = false }) => {
  const { root, inner, reviews } = useStyles();

  const reviewsCount = property.reviews.total;
  const starsCount = property.reviews.avg / 2;

  const roundedStarsCount = useMemo(() => {
    const integerStarsCount = Math.trunc(starsCount);
    const decimalStarsCount = starsCount % 1;

    switch (true) {
      case decimalStarsCount > 0.25 && decimalStarsCount <= 0.75:
        return integerStarsCount + 0.5;
      case decimalStarsCount > 0.75:
        return integerStarsCount + 1;
      default:
        return integerStarsCount;
    }
  }, [starsCount]);

  const wholeStarsCount = Math.trunc(roundedStarsCount);
  const withHalfStar = Boolean(roundedStarsCount % 1);

  const reviewsLabel = t(
    '{ num, plural, =0 {# reviews} =1 {1 review} few {# reviews} many {# reviews} other {# reviews}}',
    {
      num: reviewsCount || 0,
    }
  );

  return (
    <div className={cn(root, { inlineRoot: inline })}>
      <div className={inner}>
        {[...Array(wholeStarsCount)].map(() => (
          <RateStar />
        ))}
        {withHalfStar && <RateStar isHalf />}
      </div>
      <TextField color="secondary" variant="h7" align="right" className={cn(reviews, { inlineReviews: inline })}>
        {reviewsLabel}
      </TextField>
    </div>
  );
};

export default PropertyRate;
