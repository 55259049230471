import { useContext } from 'react';
import createStyles from '@guestyci/foundation/createStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const useStyles = createStyles(({ palette }) => {
  return {
    root: {
      background: palette.background.system,
      height: '100%',
      width: '100%',
      '& .date-picker .DateInput_input': {
        padding: '0px 5px!important',
      },
      '& .radio': {
        '& div[class*="checked"]': {
          borderColor: ({ btnColor }) => btnColor || '#1BBCEF',
        },
        '& .radio-inner-content': {
          backgroundColor: ({ btnColor }) => btnColor || '#1BBCEF',
        },
      },
      '& .range': {
        accentColor: ({ btnColor }) => btnColor || '#1BBCEF',
        '&[type="range"]': {
          borderRadius: 5,
          height: 5,
          outline: 'none',
          appearance: 'none',
          transition: 'background 450ms ease-in',
        },
      },
      '& .btn': {
        padding: '10px 30px',
        height: 46,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        cursor: 'pointer',
        border: 0,
        boxShadow: 'none',
        '&:disabled': {
          background: '#BDBDBD',
          color: '#fff',
        },
        '&-full': {
          width: ['100%', '!important'],
        },
        '&-colored': {
          background: ({ btnColor }) => btnColor || '#1A9AFF',
        },
        '&-outline': {
          background: '#fff!important',
          border: ({ btnColor }) => `1px solid ${btnColor || '#1A9AFF'}`,
          color: ({ btnColor }) => btnColor || '#1A9AFF',
          '&:hover': {
            background: ({ btnColor }) => btnColor || '#1A9AFF',
            color: ({ btnColor }) => btnColor,
          },
        },
        '&-sm': {
          width: 'auto',
          height: 46,
          padding: '10px 20px',
        },
        '&:hover': {
          textDecoration: 'none',
          color: '#fff',
        },
      },
    },
  };
});

const RootStylesWrapper = ({ children }) => {
  const { contentConfiguration: { colorTheme } = {} } = useContext(WebsiteSettingsContext);
  const { root } = useStyles({ btnColor: colorTheme?.colors?.mainButtonsAndLinks });
  return <div className={root}>{children}</div>;
};

export default RootStylesWrapper;
