import { api } from 'api';

const createInstantReservation = async ({ quoteId, params }) => {
  const { data } = await api.post(`/reservations/quotes/${quoteId}/instant`, {
    ...params,
  });
  return data;
};

export default createInstantReservation;
