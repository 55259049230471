/* eslint-disable import/no-dynamic-require */
import mapValues from 'lodash/mapValues';

const AMENITIES = {
  CM_detector: 'amenities/CM_detector',
  air_condition: 'amenities/air_condition',
  breakfast: 'amenities/breakfast',
  buzzer: 'amenities/buzzer',
  checkin: 'amenities/checkin',
  doorman: 'amenities/doorman',
  dryer: 'amenities/dryer',
  elevator: 'amenities/elevator',
  essentials: 'amenities/essentials',
  events: 'amenities/events',
  family_friendly: 'amenities/family_friendly',
  fire_extinguisher: 'amenities/fire_extinguisher',
  fireplace: 'amenities/fireplace',
  first_aid: 'amenities/first_aid',
  gym: 'amenities/gym',
  hair_dryer: 'amenities/hair_dryer',
  hangers: 'amenities/hangers',
  heating: 'amenities/heating',
  hot_tub: 'amenities/hot_tub',
  iron: 'amenities/iron',
  keypad: 'amenities/keypad',
  kitchen: 'amenities/kitchen',
  laptop: 'amenities/laptop',
  lockbox: 'amenities/lockbox',
  locker: 'amenities/locker',
  parking: 'amenities/parking',
  pets_allowed: 'amenities/pets_allowed',
  pets_live: 'amenities/pets_live',
  pool: 'amenities/pool',
  private_entrance: 'amenities/private_entrance',
  private_livingroom: 'amenities/private_livingroom',
  safety_card: 'amenities/safety_card',
  shampoo: 'amenities/shampoo',
  smartlock: 'amenities/smartlock',
  smoke_detector: 'amenities/smoke_detector',
  smoking: 'amenities/smoking',
  tv: 'amenities/tv',
  washer: 'amenities/washer',
  wheelchair: 'amenities/wheelchair',
  wifi: 'amenities/wifi',
  cats: 'amenities/cats',
  dogs: 'amenities/dogs',
  other_pets: 'amenities/other_pets',
  internet: 'amenities/internet',
  accessibility: 'amenities/accessibility',
  baby: 'amenities/baby',
  bath: 'amenities/bath',
  bbq: 'amenities/bbq',
  beach: 'amenities/beach',
  bed: 'amenities/bed',
  chair: 'amenities/chair',
  cleaning: 'amenities/cleaning',
  coffee: 'amenities/coffee',
  console: 'amenities/console',
  dishes: 'amenities/dishes',
  dishwasher: 'amenities/dishwasher',
  ev: 'amenities/ev',
  garden: 'amenities/garden',
  guard: 'amenities/guard',
  hot_water: 'amenities/hot_water',
  long_term: 'amenities/long_term',
  luggage: 'amenities/luggage',
  microwave: 'amenities/microwave',
  oven: 'amenities/oven',
  patio: 'amenities/patio',
  refrigerator: 'amenities/refrigerator',
  shades: 'amenities/shades',
  stove: 'amenities/stove',
  table: 'amenities/table',
};

const HOTEL_FEATURES = {
  listing_guests: 'hotelFeatures/listing_guests',
  listing_bedrooms: 'hotelFeatures/listing_bedrooms',
  listing_beds: 'hotelFeatures/listing_beds',
  listing_bathroom: 'hotelFeatures/listing_bathroom',
};

const COMMON = {
  star: 'star',
  star_filled: 'star_filled',
  close: 'close',
  coupon: 'coupon',
  location: 'Location',
  lock: 'lock',
  arrow: 'arrow',
  arrow_next: 'arrow_next',
  guest: 'guest',
  hotel: 'hotel',
  inquiry_success: 'inquiry_success',
  instant_success: 'instant_success',
  no_results: 'no_results',
  not_found: 'not_found',
};

const HOUSE_RULES = {
  petsAllowed: 'houseRules/petsAllowed',
  quietBetween: 'houseRules/quietBetween',
  smokingAllowed: 'houseRules/smokingAllowed',
  suitableForEvents: 'houseRules/suitableForEvents',
  suitableForChildren: 'houseRules/suitableForChildren',
  suitableForInfants: 'houseRules/suitableForInfants',
  notPetsAllowed: 'houseRules/notPetsAllowed',
  notQuietBetween: 'houseRules/notQuietBetween',
  notSmokingAllowed: 'houseRules/notSmokingAllowed',
  notSuitableForEvents: 'houseRules/notSuitableForEvents',
  notSuitableForChildren: 'houseRules/notSuitableForChildren',
  notSuitableForInfants: 'houseRules/notSuitableForInfants',
};

const SOCIAL_MEDIA = {
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  youtube: 'youtube',
};

const CREDIT_CARDS = {
  visa: 'visa',
  mastercard: 'mastercard',
  jcb: 'jcb',
  diners: 'diners',
  unionpay: 'unionpay',
  discover: 'discover',
  amex: 'amex',
};

export const iconMap = {
  ...COMMON,
  ...HOTEL_FEATURES,
  ...AMENITIES,
  ...CREDIT_CARDS,
  ...SOCIAL_MEDIA,
  ...HOUSE_RULES,
};

const requiredIconMap = mapValues(iconMap, (name) => require(`assets/icons/${name}.svg`));
export default requiredIconMap;
