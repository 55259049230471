import { useContext } from 'react';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

import { BANK_TRANSFER } from 'constants/featureToggleNames';
import { BANK_TRANSFER_PAYMENT_TYPE, CREDIT_CARD_PAYMENT_TYPE } from 'constants/constants';

const usePaymentTypeInitialValue = () => {
  const [, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);

  const { paymentTypes: { bankTransfer: bankTransferPaymentTypeEnabled = false } = {} } =
    useContext(WebsiteSettingsContext);

  if (isBankTransferEnabled && bankTransferPaymentTypeEnabled) {
    return BANK_TRANSFER_PAYMENT_TYPE;
  }

  return CREDIT_CARD_PAYMENT_TYPE;
};

export default usePaymentTypeInitialValue;
