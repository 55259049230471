import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import Button from '@guestyci/foundation/Button';

const useStyles = createStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyItems: 'stretch',
    alignItems: 'stretch',
    width: 105,
    height: 24,
  },
  activeBtn: {
    background: '#F5F8FF',
    padding: '0px 5px',
    color: '#206CFF',
  },
  disabledBtn: {
    background: '#F9FAFB',
    padding: '0px 5px',
    color: '#9CA3AF',
  },
  content: {
    color: '#206CFF',
    fontSize: 14,
    textAlign: 'center',
  },
}));

const UpsellCounter = ({ value, max = 1, min = 0, step = 1, onChange, onAdd, onRemove }) => {
  const { container, activeBtn, content, disabledBtn } = useStyles();

  return (
    <div className={container}>
      <Button
        disabled={value <= min}
        className={{ [activeBtn]: value > min, [disabledBtn]: value <= min }}
        onClick={() => {
          if (onChange) onChange(value - step);
          if (onRemove) onRemove(value - step);
        }}
      >
        {' - '}
      </Button>
      <TextField className={content}>{value}</TextField>
      <Button
        disabled={value >= max}
        className={{ [activeBtn]: value < max, [disabledBtn]: value >= max }}
        onClick={() => {
          if (onChange) onChange(value + step);
          if (onAdd) onAdd(value + step);
        }}
      >
        {' + '}
      </Button>
    </div>
  );
};

export default UpsellCounter;
