import { api } from 'api';

const URL = '/reservations/group/quotes/coupons';

const addCouponGroup = async ({ quoteId, coupon }) => {
  const { data } = await api.post(URL, {
    coupon,
    quoteIds: quoteId,
  });

  return data;
};

export default addCouponGroup;
