import { useCallback } from 'react';
import { useHistory, generatePath, useParams, useLocation } from 'react-router-dom';

import Dialog, { DialogHeader, DialogContent, DialogFooter } from '@guestyci/foundation/Dialog';
import FlatButton from '@guestyci/foundation/FlatButton';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';

import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

const useStyles = createStyles(() => ({
  root: {
    maxWidth: 512,
    minWidth: 300,
  },
}));

const ErrorDialogWrapper = ({ open, handleClose, children }) => {
  const { root } = useStyles();
  return (
    <Dialog className={root} open={open} onClose={handleClose}>
      {children}
    </Dialog>
  );
};

export const SessionExpiredDialog = ({ open, handleClose }) => {
  const { getPathWithLocale } = useGetPathToNavigate();
  const history = useHistory();

  const redirectPath = getPathWithLocale('/properties');
  const title = t('Session timed out');
  const description = t('The session has ended due to inactivity. Please restart your search.');

  const handleClick = useCallback(() => {
    handleClose();
    history.push({
      pathname: redirectPath,
      search: '',
    });
  }, [handleClose, history, redirectPath]);

  return (
    <ErrorDialogWrapper open={open} handleClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{description}</DialogContent>
      <DialogFooter>
        <RaisedButton onClick={handleClick}>{t('Restart search')}</RaisedButton>
      </DialogFooter>
    </ErrorDialogWrapper>
  );
};

export const DatesNotAvailableDialog = ({ open, handleClose }) => {
  const history = useHistory();
  const { getPathWithLocale } = useGetPathToNavigate();
  const { id } = useParams();

  const title = t('Sorry, the property is not available ');
  const description = t(
    'The property is no longer available on these dates. You can change the dates to check availability or search other properties.'
  );

  const handleClick = useCallback(() => {
    handleClose();
    const redirectPath = getPathWithLocale('/properties');
    const pathname = generatePath(`${redirectPath}/:id`, { id });
    history.push({
      pathname,
    });
  }, [getPathWithLocale, handleClose, history, id]);

  const handleCloseClick = useCallback(() => {
    handleClose();
    const redirectPath = getPathWithLocale('/properties');
    history.push({
      pathname: redirectPath,
    });
  }, [getPathWithLocale, handleClose, history]);

  return (
    <ErrorDialogWrapper open={open} handleClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{description}</DialogContent>
      <DialogFooter>
        <FlatButton onClick={handleCloseClick}>{t('Restart search')}</FlatButton>
        <RaisedButton onClick={handleClick}>{t('Change dates')}</RaisedButton>
      </DialogFooter>
    </ErrorDialogWrapper>
  );
};

export const RatePlanNotAvailableDialog = ({ open, handleClose }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { getPathWithLocale } = useGetPathToNavigate();
  const { id } = useParams();

  const title = t('The package is no longer available');
  const description = t('The package you selected is no longer available. Please go back to choose another package.');

  const handleClick = useCallback(() => {
    handleClose();
    const redirectPath = getPathWithLocale('/properties');
    const pathname = generatePath(`${redirectPath}/:id`, { id });
    history.push({
      pathname,
      search,
    });
  }, [getPathWithLocale, handleClose, history, id, search]);
  return (
    <ErrorDialogWrapper open={open} handleClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{description}</DialogContent>
      <DialogFooter>
        <RaisedButton onClick={handleClick}>{t('Choose another package')}</RaisedButton>
      </DialogFooter>
    </ErrorDialogWrapper>
  );
};

const ReservationErrorDialog = ({ error, open, handleClose }) => {
  switch (error) {
    case 'Inquiry is already fulfilled by a reservation':
      return <DatesNotAvailableDialog open={open} handleClose={handleClose} />;
    default:
      return <SessionExpiredDialog open={open} handleClose={handleClose} />;
  }
};

export default ReservationErrorDialog;
