import { createContext, useState } from 'react';
import { useQuery } from 'react-query';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import FullPageLoader from 'components/FullPageLoader';
import { getCookie, setCookie } from 'utils/cookies';
import { COOKIE_POLICY_ACCEPTED } from 'constants/constants';
import useGetWebsiteSettings from 'hooks/useGetWebsiteSettings';
import { NEW_TRANSLATIONS_UI } from 'constants/featureToggleNames';
import useLocale from 'hooks/useLocale';
import getUserLocation from 'api/location/getUserLocation';
import { QUERY_IDS } from 'api';
import { countryCodeToPhonePrefixMap } from 'constants/countryCodeToPhonePrefixMap';

const { GET_USER_LOCATION } = QUERY_IDS;

const WebsiteSettingsContext = createContext();

const WebsiteSettingsContextProvider = ({ children }) => {
  const [isFtLoading, isLanguagesFtEnabled] = useFeatureToggle(NEW_TRANSLATIONS_UI);
  const locale = useLocale();
  const language = isLanguagesFtEnabled ? locale : undefined;
  const { data: userLocation } = useQuery(GET_USER_LOCATION, () => getUserLocation(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60 * 2, // 2 hours
  });

  const cookiePolicy = getCookie(COOKIE_POLICY_ACCEPTED);
  const [cookiesPolicyAccepted, setCookiesPolicyAccepted] = useState(cookiePolicy);
  const { data: websiteSettings, isLoading } = useGetWebsiteSettings({ language, enabled: !isFtLoading });

  const setPolicyCookie = ({ name = COOKIE_POLICY_ACCEPTED, value = '' }) => {
    setCookiesPolicyAccepted(value);
    setCookie(name, value);
  };

  if (isLoading || isFtLoading) {
    return <FullPageLoader />;
  }
  return (
    <WebsiteSettingsContext.Provider
      value={{
        ...websiteSettings,
        cookiesPolicyAccepted,
        setCookiesPolicyAccepted,
        setPolicyCookie,
        userLocation: {
          ...userLocation,
          countryPhoneCode: countryCodeToPhonePrefixMap.get(userLocation?.country_code) || '+1',
        },
      }}
    >
      {children}
    </WebsiteSettingsContext.Provider>
  );
};

export { WebsiteSettingsContext };
export default WebsiteSettingsContextProvider;
