import { useQuery } from 'react-query';

import { QUERY_IDS } from 'api';
import getUpsells from 'api/upsell/getUpsells';

const { GET_UPSELLS } = QUERY_IDS;

const useGetUpsells = ({ listingId, quoteId }, options) => {
  const { data, isLoading, isError } = useQuery(
    [GET_UPSELLS, listingId, quoteId],
    () => getUpsells({ listingId, quoteId }),
    {
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    }
  );
  return { upsells: data, isUpsellLoading: isLoading, isUpsellError: isError };
};

export default useGetUpsells;
