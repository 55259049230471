import { createContext, useContext } from 'react';

const BaseUrlContext = createContext({});

const BaseUrlProvider = ({ baseUrl, children }) => (
  <BaseUrlContext.Provider value={baseUrl.replace(/\/$/, '')}>
    {children}
  </BaseUrlContext.Provider>
);
const useBaseUrl = () => useContext(BaseUrlContext);
export { BaseUrlProvider, useBaseUrl };
