import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg';

const socialIcons = {
  FACEBOOK: FacebookIcon,
  TWITTER: TwitterIcon,
  INSTAGRAM: InstagramIcon,
  YOUTUBE: YoutubeIcon,
};

export default socialIcons;
