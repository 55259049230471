import { useContext } from 'react';
import parse from 'html-react-parser';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

const useStyles = createStyles(() => ({
  root: {
    background: '#fff',
    padding: 20,
    minHeight: '100vh',
    whiteSpace: 'pre-wrap',
  },
}));

const Terms = () => {
  const { root } = useStyles();

  const { contentConfiguration: { sections } = {} } = useContext(WebsiteSettingsContext) || {};

  const { title = null, description = null } = sections?.TERMS?.payload || {};

  if (!description || !title) return null;

  return (
    <div className={root}>
      <TextField variant="h1">{title}</TextField>
      <TextField>{parse(description ?? '')}</TextField>
    </div>
  );
};

export default Terms;
