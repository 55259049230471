import { datadogRum } from '@datadog/browser-rum';

const initDatadog = () => {
  if (window.location.host.includes('localhost') || window.location.host.includes('staging')) return;
  datadogRum.init({
    applicationId: '1ea5db21-e166-46e7-a256-4f5dd847d12d',
    clientToken: 'pub92f4f63f82d5dc09d24a9e951f0da051',
    site: 'datadoghq.com',
    service: 'booking-engine',
    env: 'prod',
    sessionSampleRate: 15,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

export default initDatadog;
