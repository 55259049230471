import { useQuery } from 'react-query';

import getListingsMarkers from 'api/listings/getListingsMarkers';

import { transformListingsMarkersResponse } from 'utils';
import { QUERY_IDS } from 'api';
import useGetPathToNavigate from './useGetPathToNavigate';

const { GET_LISTING_MARKERS } = QUERY_IDS;

const useGetListingsMarkers = () => {
  const { locale, isTranslationsEnabled } = useGetPathToNavigate();
  const queryKey = isTranslationsEnabled ? [GET_LISTING_MARKERS, locale] : [GET_LISTING_MARKERS];
  const useLangParams = locale === 'en' ? {} : { lang: locale };
  const { data, isFetching, isLoading, isSuccess, isError, refetch } = useQuery(
    queryKey,
    () => getListingsMarkers({ params: { ...useLangParams } }),
    {
      refetchOnWindowFocus: false,
      refetch: false,
      select: (response) => transformListingsMarkersResponse({ response }),
    }
  );

  return {
    data,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};

export default useGetListingsMarkers;
