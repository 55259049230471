import { useQuery } from 'react-query';

import { QUERY_IDS } from 'api';
import getQuote from 'api/quote/getQuote';

const { GET_QUOTE } = QUERY_IDS;

const useGetQuote = (quoteId, options) =>
  useQuery([GET_QUOTE, quoteId], () => getQuote({ quoteId }), {
    enabled: !!quoteId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options,
  });

export default useGetQuote;
