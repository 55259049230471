import { isEmpty } from 'lodash';
import { Col } from '@guestyci/foundation/Layout';

import Promotion from 'components/Promotion';

const Promotions = ({ promotions }) => {
  if (!promotions?.length) return null;
  return <Col>{promotions?.map((p) => !isEmpty(p) && <Promotion className="mt-2" key={p?.id} promotion={p} />)}</Col>;
};

export default Promotions;
