import { useCallback } from 'react';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: true,
};

const useFacebookPixel = () => {
  const initAnalytics = useCallback((facebookPixelCode) => {
    // https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const advancedMatching = {};

    ReactPixel.init(facebookPixelCode, advancedMatching, options);
  }, []);

  const fbtrack = useCallback((title, payload) => {
    return ReactPixel.trackCustom(title, payload);
  }, []);

  const fbTrackPageView = useCallback((url) => {
    return ReactPixel.pageView(url);
  }, []);

  return {
    initAnalytics,
    fbtrack,
    fbTrackPageView,
  };
};

export default useFacebookPixel;
