import t from '@guestyci/localize/t.macro';

export const BANK_TRANSFER_PAYMENT_TYPE = 'bankTransfer';
export const CREDIT_CARD_PAYMENT_TYPE = 'card';

export const DEFAULT_RATE_PLAN_ID = 'default-rateplan-id';

export const COOKIE_POLICY_ACCEPTED = 'cookiePolicyAccepted';
export const COOKIE_POLICY_TERMS = () =>
  t(
    "This website uses cookies. \r\n We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services."
  );
export const ACCEPT_COOKIE_POLICY_BTN = 'Accept';

export const DISCOUNT_TYPE = {
  PERCENT: 'PERCENT',
  PERCENTAGE: 'PERCENTAGE',
  FIXED: 'FIXED',
};
export const INVOICE_ITEM_TYPE = {
  DISCOUNT: 'DISCOUNT',
};

export const getPropertyRoomTypes = () => ({
  BEDROOM: t('Bedroom'),
  SHARED_SPACE: t('Shared space'),
});

export const getBedTypes = () => ({
  KING_BED: (qty) => t('{qty, plural, one {King bed} other {King beds}}', { qty }),
  QUEEN_BED: (qty) => t('{qty, plural, one {Queen bed} other {Queen beds}}', { qty }),
  DOUBLE_BED: (qty) => t('{qty, plural, one {Double bed} other {Double beds}}', { qty }),
  SINGLE_BED: (qty) => t('{qty, plural, one {Single bed} other {Single beds}}', { qty }),
  SOFA_BED: (qty) => t('{qty, plural, one {Sofa bed} other {Sofa beds}}', { qty }),
  AIR_MATTRESS: (qty) => t('{qty, plural, one {Air mattress} other {Air mattresss}}', { qty }),
  BUNK_BED: (qty) => t('{qty, plural, one {Bunk bed} other {Bunk beds}}', { qty }),
  FLOOR_MATTRESS: (qty) => t('{qty, plural, one {Floor mattress} other {Floor mattresses}}', { qty }),
  WATER_BED: (qty) => t('{qty, plural, one {Water bed} other {Water beds}}', { qty }),
  TODDLER_BED: (qty) => t('{qty, plural, one {Toddler bed} other {Toddler beds}}', { qty }),
  CRIB: (qty) => t('{qty, plural, one {Crib} other {Cribs}}', { qty }),
  FULL_BATHROOM: (qty) => t('{qty, plural, one {Full bathroom} other {Full bathrooms}}', { qty }),
  HALF_BATHROOM: (qty) => t('{qty, plural, one {Half bathroom} other {Half bathrooms}}', { qty }),
});

export const getFeeTypes = () => ({
  EXTRA_BED: {
    label: t('Extra bed fee'),
  },
  CLEANING: {
    label: t('Cleanin fee'),
  },
  CANCELLATION: {
    label: t('Cancellation fee'),
  },
  ACCOMMODATION: {
    label: t('Accommodation fare fee'),
  },
  COUPON: {
    label: t('Coupon fee'),
  },
  HOST_SERVICE_FEE: {
    label: t('Host service fee'),
  },
});

export const getTaxTypes = () => ({
  LOCAL_TAX: {
    label: t('Local tax'),
  },
  CITY_TAX: {
    label: t('City tax'),
  },
  VAT: {
    label: t('VAT'),
  },
  GOODS_AND_SERVICES_TAX: {
    label: t('Goods and services tax'),
  },
  TOURISM_TAX: {
    label: t('Tourism tax'),
  },
  OTHER: {
    label: t('Other'),
  },
  STATE_TAX: {
    label: t('State tax'),
  },
  COUNTY_TAX: {
    label: t('Country tax'),
  },
  OCCUPANCY_TAX: {
    label: t('Occupancy tax'),
  },
  TRANSIENT_OCCUPANCY_TAX: {
    label: t('Transient occupancy tax'),
  },
  HOME_SHARING_TAX: {
    label: t('Home sharing tax'),
  },
  HARMONIZED_SALES_TAX: {
    label: t('Harmonized sales tax'),
  },
  MINIMUM_ALTERNATE_TAX: {
    label: t('Minimum alternate tax'),
  },
});

export const getAmenitiesTypes = () => ({
  SWIMMING_POOL: {
    label: t('Swimming pool'),
    value: 'Swimming pool',
  },
  ACCESSIBLE_HEIGHT_BED: {
    label: t('Accessible-height bed'),
    value: 'Accessible-height bed',
  },
  ACCESSIBLE_HEIGHT_TOILET: {
    label: t('Accessible-height toilet'),
    value: 'Accessible-height toilet',
  },
  AIR_CONDITIONING: {
    label: t('Air conditioning'),
    value: 'Air conditioning',
  },
  BABYSITTER_RECOMMENDATIONS: {
    label: t('Babysitter recommendations'),
    value: 'Babysitter recommendations',
  },
  BABY_BATH: {
    label: t('Baby bath'),
    value: 'Baby bath',
  },
  BABY_MONITOR: {
    label: t('Baby monitor'),
    value: 'Baby monitor',
  },
  BATHTUB: {
    label: t('Bathtub'),
    value: 'Bathtub',
  },
  BBQ_GRILL: {
    label: t('BBQ grill'),
    value: 'BBQ grill',
  },
  BEACH_ESSENTIALS: {
    label: t('Beach essentials'),
    value: 'Beach essentials',
  },
  BED_LINENS: {
    label: t('Bed linens'),
    value: 'Bed linens',
  },
  BREAKFAST: {
    label: t('Breakfast'),
    value: 'Breakfast',
  },
  CABLE_TV: {
    label: t('Cable TV'),
    value: 'Cable TV',
  },
  CARBON_MONOXIDE_DETECTOR: {
    label: t('Carbon monoxide detector'),
    value: 'Carbon monoxide detector',
  },
  CATS: {
    label: t('Cat(s)'),
    value: 'Cat(s)',
  },
  CHANGING_TABLE: {
    label: t('Changing table'),
    value: 'Changing table',
  },
  CHILDREN_BOOKS_AND_TOYS: {
    label: t('Children’s books and toys'),
    value: 'Children’s books and toys',
  },
  CHILDREN_DINNERWARE: {
    label: t('Children’s dinnerware'),
    value: 'Children’s dinnerware',
  },
  CLEANING_BEFORE_CHECKOUT: {
    label: t('Cleaning before checkout'),
    value: 'Cleaning before checkout',
  },
  COFFEE_MAKER: {
    label: t('Coffee maker'),
    value: 'Coffee maker',
  },
  COOKING_BASICS: {
    label: t('Cooking basics'),
    value: 'Cooking basics',
  },
  CRIB: {
    label: t('Crib'),
    value: 'Crib',
  },
  DISABLED_PARKING_SPOT: {
    label: t('Disabled parking spot'),
    value: 'Disabled parking spot',
  },
  DISHES_AND_SILVERWARE: {
    label: t('Dishes and silverware'),
    value: 'Dishes and silverware',
  },
  DISHWASHER: {
    label: t('Dishwasher'),
    value: 'Dishwasher',
  },
  DOGS: {
    label: t('Dog(s)'),
    value: 'Dog(s)',
  },
  DOORMAN: {
    label: t('Doorman'),
    value: 'Doorman',
  },
  DRYER: {
    label: t('Dryer'),
    value: 'Dryer',
  },
  ELEVATOR_IN_BUILDING: {
    label: t('Elevator in building'),
    value: 'Elevator in building',
  },
  ESSENTIALS: {
    label: t('Essentials'),
    value: 'Essentials',
  },
  ETHERNET_CONNECTION: {
    label: t('Ethernet connection'),
    value: 'Ethernet connection',
  },
  EV_CHARGER: {
    label: t('EV charger'),
    value: 'EV charger',
  },
  EXTRA_PILLOWS_AND_BLANKETS: {
    label: t('Extra pillows and blankets'),
    value: 'Extra pillows and blankets',
  },
  FIREPLACE_GUARDS: {
    label: t('Fireplace guards'),
    value: 'Fireplace guards',
  },
  FIRE_EXTINGUISHER: {
    label: t('Fire extinguisher'),
    value: 'Fire extinguisher',
  },
  FIRM_MATTRESS: {
    label: t('Firm mattress'),
    value: 'Firm mattress',
  },
  FIRST_AID_KIT: {
    label: t('First aid kit'),
    value: 'First aid kit',
  },
  FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR: {
    label: t('Flat smooth pathway to front door'),
    value: 'Flat smooth pathway to front door',
  },
  FREE_PARKING_ON_PREMISES: {
    label: t('Free parking on premises'),
    value: 'Free parking on premises',
  },
  GAME_CONSOLE: {
    label: t('Game console'),
    value: 'Game console',
  },
  GARDEN_OR_BACKYARD: {
    label: t('Garden or backyard'),
    value: 'Garden or backyard',
  },
  GRAB_RAILS_FOR_SHOWER_AND_TOILET: {
    label: t('Grab-rails for shower and toilet'),
    value: 'Grab-rails for shower and toilet',
  },
  GYM: {
    label: t('Gym'),
    value: 'Gym',
  },
  HAIR_DRYER: {
    label: t('Hair dryer'),
    value: 'Hair dryer',
  },
  HANGERS: {
    label: t('Hangers'),
    value: 'Hangers',
  },
  HEATING: {
    label: t('Heating'),
    value: 'Heating',
  },
  HIGH_CHAIR: {
    label: t('High chair'),
    value: 'High chair',
  },
  HOT_TUB: {
    label: t('Hot tub'),
    value: 'Hot tub',
  },
  HOT_WATER: {
    label: t('Hot water'),
    value: 'Hot water',
  },
  INDOOR_FIREPLACE: {
    label: t('Indoor fireplace'),
    value: 'Indoor fireplace',
  },
  INTERNET: {
    label: t('Internet'),
    value: 'Internet',
  },
  IRON: {
    label: t('Iron'),
    value: 'Iron',
  },
  KITCHEN: {
    label: t('Kitchen'),
    value: 'Kitchen',
  },
  LAPTOP_FRIENDLY_WORKSPACE: {
    label: t('Laptop friendly workspace'),
    value: 'Laptop friendly workspace',
  },
  LONG_TERM_STAYS_ALLOWED: {
    label: t('Long term stays allowed'),
    value: 'Long term stays allowed',
  },
  LUGGAGE_DROPOFF_ALLOWED: {
    label: t('Luggage dropoff allowed'),
    value: 'Luggage dropoff allowed',
  },
  MICROWAVE: {
    label: t('Microwave'),
    value: 'Microwave',
  },
  OTHER_PET: {
    label: t('Other pet(s)'),
    value: 'Other pet(s)',
  },
  OUTLET_COVERS: {
    label: t('Outlet covers'),
    value: 'Outlet covers',
  },
  OVEN: {
    label: t('Oven'),
    value: 'Oven',
  },
  PACK_N_PLAY_TRAVEL_CRIB: {
    label: t('Pack ’n Play/travel crib'),
    value: 'Pack ’n Play/travel crib',
  },
  PATH_TO_ENTRANCE_LIT_AT_NIGHT: {
    label: t('Path to entrance lit at night'),
    value: 'Path to entrance lit at night',
  },
  PATIO_OR_BALCONY: {
    label: t('Patio or balcony'),
    value: 'Patio or balcony',
  },
  PETS_ALLOWED: {
    label: t('Pets allowed'),
    value: 'Pets allowed',
  },
  PETS_LIVE_ON_THIS_PROPERTY: {
    label: t('Pets live on this property'),
    value: 'Pets live on this property',
  },
  POCKET_WIFI: {
    label: t('Pocket wifi'),
    value: 'Pocket wifi',
  },
  POOL: {
    label: t('Pool'),
    value: 'Pool',
  },
  PRIVATE_ENTRANCE: {
    label: t('Private entrance'),
    value: 'Private entrance',
  },
  REFRIGERATOR: {
    label: t('Refrigerator'),
    value: 'Refrigerator',
  },
  ROLL_IN_SHOWER_WITH_SHOWER_BENCH_OR_CHAIR: {
    label: t('Roll-in shower with shower bench or chair'),
    value: 'Roll-in shower with shower bench or chair',
  },
  ROOM_DARKENING_SHADES: {
    label: t('Room-darkening shades'),
    value: 'Room-darkening shades',
  },
  SHAMPOO: {
    label: t('Shampoo'),
    value: 'Shampoo',
  },
  SINGLE_LEVEL_HOME: {
    label: t('Single level home'),
    value: 'Single level home',
  },
  SMOKE_DETECTOR: {
    label: t('Smoke detector'),
    value: 'Smoke detector',
  },
  SMOKING_ALLOWED: {
    label: t('Smoking allowed'),
    value: 'Smoking allowed',
  },
  STAIR_GATES: {
    label: t('Stair gates'),
    value: 'Stair gates',
  },
  STEP_FREE_ACCESS: {
    label: t('Step-free access'),
    value: 'Step-free access',
  },
  STOVE: {
    label: t('Stove'),
    value: 'Stove',
  },
  SUITABLE_FOR_CHILDREN: {
    label: t('Suitable for children (2-12 years)'),
    value: 'Suitable for children (2-12 years)',
  },
  SUITABLE_FOR_INFANTS: {
    label: t('Suitable for infants (under 2 years)'),
    value: 'Suitable for infants (under 2 years)',
  },
  TABLE_CORNER_GUARDS: {
    label: t('Table corner guards'),
    value: 'Table corner guards',
  },
  TUB_WITH_SHOWER_BENCH: {
    label: t('Tub with shower bench'),
    value: 'Tub with shower bench',
  },
  WASHER: {
    label: t('Washer'),
    value: 'Washer',
  },
  WIDE_CLEARANCE_TO_BED: {
    label: t('Wide clearance to bed'),
    value: 'Wide clearance to bed',
  },
  WIDE_CLEARANCE_TO_SHOWER_AND_TOILET: {
    label: t('Wide clearance to shower and toilet'),
    value: 'Wide clearance to shower and toilet',
  },
  WIDE_DOORWAY: {
    label: t('Wide doorway'),
    value: 'Wide doorway',
  },
  WIDE_HALLWAY_CLEARANCE: {
    label: t('Wide hallway clearance'),
    value: 'Wide hallway clearance',
  },
  WINDOW_GUARDS: {
    label: t('Window guards'),
    value: 'Window guards',
  },
  WIRELESS_INTERNET: {
    label: t('Wireless Internet'),
    value: 'Wireless Internet',
  },
});

export const getNewAmenities = () => ({
  'Accessible-height bed': {
    label: t('Accessible-height bed'),
    value: 'Accessible-height bed',
    icon: 'wheelchair',
    lowerCase: 'accessible-height bed',
  },
  'Accessible-height toilet': {
    label: t('Accessible-height toilet'),
    value: 'Accessible-height toilet',
    icon: 'wheelchair',
    lowerCase: 'accessible-height toilet',
  },
  'Disabled parking spot': {
    label: t('Disabled parking spot'),
    value: 'Disabled parking spot',
    icon: 'parking',
    lowerCase: 'disabled parking spot',
  },
  'Grab-rails for shower and toilet': {
    label: t('Grab-rails for shower and toilet'),
    value: 'Grab-rails for shower and toilet',
    icon: 'accessibility',
    lowerCase: 'grab-rails for shower and toilet',
  },
  'Grab-rails in toilet': {
    label: t('Grab-rails in toilet'),
    value: 'Grab-rails in toilet',
    icon: 'accessibility',
    lowerCase: 'grab-rails in toilet',
  },
  'Path to entrance lit at night': {
    label: t('Path to entrance lit at night'),
    value: 'Path to entrance lit at night',
    icon: 'accessibility',
    lowerCase: 'path to entrance lit at night',
  },
  'Roll-in shower with shower bench or chair': {
    label: t('Roll-in shower with shower bench or chair'),
    value: 'Roll-in shower with shower bench or chair',
    icon: 'accessibility',
    lowerCase: 'roll-in shower with shower bench or chair',
  },
  'Shower bench': {
    label: t('Shower bench'),
    value: 'Shower bench',
    icon: 'accessibility',
    lowerCase: 'shower bench',
  },
  'Shower chair': {
    label: t('Shower chair'),
    value: 'Shower chair',
    icon: 'accessibility',
    lowerCase: 'shower chair',
  },
  'Single level home': {
    label: t('Single level home'),
    value: 'Single level home',
    icon: 'accessibility',
    lowerCase: 'single level home',
  },
  'Step-free access': {
    label: t('Step-free access'),
    value: 'Step-free access',
    icon: 'accessibility',
    lowerCase: 'step-free access',
  },
  'Tub with shower bench': {
    label: t('Tub with shower bench'),
    value: 'Tub with shower bench',
    icon: 'bath',
    lowerCase: 'tub with shower bench',
  },
  'Wheelchair accessible': {
    label: t('Wheelchair accessible'),
    value: 'Wheelchair accessible',
    icon: 'wheelchair',
    lowerCase: 'wheelchair accessible',
  },
  'Wide clearance to bed': {
    label: t('Wide clearance to bed'),
    value: 'Wide clearance to bed',
    icon: 'bed',
    lowerCase: 'wide clearance to bed',
  },
  'Wide clearance to shower and toilet': {
    label: t('Wide clearance to shower and toilet'),
    value: 'Wide clearance to shower and toilet',
    icon: 'accessibility',
    lowerCase: 'wide clearance to shower and toilet',
  },
  'Wide doorway': {
    label: t('Wide doorway'),
    value: 'Wide doorway',
    icon: 'accessibility',
    lowerCase: 'wide doorway',
  },
  'Wide hallway clearance': {
    label: t('Wide hallway clearance'),
    value: 'Wide hallway clearance',
    icon: 'accessibility',
    lowerCase: 'wide hallway clearance',
  },
  'Body soap': {
    label: t('Body soap'),
    value: 'Body soap',
    icon: 'bath',
    lowerCase: 'body soap',
  },
  'Cleaning products': {
    label: t('Cleaning products'),
    value: 'Cleaning products',
    icon: 'bath',
    lowerCase: 'cleaning products',
  },
  Conditioner: {
    label: t('Conditioner'),
    value: 'Conditioner',
    icon: 'bath',
    lowerCase: 'conditioner',
  },
  'Hot water': {
    label: t('Hot water'),
    value: 'Hot water',
    icon: 'bath',
    lowerCase: 'hot water',
  },
  Shampoo: {
    label: t('Shampoo'),
    value: 'Shampoo',
    icon: 'shampoo',
    lowerCase: 'shampoo',
  },
  'Shower gel': {
    label: t('Shower gel'),
    value: 'Shower gel',
    icon: 'bath',
    lowerCase: 'shower gel',
  },
  'Towels provided': {
    label: t('Towels provided'),
    value: 'Towels provided',
    icon: 'bath',
    lowerCase: 'towels provided',
  },
  'Clothing storage': {
    label: t('Clothing storage'),
    value: 'Clothing storage',
    icon: 'hangers',
    lowerCase: 'clothing storage',
  },
  'Coin Laundry': {
    label: t('Coin Laundry'),
    value: 'Coin Laundry',
    icon: 'washer',
    lowerCase: 'coin laundry',
  },
  'Dryer in common space': {
    label: t('Dryer in common space'),
    value: 'Dryer in common space',
    icon: 'dryer',
    lowerCase: 'dryer in common space',
  },
  'Mosquito net': {
    label: t('Mosquito net'),
    value: 'Mosquito net',
    icon: 'bed',
    lowerCase: 'mosquito net',
  },
  'Washer in common space': {
    label: t('Washer in common space'),
    value: 'Washer in common space',
    icon: 'washer',
    lowerCase: 'washer in common space',
  },
  'DVD player': {
    label: t('DVD player'),
    value: 'DVD player',
    icon: 'tv',
    lowerCase: 'dvd player',
  },
  'Foosball table': {
    label: t('Foosball table'),
    value: 'Foosball table',
    icon: 'family_friendly',
    lowerCase: 'foosball table',
  },
  'Game room': {
    label: t('Game room'),
    value: 'Game room',
    icon: 'family_friendly',
    lowerCase: 'game room',
  },
  Piano: {
    label: t('Piano'),
    value: 'Piano',
    icon: 'family_friendly',
    lowerCase: 'piano',
  },
  'Ping pong table': {
    label: t('Ping pong table'),
    value: 'Ping pong table',
    icon: 'family_friendly',
    lowerCase: 'ping pong table',
  },
  'Pool table': {
    label: t('Pool table'),
    value: 'Pool table',
    icon: 'family_friendly',
    lowerCase: 'pool table',
  },
  'Sound system': {
    label: t('Sound system'),
    value: 'Sound system',
    icon: 'family_friendly',
    lowerCase: 'sound system',
  },
  'Baby bath': {
    label: t('Baby bath'),
    value: 'Baby bath',
    icon: 'baby',
    lowerCase: 'baby bath',
  },
  'Baby monitor': {
    label: t('Baby monitor'),
    value: 'Baby monitor',
    icon: 'baby',
    lowerCase: 'baby monitor',
  },
  'Babysitter recommendations': {
    label: t('Babysitter recommendations'),
    value: 'Babysitter recommendations',
    icon: 'baby',
    lowerCase: 'babysitter recommendations',
  },
  Bathtub: {
    label: t('Bathtub'),
    value: 'Bathtub',
    icon: 'bath',
    lowerCase: 'bathtub',
  },
  'Board games': {
    label: t('Board games'),
    value: 'Board games',
    icon: 'family_friendly',
    lowerCase: 'board games',
  },
  'Changing table': {
    label: t('Changing table'),
    value: 'Changing table',
    icon: 'table',
    lowerCase: 'changing table',
  },
  'Children’s books and toys': {
    label: t('Children’s books and toys'),
    value: 'Children’s books and toys',
    icon: 'family_friendly',
    lowerCase: 'children’s books and toys',
  },
  'Children’s dinnerware': {
    label: t('Children’s dinnerware'),
    value: 'Children’s dinnerware',
    icon: 'family_friendly',
    lowerCase: 'children’s dinnerware',
  },
  Crib: {
    label: t('Crib'),
    value: 'Crib',
    icon: 'baby',
    lowerCase: 'crib',
  },
  'Family/kid friendly': {
    label: t('Family/kid friendly'),
    value: 'Family/kid friendly',
    icon: 'family_friendly',
    lowerCase: 'family/kid friendly',
  },
  'Fireplace guards': {
    label: t('Fireplace guards'),
    value: 'Fireplace guards',
    icon: 'guard',
    lowerCase: 'fireplace guards',
  },
  'Game console': {
    label: t('Game console'),
    value: 'Game console',
    icon: 'console',
    lowerCase: 'game console',
  },
  'High chair': {
    label: t('High chair'),
    value: 'High chair',
    icon: 'chair',
    lowerCase: 'high chair',
  },
  'Outlet covers': {
    label: t('Outlet covers'),
    value: 'Outlet covers',
    icon: 'guard',
    lowerCase: 'outlet covers',
  },
  'Pack ’n Play/travel crib': {
    label: t('Pack ’n Play/travel crib'),
    value: 'Pack ’n Play/travel crib',
    icon: 'baby',
    lowerCase: 'pack ’n play/travel crib',
  },
  'Room-darkening shades': {
    label: t('Room-darkening shades'),
    value: 'Room-darkening shades',
    icon: 'family_friendly',
    lowerCase: 'room-darkening shades',
  },
  'Stair gates': {
    label: t('Stair gates'),
    value: 'Stair gates',
    icon: 'guard',
    lowerCase: 'stair gates',
  },
  'Table corner guards': {
    label: t('Table corner guards'),
    value: 'Table corner guards',
    icon: 'guard',
    lowerCase: 'table corner guards',
  },
  'Window guards': {
    label: t('Window guards'),
    value: 'Window guards',
    icon: 'guard',
    lowerCase: 'window guards',
  },
  'Portable fans': {
    label: t('Portable fans'),
    value: 'Portable fans',
    icon: 'air_condition',
    lowerCase: 'portable fans',
  },
  'Carbon monoxide detector': {
    label: t('Carbon monoxide detector'),
    value: 'Carbon monoxide detector',
    icon: 'CM_detector',
    lowerCase: 'carbon monoxide detector',
  },
  'Emergency exit': {
    label: t('Emergency exit'),
    value: 'Emergency exit',
    icon: 'guard',
    lowerCase: 'emergency exit',
  },
  'Fire extinguisher': {
    label: t('Fire extinguisher'),
    value: 'Fire extinguisher',
    icon: 'fire_extinguisher',
    lowerCase: 'fire extinguisher',
  },
  'First aid kit': {
    label: t('First aid kit'),
    value: 'First aid kit',
    icon: 'first_aid',
    lowerCase: 'first aid kit',
  },
  'Smoke detector': {
    label: t('Smoke detector'),
    value: 'Smoke detector',
    icon: 'smoke_detector',
    lowerCase: 'smoke detector',
  },
  'Baking sheet': {
    label: t('Baking sheet'),
    value: 'Baking sheet',
    icon: 'kitchen',
    lowerCase: 'baking sheet',
  },
  'Barbeque utensils': {
    label: t('Barbeque utensils'),
    value: 'Barbeque utensils',
    icon: 'bbq',
    lowerCase: 'barbeque utensils',
  },
  Blender: {
    label: t('Blender'),
    value: 'Blender',
    icon: 'kitchen',
    lowerCase: 'blender',
  },
  Breakfast: {
    label: t('Breakfast'),
    value: 'Breakfast',
    icon: 'breakfast',
    lowerCase: 'breakfast',
  },
  Coffee: {
    label: t('Coffee'),
    value: 'Coffee',
    icon: 'coffee',
    lowerCase: 'coffee',
  },
  'Coffee maker': {
    label: t('Coffee maker'),
    value: 'Coffee maker',
    icon: 'coffee',
    lowerCase: 'coffee maker',
  },
  Cookware: {
    label: t('Cookware'),
    value: 'Cookware',
    icon: 'kitchen',
    lowerCase: 'cookware',
  },
  'Dining table': {
    label: t('Dining table'),
    value: 'Dining table',
    icon: 'table',
    lowerCase: 'dining table',
  },
  'Dishes and silverware': {
    label: t('Dishes and silverware'),
    value: 'Dishes and silverware',
    icon: 'dishes',
    lowerCase: 'dishes and silverware',
  },
  Dishwasher: {
    label: t('Dishwasher'),
    value: 'Dishwasher',
    icon: 'dishwasher',
    lowerCase: 'dishwasher',
  },
  Freezer: {
    label: t('Freezer'),
    value: 'Freezer',
    icon: 'refrigerator',
    lowerCase: 'freezer',
  },
  'Ice maker': {
    label: t('Ice maker'),
    value: 'Ice maker',
    icon: 'refrigerator',
    lowerCase: 'ice maker',
  },
  Kettle: {
    label: t('Kettle'),
    value: 'Kettle',
    icon: 'kitchen',
    lowerCase: 'kettle',
  },
  Microwave: {
    label: t('Microwave'),
    value: 'Microwave',
    icon: 'microwave',
    lowerCase: 'microwave',
  },
  'Mini fridge': {
    label: t('Mini fridge'),
    value: 'Mini fridge',
    icon: 'refrigerator',
    lowerCase: 'mini fridge',
  },
  Oven: {
    label: t('Oven'),
    value: 'Oven',
    icon: 'oven',
    lowerCase: 'oven',
  },
  Refrigerator: {
    label: t('Refrigerator'),
    value: 'Refrigerator',
    icon: 'refrigerator',
    lowerCase: 'refrigerator',
  },
  'Rice maker': {
    label: t('Rice maker'),
    value: 'Rice maker',
    icon: 'kitchen',
    lowerCase: 'rice maker',
  },
  Stove: {
    label: t('Stove'),
    value: 'Stove',
    icon: 'stove',
    lowerCase: 'stove',
  },
  Toaster: {
    label: t('Toaster'),
    value: 'Toaster',
    icon: 'kitchen',
    lowerCase: 'toaster',
  },
  'Trash compactor': {
    label: t('Trash compactor'),
    value: 'Trash compactor',
    icon: 'kitchen',
    lowerCase: 'trash compactor',
  },
  'Wine glasses': {
    label: t('Wine glasses'),
    value: 'Wine glasses',
    icon: 'dishes',
    lowerCase: 'wine glasses',
  },
  Beach: {
    label: t('Beach'),
    value: 'Beach',
    icon: 'beach',
    lowerCase: 'beach',
  },
  'Beach Front': {
    label: t('Beach Front'),
    value: 'Beach Front',
    icon: 'beach',
    lowerCase: 'beach front',
  },
  'Beach View': {
    label: t('Beach View'),
    value: 'Beach View',
    icon: 'beach',
    lowerCase: 'beach view',
  },
  'Beach access': {
    label: t('Beach access'),
    value: 'Beach access',
    icon: 'beach',
    lowerCase: 'beach access',
  },
  'City View': {
    label: t('City View'),
    value: 'City View',
    icon: 'garden',
    lowerCase: 'city view',
  },
  'Desert View': {
    label: t('Desert View'),
    value: 'Desert View',
    icon: 'garden',
    lowerCase: 'desert view',
  },
  Downtown: {
    label: t('Downtown'),
    value: 'Downtown',
    icon: 'garden',
    lowerCase: 'downtown',
  },
  'Garden View': {
    label: t('Garden View'),
    value: 'Garden View',
    icon: 'garden',
    lowerCase: 'garden view',
  },
  'Golf course front': {
    label: t('Golf course front'),
    value: 'Golf course front',
    icon: 'garden',
    lowerCase: 'golf course front',
  },
  'Golf view': {
    label: t('Golf view'),
    value: 'Golf view',
    icon: 'garden',
    lowerCase: 'golf view',
  },
  Lake: {
    label: t('Lake'),
    value: 'Lake',
    icon: 'beach',
    lowerCase: 'lake',
  },
  'Lake Front': {
    label: t('Lake Front'),
    value: 'Lake Front',
    icon: 'beach',
    lowerCase: 'lake front',
  },
  'Lake access': {
    label: t('Lake access'),
    value: 'Lake access',
    icon: 'beach',
    lowerCase: 'lake access',
  },
  'Laundromat nearby': {
    label: t('Laundromat nearby'),
    value: 'Laundromat nearby',
    icon: 'washer',
    lowerCase: 'laundromat nearby',
  },
  Mountain: {
    label: t('Mountain'),
    value: 'Mountain',
    icon: 'garden',
    lowerCase: 'mountain',
  },
  'Mountain view': {
    label: t('Mountain view'),
    value: 'Mountain view',
    icon: 'garden',
    lowerCase: 'mountain view',
  },
  'Near Ocean': {
    label: t('Near Ocean'),
    value: 'Near Ocean',
    icon: 'beach',
    lowerCase: 'near ocean',
  },
  'Ocean Front': {
    label: t('Ocean Front'),
    value: 'Ocean Front',
    icon: 'beach',
    lowerCase: 'ocean front',
  },
  Resort: {
    label: t('Resort'),
    value: 'Resort',
    icon: 'beach',
    lowerCase: 'resort',
  },
  'Resort access': {
    label: t('Resort access'),
    value: 'Resort access',
    icon: 'beach',
    lowerCase: 'resort access',
  },
  Rural: {
    label: t('Rural'),
    value: 'Rural',
    icon: 'garden',
    lowerCase: 'rural',
  },
  'Sea view': {
    label: t('Sea view'),
    value: 'Sea view',
    icon: 'beach',
    lowerCase: 'sea view',
  },
  'Ski In': {
    label: t('Ski In'),
    value: 'Ski In',
    icon: 'events',
    lowerCase: 'ski in',
  },
  'Ski Out': {
    label: t('Ski Out'),
    value: 'Ski Out',
    icon: 'events',
    lowerCase: 'ski out',
  },
  'Ski in/Ski out': {
    label: t('Ski in/Ski out'),
    value: 'Ski in/Ski out',
    icon: 'events',
    lowerCase: 'ski in/ski out',
  },
  Town: {
    label: t('Town'),
    value: 'Town',
    icon: 'events',
    lowerCase: 'town',
  },
  Village: {
    label: t('Village'),
    value: 'Village',
    icon: 'garden',
    lowerCase: 'village',
  },
  'Water View': {
    label: t('Water View'),
    value: 'Water View',
    icon: 'beach',
    lowerCase: 'water view',
  },
  Waterfront: {
    label: t('Waterfront'),
    value: 'Waterfront',
    icon: 'beach',
    lowerCase: 'waterfront',
  },
  'Cleaning Disinfection': {
    label: t('Cleaning Disinfection'),
    value: 'Cleaning Disinfection',
    icon: 'cleaning',
    lowerCase: 'cleaning disinfection',
  },
  'Cleaning before checkout': {
    label: t('Cleaning before checkout'),
    value: 'Cleaning before checkout',
    icon: 'cleaning',
    lowerCase: 'cleaning before checkout',
  },
  Desk: {
    label: t('Desk'),
    value: 'Desk',
    icon: 'table',
    lowerCase: 'desk',
  },
  'Enhanced cleaning practices': {
    label: t('Enhanced cleaning practices'),
    value: 'Enhanced cleaning practices',
    icon: 'cleaning',
    lowerCase: 'enhanced cleaning practices',
  },
  'High touch surfaces disinfected': {
    label: t('High touch surfaces disinfected'),
    value: 'High touch surfaces disinfected',
    icon: 'cleaning',
    lowerCase: 'high touch surfaces disinfected',
  },
  'Laptop friendly workspace': {
    label: t('Laptop friendly workspace'),
    value: 'Laptop friendly workspace',
    icon: 'laptop',
    lowerCase: 'laptop friendly workspace',
  },
  'Long term stays allowed': {
    label: t('Long term stays allowed'),
    value: 'Long term stays allowed',
    icon: 'guard',
    lowerCase: 'long term stays allowed',
  },
  'Luggage dropoff allowed': {
    label: t('Luggage dropoff allowed'),
    value: 'Luggage dropoff allowed',
    icon: 'lockbox',
    lowerCase: 'luggage dropoff allowed',
  },
  'BBQ grill': {
    label: t('BBQ grill'),
    value: 'BBQ grill',
    icon: 'bbq',
    lowerCase: 'bbq grill',
  },
  'Beach essentials': {
    label: t('Beach essentials'),
    value: 'Beach essentials',
    icon: 'beach',
    lowerCase: 'beach essentials',
  },
  'Bicycles available': {
    label: t('Bicycles available'),
    value: 'Bicycles available',
    icon: 'gym',
    lowerCase: 'bicycles available',
  },
  Bikes: {
    label: t('Bikes'),
    value: 'Bikes',
    icon: 'gym',
    lowerCase: 'bikes',
  },
  'Boat slip': {
    label: t('Boat slip'),
    value: 'Boat slip',
    icon: 'beach',
    lowerCase: 'boat slip',
  },
  Doorman: {
    label: t('Doorman'),
    value: 'Doorman',
    icon: 'doorman',
    lowerCase: 'doorman',
  },
  'Fire Pit': {
    label: t('Fire Pit'),
    value: 'Fire Pit',
    icon: 'fireplace',
    lowerCase: 'fire pit',
  },
  'Garden or backyard': {
    label: t('Garden or backyard'),
    value: 'Garden or backyard',
    icon: 'garden',
    lowerCase: 'garden or backyard',
  },
  Hammock: {
    label: t('Hammock'),
    value: 'Hammock',
    icon: 'garden',
    lowerCase: 'hammock',
  },
  Kayak: {
    label: t('Kayak'),
    value: 'Kayak',
    icon: 'beach',
    lowerCase: 'kayak',
  },
  'Outdoor kitchen': {
    label: t('Outdoor kitchen'),
    value: 'Outdoor kitchen',
    icon: 'kitchen',
    lowerCase: 'outdoor kitchen',
  },
  'Outdoor seating (furniture)': {
    label: t('Outdoor seating (furniture)'),
    value: 'Outdoor seating (furniture)',
    icon: 'garden',
    lowerCase: 'outdoor seating (furniture)',
  },
  River: {
    label: t('River'),
    value: 'River',
    icon: 'garden',
    lowerCase: 'river',
  },
  'Free parking on premises': {
    label: t('Free parking on premises'),
    value: 'Free parking on premises',
    icon: 'parking',
    lowerCase: 'free parking on premises',
  },
  'Free parking on street': {
    label: t('Free parking on street'),
    value: 'Free parking on street',
    icon: 'parking',
    lowerCase: 'free parking on street',
  },
  Garage: {
    label: t('Garage'),
    value: 'Garage',
    icon: 'parking',
    lowerCase: 'garage',
  },
  'Paid parking': {
    label: t('Paid parking'),
    value: 'Paid parking',
    icon: 'parking',
    lowerCase: 'paid parking',
  },
  'Paid parking off premises': {
    label: t('Paid parking off premises'),
    value: 'Paid parking off premises',
    icon: 'parking',
    lowerCase: 'paid parking off premises',
  },
  'Communal pool': {
    label: t('Communal pool'),
    value: 'Communal pool',
    icon: 'pool',
    lowerCase: 'communal pool',
  },
  'Indoor pool': {
    label: t('Indoor pool'),
    value: 'Indoor pool',
    icon: 'pool',
    lowerCase: 'indoor pool',
  },
  'Outdoor pool': {
    label: t('Outdoor pool'),
    value: 'Outdoor pool',
    icon: 'pool',
    lowerCase: 'outdoor pool',
  },
  'Private pool': {
    label: t('Private pool'),
    value: 'Private pool',
    icon: 'pool',
    lowerCase: 'private pool',
  },
  'Rooftop pool': {
    label: t('Rooftop pool'),
    value: 'Rooftop pool',
    icon: 'pool',
    lowerCase: 'rooftop pool',
  },
  'Swimming pool': {
    label: t('Swimming pool'),
    value: 'Swimming pool',
    icon: 'pool',
    lowerCase: 'swimming pool',
  },
  'Air conditioning': {
    label: t('Air conditioning'),
    value: 'Air conditioning',
    icon: 'air_condition',
    lowerCase: 'air conditioning',
  },
  'Bed linens': {
    label: t('Bed linens'),
    value: 'Bed linens',
    icon: 'bed',
    lowerCase: 'bed linens',
  },
  'Cable TV': {
    label: t('Cable TV'),
    value: 'Cable TV',
    icon: 'tv',
    lowerCase: 'cable tv',
  },
  Dryer: {
    label: t('Dryer'),
    value: 'Dryer',
    icon: 'dryer',
    lowerCase: 'dryer',
  },
  Elevator: {
    label: t('Elevator'),
    value: 'Elevator',
    icon: 'elevator',
    lowerCase: 'elevator',
  },
  Essentials: {
    label: t('Essentials'),
    value: 'Essentials',
    icon: 'essentials',
    lowerCase: 'essentials',
  },
  'Hair dryer': {
    label: t('Hair dryer'),
    value: 'Hair dryer',
    icon: 'hair_dryer',
    lowerCase: 'hair dryer',
  },
  Hangers: {
    label: t('Hangers'),
    value: 'Hangers',
    icon: 'hangers',
    lowerCase: 'hangers',
  },
  Heating: {
    label: t('Heating'),
    value: 'Heating',
    icon: 'heating',
    lowerCase: 'heating',
  },
  'Indoor fireplace': {
    label: t('Indoor fireplace'),
    value: 'Indoor fireplace',
    icon: 'fireplace',
    lowerCase: 'indoor fireplace',
  },
  Internet: {
    label: t('Internet'),
    value: 'Internet',
    icon: 'internet',
    lowerCase: 'internet',
  },
  Iron: {
    label: t('Iron'),
    value: 'Iron',
    icon: 'iron',
    lowerCase: 'iron',
  },
  Kitchen: {
    label: t('Kitchen'),
    value: 'Kitchen',
    icon: 'kitchen',
    lowerCase: 'kitchen',
  },
  'Patio or balcony': {
    label: t('Patio or balcony'),
    value: 'Patio or balcony',
    icon: 'patio',
    lowerCase: 'patio or balcony',
  },
  TV: {
    label: t('TV'),
    value: 'TV',
    icon: 'tv',
    lowerCase: 'tv',
  },
  Washer: {
    label: t('Washer'),
    value: 'Washer',
    icon: 'washer',
    lowerCase: 'washer',
  },
  'Wireless Internet': {
    label: t('Wireless Internet'),
    value: 'Wireless Internet',
    icon: 'wifi',
    lowerCase: 'wireless internet',
  },
  'Ceiling fan': {
    label: t('Ceiling fan'),
    value: 'Ceiling fan',
    icon: 'air_condition',
    lowerCase: 'ceiling fan',
  },
  'EV charger': {
    label: t('EV charger'),
    value: 'EV charger',
    icon: 'ev',
    lowerCase: 'ev charger',
  },
  'Extra pillows and blankets': {
    label: t('Extra pillows and blankets'),
    value: 'Extra pillows and blankets',
    icon: 'bed',
    lowerCase: 'extra pillows and blankets',
  },
  'Pocket Wifi': {
    label: t('Pocket Wifi'),
    value: 'Pocket Wifi',
    icon: 'wifi',
    lowerCase: 'pocket wifi',
  },
  'Private entrance': {
    label: t('Private entrance'),
    value: 'Private entrance',
    icon: 'private_entrance',
    lowerCase: 'private entrance',
  },
  Safe: {
    label: t('Safe'),
    value: 'Safe',
    icon: 'guard',
    lowerCase: 'safe',
  },
  'Stereo system': {
    label: t('Stereo system'),
    value: 'Stereo system',
    icon: 'events',
    lowerCase: 'stereo system',
  },
  Gym: {
    label: t('Gym'),
    value: 'Gym',
    icon: 'gym',
    lowerCase: 'gym',
  },
  'Hot tub': {
    label: t('Hot tub'),
    value: 'Hot tub',
    icon: 'hot_tub',
    lowerCase: 'hot tub',
  },
  Spa: {
    label: t('Spa'),
    value: 'Spa',
    icon: 'bath',
    lowerCase: 'spa',
  },
  Sauna: {
    label: t('Sauna'),
    value: 'Sauna',
    icon: 'bath',
    lowerCase: 'sauna',
  },
  Casinos: {
    label: t('Casinos'),
    value: 'Casinos',
    icon: 'events',
    lowerCase: 'casinos',
  },
  Cycling: {
    label: t('Cycling'),
    value: 'Cycling',
    icon: 'events',
    lowerCase: 'cycling',
  },
  Fishing: {
    label: t('Fishing'),
    value: 'Fishing',
    icon: 'events',
    lowerCase: 'fishing',
  },
  'Golf - Optional': {
    label: t('Golf - Optional'),
    value: 'Golf - Optional',
    icon: 'events',
    lowerCase: 'golf - optional',
  },
  'Horseback Riding': {
    label: t('Horseback Riding'),
    value: 'Horseback Riding',
    icon: 'events',
    lowerCase: 'horseback riding',
  },
  'Mountain Climbing': {
    label: t('Mountain Climbing'),
    value: 'Mountain Climbing',
    icon: 'events',
    lowerCase: 'mountain climbing',
  },
  Museums: {
    label: t('Museums'),
    value: 'Museums',
    icon: 'events',
    lowerCase: 'museums',
  },
  'Rock Climbing': {
    label: t('Rock Climbing'),
    value: 'Rock Climbing',
    icon: 'events',
    lowerCase: 'rock climbing',
  },
  Shopping: {
    label: t('Shopping'),
    value: 'Shopping',
    icon: 'events',
    lowerCase: 'shopping',
  },
  'Theme Parks': {
    label: t('Theme Parks'),
    value: 'Theme Parks',
    icon: 'events',
    lowerCase: 'theme parks',
  },
  'Water Parks': {
    label: t('Water Parks'),
    value: 'Water Parks',
    icon: 'events',
    lowerCase: 'water parks',
  },
  'Water Sports': {
    label: t('Water Sports'),
    value: 'Water Sports',
    icon: 'events',
    lowerCase: 'water sports',
  },
  Zoo: {
    label: t('Zoo'),
    value: 'Zoo',
    icon: 'events',
    lowerCase: 'zoo',
  },
});

export const getPropertyTypes = () => ({
  APARTMENT: {
    label: t('Apartment'),
    value: 'Apartment',
  },
  HOUSE: {
    label: t('House'),
    value: 'House',
  },
  LOFT: {
    label: t('Loft'),
    value: 'Loft',
  },
  BOAT: {
    label: t('Boat'),
    value: 'Boat',
  },
  CAMPER_RV: {
    label: t('Camper/RV'),
    value: 'Camper/RV',
  },
  CONDOMINIUM: {
    label: t('Condominium'),
    value: 'Condominium',
  },
  CHALET: {
    label: t('Chalet'),
    value: 'Chalet',
  },
  BED_Breakfast: {
    label: t('Bed & Breakfast'),
    value: 'Bed & Breakfast',
  },
  VILLA: {
    label: t('Villa'),
    value: 'Villa',
  },
  TENT: {
    label: t('Tent'),
    value: 'Tent',
  },
  OTHER: {
    label: t('Other'),
    value: 'Other',
  },
  CABIN: {
    label: t('Cabin'),
    value: 'Cabin',
  },
  TOWNHOUSE: {
    label: t('Townhouse'),
    value: 'Townhouse',
  },
  BUNGALOW: {
    label: t('Bungalow'),
    value: 'Bungalow',
  },
  HUT: {
    label: t('Hut'),
    value: 'Hut',
  },
  DORM: {
    label: t('Dorm'),
    value: 'Dorm',
  },
  PARKING_SPACE: {
    label: t('Parking Space'),
    value: 'Parking Space',
  },
  PLANE: {
    label: t('Plane'),
    value: 'Plane',
  },
  TREEHOUSE: {
    label: t('Treehouse'),
    value: 'Treehouse',
  },
  YURT: {
    label: t('Yurt'),
    value: 'Yurt',
  },
  TIPI: {
    label: t('Tipi'),
    value: 'Tipi',
  },
  IGLOO: {
    label: t('Igloo'),
    value: 'Igloo',
  },
  EARTH_HOUSE: {
    label: t('Earth House'),
    value: 'Earth House',
  },
  ISLAND: {
    label: t('Island'),
    value: 'Island',
  },
  CAVE: {
    label: t('Cave'),
    value: 'Cave',
  },
  CASTLE: {
    label: t('Castle'),
    value: 'Castle',
  },
  STUDIO: {
    label: t('Studio'),
    value: 'Studio',
  },
  COTTAGE: {
    label: t('Cottage'),
    value: 'Cottage',
  },
});

export const getNewPropertyTypes = () => ({
  Apartment: {
    label: t('Apartment'),
    value: 'Apartment',
  },
  House: {
    label: t('House'),
    value: 'House',
  },
  Loft: {
    label: t('Loft'),
    value: 'Loft',
  },
  Boat: {
    label: t('Boat'),
    value: 'Boat',
  },
  'Camper/RV': {
    label: t('Camper/RV'),
    value: 'Camper/RV',
  },
  Condominium: {
    label: t('Condominium'),
    value: 'Condominium',
  },
  Chalet: {
    label: t('Chalet'),
    value: 'Chalet',
  },
  'Bed & Breakfast': {
    label: t('Bed & Breakfast'),
    value: 'Bed & Breakfast',
  },
  Villa: {
    label: t('Villa'),
    value: 'Villa',
  },
  Tent: {
    label: t('Tent'),
    value: 'Tent',
  },
  Other: {
    label: t('Other'),
    value: 'Other',
  },
  Cabin: {
    label: t('Cabin'),
    value: 'Cabin',
  },
  Townhouse: {
    label: t('Townhouse'),
    value: 'Townhouse',
  },
  Bungalow: {
    label: t('Bungalow'),
    value: 'Bungalow',
  },
  Hut: {
    label: t('Hut'),
    value: 'Hut',
  },
  Dorm: {
    label: t('Dorm'),
    value: 'Dorm',
  },
  'Parking Space': {
    label: t('Parking Space'),
    value: 'Parking Space',
  },
  Plane: {
    label: t('Plane'),
    value: 'Plane',
  },
  Treehouse: {
    label: t('Treehouse'),
    value: 'Treehouse',
  },
  Yurt: {
    label: t('Yurt'),
    value: 'Yurt',
  },
  Tipi: {
    label: t('Tipi'),
    value: 'Tipi',
  },
  Igloo: {
    label: t('Igloo'),
    value: 'Igloo',
  },
  'Earth House': {
    label: t('Earth House'),
    value: 'Earth House',
  },
  Island: {
    label: t('Island'),
    value: 'Island',
  },
  Cave: {
    label: t('Cave'),
    value: 'Cave',
  },
  Castle: {
    label: t('Castle'),
    value: 'Castle',
  },
  Studio: {
    label: t('Studio'),
    value: 'Studio',
  },
  Cottage: {
    label: t('Cottage'),
    value: 'Cottage',
  },
});

export const MEAL_PLANS = {
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
  ALL_INCLUSIVE: 'all_inclusive',
};

export const getMealPlansTranslations = () => ({
  [MEAL_PLANS.BREAKFAST]: t('Breakfast'),
  [MEAL_PLANS.LUNCH]: t('Lunch'),
  [MEAL_PLANS.DINNER]: t('Dinner'),
});

export const ALLOWED_POINT_OF_SALE = ['google'];

export const SELECTED_UPSELLS_LOCALSTORE_KEY = 'upsellItems';

export const WORKFLOW_TOOLTIP_TYPES = {
  PERIOD: 'PERIOD',
  DURATION: 'DURATION',
  DURATION_HOLD: 'DURATION_HOLD',
};

export const ACCOUNT_TYPES = {
  PRO: 'pro',
  LITE: 'lite',
};
