import moment from 'moment';

import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import Tooltip from '@guestyci/foundation/Tooltip';
import { Row } from '@guestyci/foundation/Layout';
import { ReactComponent as IcoNoticeOutline } from '@guestyci/icons/IcoNoticeOutline.svg';
import createStyles from '@guestyci/foundation/createStyles';

import { US_DATE_FORMAT } from 'constants/date';
import { WORKFLOW_TOOLTIP_TYPES } from 'constants/constants';

const useStyles = createStyles((theme) => ({
  cursorPointer: {
    cursor: 'pointer',
  },
  root: {
    backgroundColor: theme.palette.background.white,
    color: '#1F2937',
  },
  icon: {
    fill: '#6B7280',
  },
}));

const PaymentClarificationTooltip = ({ tooltip = {} }) => {
  const { holdAfter = 0, captureAfter = 0, holdDate, captureDate, duration = 0 } = tooltip;
  const classes = useStyles();
  const TOOLTIP = {
    // HOLD_AND_CAPTURE TYPE PERIOD (BOOKING CONFIRMATION)
    [WORKFLOW_TOOLTIP_TYPES.PERIOD]: (
      <TextField color="dark">
        {t(
          `To secure your reservation, this amount will be held on {holdDate}, but you will only be charged on {captureDate}.`,
          {
            holdDate: moment(holdDate).format(US_DATE_FORMAT),
            captureDate: moment(captureDate).format(US_DATE_FORMAT),
          }
        )}
      </TextField>
    ),
    // HOLD_AND_CAPTURE TYPE DURATION (CHECKIN / CHECKOUT)
    [WORKFLOW_TOOLTIP_TYPES.DURATION]: (
      <TextField color="dark">
        {holdAfter > 0 &&
          t(
            `To secure your reservation, this amount will be held { holdAfter, plural, =1 {# day} other {# days} } after confirmation, but you will only be charged { captureAfter, plural, =1 {# day} other {# days} } after the hold.`,
            {
              holdAfter,
              captureAfter,
            }
          )}
        {holdAfter === 0 &&
          t(
            `To secure your reservation, this amount will be held on the booking confirmation, but you will only be charged { captureAfter, plural, =1 {# day} other {# days} } after the hold.`,
            {
              holdAfter,
              captureAfter,
            }
          )}
      </TextField>
    ),
    // HOLD_AND_RELEASE
    [WORKFLOW_TOOLTIP_TYPES.DURATION_HOLD]: (
      <TextField color="dark">
        {t(
          `To secure your reservation, this amount will be held on {date} and released after { duration, plural, =1 {# day} other {# days} }.`,
          {
            date: moment(tooltip.date).format(US_DATE_FORMAT),
            duration,
          }
        )}
      </TextField>
    ),
  };

  if (!tooltip?.type) {
    return null;
  }

  return (
    <Tooltip className={classes.root} body={TOOLTIP[tooltip.type]}>
      <Row className={classes.cursorPointer}>
        <IcoNoticeOutline data-qa-tooltip-icon width={16} height={16} className={classes.icon} />
      </Row>
    </Tooltip>
  );
};

export default PaymentClarificationTooltip;
