import { api } from 'api';

const URL = '/listings';

const getListingPaymentProvider = async ({ listingId }) => {
  const { data } = await api.get(`${URL}/${listingId}/payment-provider`);
  return data;
};

export default getListingPaymentProvider;
