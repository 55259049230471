import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';

const useStyles = createStyles(() => ({
  root: {
    padding: 15,
    background: '#FEF9E5',
    marginBottom: 20,
  },
}));

const BookNowError = ({ data }) => {
  const { root } = useStyles();
  const { guestsCount, maxGuestsNumber = null, isCreatingQuoteError = false } = data;
  const isError = guestsCount === undefined || isCreatingQuoteError;

  const errorMap = {
    guestsCount: `The property can accommodate ${maxGuestsNumber} guests. Please re-enter the number of guests.`,
    isCreatingQuoteError:
      'Looks like this property is no longer available for these dates. Try to broaden or change the date range or check out one of our other properties.',
  };

  if (!isError) {
    return null;
  }

  return (
    <TextField color="dark" variant="h5" className={root}>
      {errorMap[isCreatingQuoteError ? 'isCreatingQuoteError' : 'guestsCount']}
    </TextField>
  );
};

export default BookNowError;
