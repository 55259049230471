import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { WebsiteSettingsContext } from '../context/WebsiteSettingsContext';
// eslint-disable-next-line import/no-cycle
import { useGoogleTagManager, useFacebookPixel } from './hooks';

export const AnalyticsContext = createContext({
  sendPageView: () => {},
  fbtrack: () => {},
  gtagEvent: () => {},
  gtagEcommerceViewItem: () => {},
  gtagEcommerceBeginCheckout: () => {},
  gtagEcommercePurchase: () => {},
  gtagEcommerceGroupReservationsPurchase: () => {},
  reservationTypes: () => {},
});

export const AnalyticsProvider = ({ children }) => {
  const currentUrl = window.location.pathname + window.location.search;
  const history = useHistory();

  const initializationStatuses = useRef({
    isGTAGInit: false,
    isFBInit: false,
  });
  const {
    current: { isGTAGInit, isFBInit },
  } = initializationStatuses;

  const prevPath = useRef(currentUrl);

  const {
    analytics: { googleAnalytics: gaId = null, googleTag: tagId = null, facebookPixel: facebookPixelId = null } = {},
    cookiesPolicyAccepted,
  } = useContext(WebsiteSettingsContext);

  const {
    initGoogleTagManager,
    gtagEvent,
    gtagEcommerceViewItem,
    gtagEcommerceBeginCheckout,
    gtagEcommercePurchase,
    gtagEcommerceGroupReservationsPurchase,
    reservationTypes,
    gtagPageViewEvent,
  } = useGoogleTagManager();
  const { initAnalytics: initFacebookAnalytics, fbTrackPageView, fbtrack } = useFacebookPixel();

  const shouldShowCookiePolicy = useMemo(() => {
    return !!facebookPixelId;
  }, [facebookPixelId]);

  const sendPageView = useCallback(
    (url) => {
      const URL = url || window.location.pathname + window.location.search;
      gtagPageViewEvent(URL);
      fbTrackPageView(URL);
      prevPath.current = currentUrl;
    },
    [currentUrl, fbTrackPageView, gtagPageViewEvent]
  );

  const value = useMemo(() => {
    return {
      sendPageView,
      fbtrack,
      gtagEvent,
      gtagEcommerceViewItem,
      gtagEcommerceBeginCheckout,
      gtagEcommercePurchase,
      gtagEcommerceGroupReservationsPurchase,
      reservationTypes,
      shouldShowCookiePolicy,
    };
  }, [
    fbtrack,
    gtagEcommerceBeginCheckout,
    gtagEcommerceGroupReservationsPurchase,
    gtagEcommercePurchase,
    gtagEcommerceViewItem,
    gtagEvent,
    shouldShowCookiePolicy,
    reservationTypes,
    sendPageView,
  ]);

  useEffect(() => {
    if ((tagId || gaId) && !isGTAGInit) {
      initGoogleTagManager({
        tagId,
        gaId,
      });
      initializationStatuses.current.isGTAGInit = true;
    }
    if (facebookPixelId && !isFBInit && cookiesPolicyAccepted) {
      initFacebookAnalytics(facebookPixelId);
      initializationStatuses.current.isFBInit = true;
      fbTrackPageView(currentUrl);
    }
  }, [
    facebookPixelId,
    initGoogleTagManager,
    initFacebookAnalytics,
    sendPageView,
    fbTrackPageView,
    isGTAGInit,
    isFBInit,
    currentUrl,
    cookiesPolicyAccepted,
    tagId,
    gaId,
  ]);

  useEffect(() => {
    const handleListen = () => {
      if (currentUrl !== prevPath.current) {
        sendPageView(currentUrl);
        prevPath.current = currentUrl;
      }
    };

    const unlisten = history.listen(handleListen);
    return () => {
      unlisten();
    };
  }, [currentUrl, history, sendPageView]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
