/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Input from '@guestyci/foundation/Input';
import Icon from 'components/Icon/Icon';

import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(() => ({
  root: {
    padding: ' 0px 12px 0px 0px',
    border: '1px solid #D4D8E1',
    borderRadius: 2,
    height: 46,
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #D4D8E1',
    padding: 15,
    width: 46,
    marginRight: 10,
    height: 46,
  },
}));

const IconInput = React.forwardRef(function CustomInput({ value, placeholder, open, ...props }, ref) {
  const { root, iconRoot } = useStyles();
  const values = props?.renderSelected ? props?.renderSelected(value)?.join('') : value;
  return (
    <div ref={ref}>
      <Input
        {...props}
        value={values}
        className={root}
        placeholder={placeholder}
        prefix={
          <span className={iconRoot}>
            <Icon icon="guest" size={20} />
          </span>
        }
      />
    </div>
  );
});

export default IconInput;
