// import { api } from 'api';
import Resource from '@guestyci/agni';

const { api } = Resource.create({
  domain: '/',
  env: process.env.REACT_APP_ENV_CLUSTER,
  development: 'localhost',
});

// TODO: refactor after BE is ready
const URL = '/pm-websites-backend/legacy/stripe/setup-intents';

const setupIntents = async (listingId) => {
  const { data } = await api.post(URL, {
    listingId,
  });
  return data;
};

export default setupIntents;
