/* eslint-disable @guestyci/no-literal-string */
import { Route, Switch } from 'react-router-dom';

import routes, { routesWithLocale } from 'constants/routes';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

const RoutesWithLocale = () => {
  return (
    <Switch>
      {routesWithLocale.map((route) => (
        <Route key={route.path} exact={route?.exact} path={route.path} render={route.render} />
      ))}
    </Switch>
  );
};

const StandardRoutes = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} exact={route?.exact} path={route.path}>
          {route.page}
        </Route>
      ))}
    </Switch>
  );
};

const AppRoutes = () => {
  const { isTranslationsEnabled, isFtLoading } = useGetPathToNavigate();
  if (isFtLoading) {
    return null;
  }
  if (isTranslationsEnabled) return <RoutesWithLocale />;
  return <StandardRoutes />;
};

export default AppRoutes;
