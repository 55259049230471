import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';
import WebsiteSettingsContextProvider from 'context/WebsiteSettingsContext';
import UpsellContextProvider from 'context/UpsellContext/UpsellContext';
import FeatureToggleContext from 'context/FeatureToggleContext';
import CurrencyContextProvider from 'context/CurrencyContext';
import { Router, BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import '@guestyci/foundation/style/styles.css';
import ErrorBoundary from 'components/ErrorBoundary';
import PageHead from 'components/PageHead';
import initDatadog from 'utils/datadog';
import useLocale from 'hooks/useLocale';
import App from './app/App';
import { BaseUrlProvider } from './BaseUrlProvider';
import { AnalyticsProvider } from './analytics';

const queryClient = new QueryClient();
if (process.env.REACT_APP_ENV_CLUSTER === 'production') {
  initDatadog();
}

function AppContainer({ baseUrl }) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const locale = useLocale();
  // const isDevelopment = process.env.NODE_ENV !== 'production';

  const onError = (error) => {
    console.error('LOCALIZE ERROR: ', error);
  };

  useEffect(() => {
    if (pathname.includes('listings')) {
      const newPath = pathname.replace('listings', 'properties');
      const params = new URLSearchParams(search);

      if (params.get('endDate')) {
        params.set('checkOut', params.get('endDate'));
        params.delete('endDate');
      }

      if (params.get('startDate')) {
        params.set('checkIn', params.get('startDate'));
        params.delete('startDate');
      }

      params.delete('guests');
      history.push(`${newPath}?${params.toString()}`);
    }
  }, [pathname, search, history]);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FeatureToggleContext>
          <WebsiteSettingsContextProvider>
            <CurrencyContextProvider>
              <UpsellContextProvider>
                <BaseUrlProvider baseUrl={baseUrl}>
                  <ErrorBoundary>
                    <PageHead>
                      <LocalizationProvider onError={onError} debug={false} language={locale}>
                        <AnalyticsProvider>
                          <App />
                        </AnalyticsProvider>
                      </LocalizationProvider>
                    </PageHead>
                  </ErrorBoundary>
                </BaseUrlProvider>
              </UpsellContextProvider>
            </CurrencyContextProvider>
          </WebsiteSettingsContextProvider>
        </FeatureToggleContext>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.StrictMode>
  );
}

window.renderBookingEnginePage = (containerId, history, baseUrl) => {
  ReactDOM.render(
    <Router history={history}>
      <AppContainer baseUrl={baseUrl} />
    </Router>,
    document.getElementById(containerId)
  );
};

if (!document.getElementById('bookingEnginePage-container')) {
  ReactDOM.render(
    <BrowserRouter>
      <AppContainer baseUrl="/" />
    </BrowserRouter>,
    document.getElementById('root')
  );
}
