// eslint-disable-next-line
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import FlatButton from '@guestyci/foundation/FlatButton';
import IconButton from '@guestyci/foundation/IconButton';
import { ReactComponent as BtnDelete } from '@guestyci/icons/BtnDelete.svg';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { ACCEPT_COOKIE_POLICY_BTN, COOKIE_POLICY_TERMS } from 'constants/constants';

const useStyles = createStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#000',
    color: '#fff',
    padding: '1rem 0 1rem 2rem',
    width: '100%',
    zIndex: 999999999,
  },
  privacyPolicy: {
    color: '#FFF',
    display: 'flex',
    '&:hover': {
      color: '#CCC',
    },
  },
}));

const CookiePolicyLayout = ({ message, declineBtn, acceptBtn }) => {
  const styles = useStyles();
  return (
    <div className={classnames('row no-gutters cookies__block', styles.root)}>
      <div className="col-sm-12 col-md-9 cookies__message">
        {message}
        <Link className={classnames(styles.privacyPolicy)} target="_blank" to="/privacy-policy">
          {t('Privacy Policy')}
        </Link>
      </div>
      <div className="col-sm-12 col-md-3 pt-2 cookies__btn__group">
        <div className="row justify-content-between">
          <div className="col col-xs col-3 d-flex">{declineBtn}</div>
          <div className="col col-xs col-9">{acceptBtn}</div>
        </div>
      </div>
    </div>
  );
};

const DeclineButton = ({ onDecline }) => (
  <IconButton className="cookies__btn cookies__btn--decline" icon="close" color="transparent" onClick={onDecline}>
    <BtnDelete />
  </IconButton>
);

const AcceptButton = ({ text, onAccept }) => (
  <FlatButton className="cookies__btn cookies__btn--accept" icon="check" color="success" onClick={onAccept}>
    {text}
  </FlatButton>
);

const CookiePolicy = ({ message, btnAcceptText, onAccept, onDecline }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <CookiePolicyLayout
    message={message}
    declineBtn={<DeclineButton onDecline={onDecline} />}
    acceptBtn={<AcceptButton text={btnAcceptText} onAccept={onAccept} />}
  />
);

const CookiePolicyStrategy = ({ isVisible, ...rest }) => {
  const { cookiesPolicyAccepted, setPolicyCookie } = useContext(WebsiteSettingsContext);

  const cookiePolicyProps = {
    message: COOKIE_POLICY_TERMS(),
    btnAcceptText: ACCEPT_COOKIE_POLICY_BTN,
    onAccept: () => setPolicyCookie({ value: 1 }),
    onDecline: () => setPolicyCookie({ value: 0 }),
    ...rest,
  };

  return isVisible && cookiesPolicyAccepted === undefined ? <CookiePolicy {...cookiePolicyProps} /> : null;
};

export { CookiePolicyStrategy, CookiePolicy, CookiePolicyLayout, DeclineButton, AcceptButton };
