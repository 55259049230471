export function setCookie(name, value, props = {}) {
  const options = {
    path: '/',
    ...props,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)  }=${  encodeURIComponent(value)}`;

  // eslint-disable-next-line
  for (let optionKey in options) {
    updatedCookie += `; ${  optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${  optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

// returns cookie with corresponded name,
// or undefined, if nothing was found
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${  name.replace(/([.$?*|{}()[ß]\\\/+\^])/g, '\\$1')  }=([^;]*)`)
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
