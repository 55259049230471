import { useContext } from 'react';

import { GuestyPayWidget } from '@guestyci/guesty-pay-widget';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { blue10, white } from '@guestyci/foundation/theme/colors';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import Paper from '@guestyci/foundation/Paper';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import PaymentMethodSwitch from 'components/PaymentMethodSwitch';
import BankTransferInformation from 'components/BankTransferInformation';
import { BANK_TRANSFER } from 'constants/featureToggleNames';

const GuestyPayInstantForm = ({ providerId }) => {
  const {
    paymentTypes: {
      creditCard: creditCardPaymentTypeEnabled = true,
      bankTransfer: bankTransferPaymentTypeEnabled = false,
    } = {},
  } = useContext(WebsiteSettingsContext);
  const [, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);

  const creditCardForm = (
    <>
      <Paper bgColor={blue10} elevation={0} style={{ padding: '8px 10px', marginBottom: '25px' }}>
        <TextField variant="h5">
          {t("We'll securely save your credit card details and we'll charge payments according to our payment policy.")}
        </TextField>
      </Paper>
      <GuestyPayWidget providerId={providerId} styles={{ colorBackground: white }} />
    </>
  );

  if (isBankTransferEnabled && (creditCardPaymentTypeEnabled || bankTransferPaymentTypeEnabled)) {
    return (
      <PaymentMethodSwitch
        isCreditCardEnabled={creditCardPaymentTypeEnabled}
        isBankTransferEnabled={bankTransferPaymentTypeEnabled}
        creditCardForm={creditCardForm}
        BankTransferInformation={<BankTransferInformation />}
      />
    );
  }

  return creditCardForm;
};

export default GuestyPayInstantForm;
